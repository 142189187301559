export const ROLES = {
  SUPERADMIN: 'superadmin',
  ADMIN: 'admin',
  USER: 'user',
}

export const ADMIN = ROLES.ADMIN
export const SUPER_ADMIN = ROLES.SUPERADMIN
export const USER = ROLES.USER

export const ADMIN_USERS = [SUPER_ADMIN, ADMIN]
export const ALL_USERS = [USER, ...ADMIN_USERS]
