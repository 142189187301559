export const normalizeSecurityChanges = (changes = []) => {
  const changesMap = {}

  if (changes === null) {
    return changesMap
  }

  for (let c of changes) {
    const [prefix, fieldName] = c.path

    if (prefix === 'investment_security_values') {
      changesMap[fieldName] = c
    }
  }

  return changesMap
}
