import React from 'react'
import Grid from '@material-ui/core/Grid'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  panelButton: {
    marginBottom: theme.spacing(3),
  },
  buttonLabel: {
    marginRight: theme.spacing(2),
    color: theme.palette.green.main,
  },
}))
export const PanelButton = ({ button, label }) => {
  const classes = useStyles()

  return (
    <Grid container item alignItems="center" justify="flex-end" className={classes.panelButton}>
      <Typography variant="body1" className={classes.buttonLabel}>
        {label}
      </Typography>

      {button}
    </Grid>
  )
}
