import React from 'react'
import { Redirect, Switch, useRouteMatch } from 'react-router-dom'
import { RoleRoute } from 'components/RoleRoute'
import { ALL_USERS } from 'constants/roles'

import { TransactionReport, PendingTransactionReport } from 'components/transactionReport'
import { useAuth } from 'hooks/useAuth'
import { InvestorPortfolioShareholdings } from './InvestorPortfolioShareholdings'
import { InvestorDebtTable } from './InvestorDebtTable'
import { InvestorOverview } from './InvestorOverview'
import { InvestorChangeLog } from './InvestorChangeLog'

export const InvestorDetailsRouter = ({ investments, investor }) => {
  const { role } = useAuth()
  let { path, url } = useRouteMatch()

  return (
    <Switch>
      <RoleRoute
        exact
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}`}
        render={() => <Redirect to={`${url}/overview`} />}
      />

      <RoleRoute
        component={() => <InvestorOverview investor={investor} />}
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}/overview`}
      />

      <RoleRoute
        component={props => (
          <InvestorPortfolioShareholdings {...props} investments={investments} investor={investor} investorMode />
        )}
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}/portfolio-shareholdings`}
      />

      <RoleRoute
        component={props => <InvestorDebtTable {...props} investments={investments} investor={investor} investorMode />}
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}/debt`}
      />

      <RoleRoute
        component={props => <TransactionReport {...props} investments={investments} investorMode />}
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}/transaction-report`}
      />

      <RoleRoute
        component={props => <PendingTransactionReport {...props} investments={investments} investorMode />}
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}/pending-transaction-report`}
      />

      <RoleRoute
        component={props => <InvestorChangeLog {...props} investorId={investor.id} />}
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}/change-log`}
      />
    </Switch>
  )
}
