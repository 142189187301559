import React, { useState } from 'react'
import { Filters } from 'components/Filters'
import { Grid, Tab, Tabs, Button } from '@material-ui/core'
import { TransactionsTable } from './TransactionsTable'
import { makeStyles } from '@material-ui/core/styles'
import { SECURITY_TYPES } from 'constants/security-types'
import moment from 'moment'
import _ from 'lodash'
import { isLocked, isPending, splitAttachments } from 'utils/investment'
import { CSVLink } from 'react-csv'
import getExportData from './exports/transactionReportExportService'

const useStyles = makeStyles(theme => ({
  tab: {
    padding: theme.spacing(1, 4),
    minWidth: 0,
  },
  exportButtonContainer: {
    marginRight: '10px',
  },
  exportButton: {
    color: '#fff',
    textDecoration: 'none',
  },
}))

const TYPE_FILTERS = [
  { label: 'Option', value: SECURITY_TYPES.OPTION },
  { label: 'Warrant', value: SECURITY_TYPES.WARRANT },
  { label: 'Debt', value: SECURITY_TYPES.DEBT },
  { label: 'CLN', value: SECURITY_TYPES.CONVERTIBLE_DEBT },
  { label: 'Equity', value: SECURITY_TYPES.EQUITY },
]

export const TransactionReport = ({ company, investments: inboundInvestments = [], investorMode = false }) => {
  const initialInvestments = inboundInvestments.map(investment => ({
    ...investment,
    ...splitAttachments(investment.attachments),
  }))

  const [investments, setInvestments] = useState(initialInvestments)
  const [type, setType] = useState('All')

  const filterByType = () => {
    if (type === 'All') {
      return investments
    }

    return investments.filter(investment => investment.investment_security.type === type)
  }
  const filterPending = investments => investments.filter(investment => !isPending(investment))

  const filteredInvestments = filterPending(filterByType())

  const onFilter = filteredCollection => {
    setInvestments(filteredCollection)
  }

  const handleTabChange = (event, newType) => setType(newType)

  const exportData = table => getExportData(table, investments, investorMode)

  const classes = useStyles()

  const initialFiltersConfig = {
    DATE: {
      label: 'Dates',
      value: 'All',
      options: [
        'All',
        ...new Set(filteredInvestments.map(investment => moment(investment.investment_date).format('YYYY')).flat(1)),
      ],
      filterFn: (transaction, selectedValue) => moment(transaction.investment_date).format('YYYY') === selectedValue,
    },
    INVESTOR_TYPE: {
      label: 'Primary Affiliation',
      value: 'All',
      filterFn: (transaction, selectedValue) => transaction.investor.investor_type === selectedValue,
      options: ['All', ...new Set(filteredInvestments.map(investment => investment.investor.investor_type).flat(1))],
    },
    COMPANY: {
      label: 'Company',
      value: 'All',
      filterFn: (transaction, selectedValue) => transaction.company.name === selectedValue,
      options: ['All', ...[...new Set(filteredInvestments.map(investment => investment.company?.name).flat(1))].sort()],
    },
    STATUS: {
      label: 'Status',
      value: 'All',
      filterFn: (transaction, selectedValue) => transaction.investment_status === selectedValue,
      options: ['All', ...new Set(filteredInvestments.map(investment => investment.investment_status).flat(1))],
    },
    INVESTOR: {
      label: 'Investor',
      value: 'All',
      filterFn: (transaction, selectedValue) => transaction.investor.investor_name === selectedValue,
      options: [
        'All',
        ...[...new Set(filteredInvestments.map(investment => investment.investor.investor_name).flat(1))].sort(),
      ],
    },
    APPROVED: {
      label: 'Approved',
      value: 'All',
      filterFn: (transaction, selectedValue) => {
        const pendingOrLocked = isPending(transaction) || isLocked(transaction)
        return selectedValue === 'Yes' ? !pendingOrLocked : pendingOrLocked
      },
      options: ['All', 'Yes', 'No'],
    },
  }

  const getInitialFilterConfig = (initialFiltersConfig, isInvestorMode) => {
    if (isInvestorMode) {
      return _.omit(initialFiltersConfig, ['INVESTOR', 'INVESTOR_TYPE'])
    }

    return _.omit(initialFiltersConfig, 'COMPANY')
  }

  return (
    <Grid container>
      <Grid container alignItems="center">
        <Grid container item xs={6} alignItems="center">
          <Filters
            initialFiltersConfig={getInitialFilterConfig(initialFiltersConfig, investorMode)}
            onFilter={onFilter}
            collection={initialInvestments}
          />
        </Grid>
        <Grid container item xs={6} justify="flex-end">
          <Tabs value={type} omponent="div" onChange={handleTabChange}>
            <Tab value="All" label="All" color="primary" variant="contained" className={classes.tab} />

            {TYPE_FILTERS.map(typeFilter => (
              <Tab
                key={typeFilter.label}
                label={typeFilter.label}
                color="primary"
                variant="contained"
                className={classes.tab}
                value={typeFilter.value}
              />
            ))}
          </Tabs>
        </Grid>

        <TransactionsTable investments={filteredInvestments} investorMode={investorMode} company={company} />
        <Button variant="contained" color="primary" className={classes.exportButtonContainer}>
          <CSVLink className={classes.exportButton} data={exportData('share')}>
            Export Shares to CSV
          </CSVLink>
        </Button>
        <Button variant="contained" color="primary" className={classes.exportButtonContainer}>
          <CSVLink className={classes.exportButton} data={exportData('debt')}>
            Export Debts to CSV
          </CSVLink>
        </Button>
      </Grid>
    </Grid>
  )
}
