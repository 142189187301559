import React from 'react'
import { DebtTable } from 'components/debtTable'
import { getCompanyDebts } from 'api'
import { useQuery } from 'react-query'
import { Spinner } from 'components/Spinner'

export const CompanyDebtTable = ({ company }) => {
  const { data: debts = [], isLoading } = useQuery(['companyDebt', company.id], getCompanyDebts)

  if (isLoading) {
    return <Spinner color="secondary" size={40} centered height="500px" />
  }

  const { stats, table } = debts
  const { investments_list: investmentsList } = table

  return <DebtTable debtOwner="Investor" debtDetails={{ stats, investmentsList }} />
}
