import React from 'react'
import { Grid, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import moment from 'moment'
import { DATE_FORMAT, DATE_FORMAT_DETAILED } from 'constants/date'
import { ViewDocuments } from 'components/company/CompanyDetails/ViewDocuments'
import Collapse from '@material-ui/core/Collapse'
import {
  CompanyTypeIcon,
  TooltipTypography,
  StatusText,
  TextTooltip,
  CurrencyCell,
  ConfirmationDialog,
} from 'components'

import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { isLockedOrPending } from 'utils/investment'
import { InvestmentEdit, InvestmentRealise } from 'components/investment'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import { queryCache, useMutation } from 'react-query'
import { SECURITY_TYPES } from 'constants/security-types'
import { useSetState } from 'react-use'
import { InvestmentConvert } from 'components/investment/InvestmentConvert'
import { InvestmentDetails, InvestmentSecurityDetails } from '../detailsView'
import { useLocation } from 'react-use'
import { useScroll, useToast } from 'hooks'
import {
  splitInvestment as splitInvestmentApi,
  cancelInvestment as cancelInvestmentApi,
  convertInvestment as convertInvestmentApi,
} from 'api'
import { apiErrors } from 'utils/error'

const useStyles = makeStyles(theme => ({
  expand: {
    marginRight: theme.spacing(1),
  },
  currencyIcon: {
    marginRight: theme.spacing(2),
  },
  button: {
    padding: 0,
    marginRight: theme.spacing(2),
  },
  detailsTable: {
    borderBottom: 'none',
  },
  lockedRow: {
    backgroundColor: theme.palette.common.pattensBlue,
  },
  locked: {
    opacity: 0.5,
  },
}))

export const TransactionRow = ({ investment, investorMode, company }) => {
  const [{ open, anchorEl, openEdit, openRealise, openConvert, openCancel }, setState] = useSetState({
    open: false,
    anchorEl: null,
    openEdit: false,
    openRealise: false,
    openConvert: false,
    openCancel: false,
  })

  const [splitInvestment, { isLoading: realisingInvestment }] = useMutation(splitInvestmentApi, { throwOnError: true })
  const [cancelInvestment] = useMutation(cancelInvestmentApi, { throwOnError: true })
  const [convertInvestment, { isLoading: convertingInvestment }] = useMutation(convertInvestmentApi, {
    throwOnError: true,
  })

  const { search } = useLocation()
  const investmentId = new URLSearchParams(search).get('investmentId')
  const isOpened = investmentId === investment.id.toString()

  const transactionRef = useScroll(isOpened)

  const closeMenu = () => setState({ anchorEl: null })
  const classes = useStyles()

  const readOnlyInvestment = isLockedOrPending(investment)

  const handleMenuClick = event => {
    event.preventDefault()
    if (readOnlyInvestment) return
    setState({ anchorEl: event.currentTarget })
  }

  const handleEditClick = () => {
    closeMenu()
    setState({ openEdit: true })
  }

  const handleRealiseClick = event => {
    event.preventDefault()
    closeMenu()
    setState({ openRealise: true })
  }

  const handleConvertClick = event => {
    event.preventDefault()
    closeMenu()
    setState({ openConvert: true })
  }

  const handleCancelClick = event => {
    event.preventDefault()
    closeMenu()
    setState({ openCancel: true })
  }

  const iconButton = menuOptions => {
    const button = (
      <IconButton
        aria-label="settings"
        className={classes.button}
        aria-haspopup="true"
        onClick={handleMenuClick}
        disabled={!menuOptions}
      >
        <MoreHoriz />
      </IconButton>
    )

    return readOnlyInvestment ? (
      <TextTooltip title="This Item is pending Admin’s approval">{button}</TextTooltip>
    ) : (
      button
    )
  }

  const detailsClasses = classnames([classes.detailsTable, { [classes.locked]: readOnlyInvestment }])
  const rowClasses = classnames({ [classes.lockedRow]: readOnlyInvestment, [classes.locked]: readOnlyInvestment })

  const invalidateInvestments = () => {
    if (investorMode) {
      queryCache.invalidateQueries('investorInvestments')
    } else {
      queryCache.invalidateQueries('companyInvestments')
    }
  }

  const menuOptions = transactionMenuOptions({
    handleRealise: handleRealiseClick,
    handleConvert: handleConvertClick,
    handleEdit: handleEditClick,
    handleCancel: handleCancelClick,
    investmentStatus: investment.investment_status,
    investmentSecurityType: investment?.investment_security?.type,
  })

  const investmentSecurityValues = investment.investment_security.investment_security_values

  const closeActionModals = () =>
    setState({ openCancel: false, openConvert: false, openRealise: false, openEdit: false })

  const toastService = useToast()

  const realise = async values => {
    try {
      await splitInvestment({ id: investment.id, body: values })
      closeActionModals()
      invalidateInvestments()
      toastService.showSuccessToast('Realised investment successfully.')
    } catch (e) {
      toastService.showErrorToast(`Failed to realise investment. ${e.message}`)
      console.log(e)
      return apiErrors(e)
    }
  }

  const convert = async values => {
    try {
      await convertInvestment({ id: investment.id, body: values })
      closeActionModals()
      invalidateInvestments()
      toastService.showSuccessToast('Conversion succeeded.')
    } catch (e) {
      toastService.showErrorToast(`Failed to convert investment. ${e.message}`)
      console.log(e)
      return apiErrors(e)
    }
  }

  const cancelAccepted = async () => {
    try {
      await cancelInvestment(investment.id)
      closeActionModals()
      invalidateInvestments()
      toastService.showSuccessToast('Investment has been canceled.')
    } catch (e) {
      toastService.showErrorToast(`Failed to cancel investment. ${e.message}`)
      console.log(e)
      return apiErrors(e)
    }
  }

  return (
    <>
      <TableRow className={rowClasses} ref={transactionRef}>
        <TableCell width="5%" padding="none">
          <Grid container alignItems="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setState({ open: !open })}
              className={classes.expand}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
            </IconButton>
            <CompanyTypeIcon type={investment?.investment_type} />
          </Grid>
        </TableCell>
        <TableCell width="11%">
          {investorMode ? (
            <TooltipTypography variant="body2" maxWidth={125}>
              {investment?.company?.name}
            </TooltipTypography>
          ) : (
            <TooltipTypography variant="body2" maxWidth={125}>
              {investment?.investor?.investor_name}
            </TooltipTypography>
          )}
        </TableCell>
        <TableCell width="12%">
          <TooltipTypography variant="body2" maxWidth={125}>
            {investment.investment_security.security.name}
          </TooltipTypography>
        </TableCell>
        <TableCell>{moment(investment.investment_date).format(DATE_FORMAT)}</TableCell>
        <TableCell width="12%">
          <CurrencyCell value={investmentSecurityValues.invested_capital} />
        </TableCell>
        <TableCell>
          <StatusText text={investment.investment_status} status={investment.investment_status} />
        </TableCell>

        <TableCell>
          <ViewDocuments documents={investment.subscription_document} />
        </TableCell>

        <TableCell>{investment?.requested_schemas}</TableCell>
        <TableCell>{investment?.fees_due ? 'Yes' : 'No'}</TableCell>
        <TableCell>{cashStatus(investment.cash_received)}</TableCell>

        <TableCell>{moment(investment.updated_at).format(DATE_FORMAT_DETAILED)}</TableCell>

        <TableCell>
          <>
            {iconButton(menuOptions)}

            <Menu
              anchorEl={anchorEl}
              keepMounted
              elevation={1}
              open={Boolean(anchorEl)}
              onClose={closeMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div>{menuOptions}</div>
            </Menu>
          </>
        </TableCell>
      </TableRow>

      {/* Inner table  */}
      <TableRow>
        <TableCell colSpan={13} padding="none" className={detailsClasses}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <InvestmentDetails investment={investment} />
            <InvestmentSecurityDetails
              security={investment.investment_security}
              splitSecurity={investment.investment_status === 'Realised'}
            />
          </Collapse>
        </TableCell>
      </TableRow>

      {openEdit && (
        <InvestmentEdit
          investment={investment}
          company={company || investment?.company}
          open={openEdit}
          onClose={closeActionModals}
          disableCompanySelect
          disableInvestorSelect
          disableSecuritySelect
          onAfterUpdate={invalidateInvestments}
        />
      )}

      {openRealise && (
        <InvestmentRealise
          open={openRealise}
          investment={investment}
          onSubmit={realise}
          handleClose={closeActionModals}
          isLoading={realisingInvestment}
        />
      )}

      {openConvert && (
        <InvestmentConvert
          open={openConvert}
          investment={investment}
          onSubmit={convert}
          handleClose={closeActionModals}
          isLoading={convertingInvestment}
        />
      )}

      {openCancel && (
        <ConfirmationDialog
          open={openCancel}
          title="Cancel Transaction"
          content="Are you sure you want to cancel this Transaction? This can not be undone."
          onCancel={closeActionModals}
          onAccept={cancelAccepted}
        />
      )}
    </>
  )
}

function transactionMenuOptions({
  investmentSecurityType,
  handleEdit,
  handleConvert,
  handleRealise,
  handleCancel,
  investmentStatus,
}) {
  if (investmentStatus === 'Canceled' || investmentStatus === 'Realised') {
    return null
  }

  const INVESTMENT_OPTIONS = {
    EDIT: (
      <MenuItem onClick={handleEdit} key="edit">
        Edit
      </MenuItem>
    ),
    CONVERT: (
      <MenuItem onClick={handleConvert} key="convert">
        Convert
      </MenuItem>
    ),
    REALISE: (
      <MenuItem onClick={handleRealise} key="realise">
        Realise
      </MenuItem>
    ),
    CANCEL: (
      <MenuItem onClick={handleCancel} key="cancel">
        Cancel
      </MenuItem>
    ),
  }

  let investmentOptions = []

  switch (investmentSecurityType) {
    case SECURITY_TYPES.EQUITY:
    case SECURITY_TYPES.DEBT:
    case SECURITY_TYPES.CONVERTIBLE_DEBT:
      investmentOptions = [INVESTMENT_OPTIONS.EDIT, INVESTMENT_OPTIONS.REALISE, INVESTMENT_OPTIONS.CANCEL]
      break
    case SECURITY_TYPES.WARRANT:
    case SECURITY_TYPES.OPTION:
      investmentOptions = [INVESTMENT_OPTIONS.EDIT, INVESTMENT_OPTIONS.CONVERT, INVESTMENT_OPTIONS.CANCEL]
      break
    default:
  }

  return investmentOptions
}

function cashStatus(cashReceived) {
  let status = 'live'

  if (cashReceived === 'No') {
    status = 'canceled'
  } else if (cashReceived === 'Pending') {
    status = 'received'
  }

  return <StatusText text={cashReceived} status={status} />
}
