import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { allApprovals } from 'api/approvals'
import { Container, fade } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import { InvestmentApprovals } from './investment'
import classNames from 'classnames/bind'

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.common.blueGray,
    fontWeight: 500,
    marginRight: theme.spacing(7.5),
    cursor: 'pointer',
  },
  active: {
    color: theme.palette.common.dark,
  },
  tabsBorder: {
    borderBottom: `1px solid ${fade(theme.palette.grayShades.gray3, 0.5)}`,
  },
}))

const TABS = {
  COMPANIES: 'COMPANIES',
  INVESTORS: 'INVESTORS',
  INVESTMENTS: 'INVESTMENTS',
}

export const ApprovalsList = () => {
  const classes = useStyles()
  const { data: approvals } = useQuery('approvals', allApprovals)
  const [tab, setTab] = useState(TABS.INVESTMENTS)
  const { investments } = splitApprovalsByType(approvals)

  const onTabClick = tab => () => setTab(tab)

  return (
    <Container maxWidth="xl" disableGutters>
      <Box px={6} pb={12}>
        <Box width="100%" mb={6} className={classes.tabsBorder} pb={4}>
          <span
            className={classNames(classes.link, { [classes.active]: tab === TABS.INVESTMENTS })}
            onClick={onTabClick(TABS.INVESTMENTS)}
          >
            Investments ({investments.length})
          </span>
        </Box>

        {tab === TABS.INVESTMENTS && <InvestmentApprovals investments={investments} />}
      </Box>
    </Container>
  )
}

function splitApprovalsByType(approvals = []) {
  const investments = []

  for (let approval of approvals) {
    if (approval.group_type === 'Investment') {
      investments.push(approval)
    }
  }

  return { investments }
}
