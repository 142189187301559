import React, { useState } from 'react'
import { FadeContent, Panel } from 'components'
import { AddSecurityModal } from 'components/security'
import { SecurityList } from 'components/security/SecurityList'
import { IconButton } from '@material-ui/core'
import Add from '@material-ui/icons/Add'

export const CompanySecurityList = ({ securityList, addSecurity, panelClass = '', disableGutters = false }) => {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <Panel
      disableGutters={disableGutters}
      title="Available Securities"
      panelClass={panelClass}
      buttonLabel="Add Security"
      button={
        <IconButton color="primary" variant="outlined" onClick={openModal}>
          <Add />
        </IconButton>
      }
      headerChildren={
        <AddSecurityModal open={open} handleClose={closeModal} securityList={securityList} addSecurity={addSecurity} />
      }
    >
      <FadeContent>
        <div>
          <SecurityList securityList={securityList} />
        </div>
      </FadeContent>
    </Panel>
  )
}
