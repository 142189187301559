import { API } from './ApiClient'
import _ from 'lodash'

const sortByName = companies => _.sortBy(companies, company => company.name.toLowerCase())

export const getCompanies = () => API.get('/company').then(companies => sortByName(companies))
export const getFavoriteCompanies = () => API.get('/company/favorites').then(companies => sortByName(companies))
export const getCompany = (key, companyId) => API.get(`/company/${companyId}`)
export const addToFavorite = companyId => API.post('/company/favorites/' + companyId)
export const removeFromFavorite = companyId => API.delete('/company/favorites/' + companyId)
export const postCompany = body => API.post('/company', { body })
export const createSecurity = ({ security, companyId }) =>
  API.post(`/company/${companyId}/security`, { body: security })
export const updateCompany = ({ company, companyId }) => API.put(`/company/${companyId}`, { body: company })
export const getSecuritiesByCompany = (key, company_id) => API.get(`/company/${company_id}/securities`)
export const getCompanyInvestments = (key, companyId) => API.get(`/company/${companyId}/investments`)
export const getCompanyDebts = (key, companyId) => API.get(`/company/${companyId}/investments/debt-table`)
export const getCompanyShareCapTable = (key, companyId) => API.get(`/company/${companyId}/investments/share-cap-table`)
export const getCompanyStats = (key, companyId) => API.get(`/company/${companyId}/stats`)
export const getCompanyChangeLog = (key, companyId) => API.get(`/company/${companyId}/change_log`)
