import React from 'react'
import { useField } from 'react-final-form'
import { get } from 'lodash'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'

import Autocomplete from './Autocomplete'
import { composeValidators } from 'validators/composeValidators'

const filterOptions = createFilterOptions({
  ignoreCase: true,
})

export const FormAutocomplete = ({
  name,
  label = '',
  validators = [],
  options,
  id,
  parse,
  type = 'text',
  getOptionLabel,
  ...rest
}) => {
  const { input, meta } = useField(name, { validate: composeValidators(...validators), parse, type })
  const value = get(input, 'value', [])
  const showError = !!(meta.error || meta.submitError) && (meta.touched || meta.modified)

  const autocompleteProps = {
    ...rest,
    id,
    name,
    label,
    value,
    handleChange: (event, value) => input.onChange(value),
    onFocus: input.onFocus,
    onBlur: input.onBlur,
    options,
    error: showError,
    helperText: showError && (meta.error || meta.submitError),
    getOptionLabel,
    filterOptions,
  }

  return <Autocomplete {...autocompleteProps} />
}

export default FormAutocomplete
