import React, { useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import Add from '@material-ui/icons/Add'
import Close from '@material-ui/icons/Close'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import { TextInput } from './TextInput'
import { If } from './If'
import { Button } from '@material-ui/core'
import _ from 'lodash'
import { DEBOUNCE_DELAY } from 'constants/input'

const useStyles = makeStyles(theme => ({
  chipList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    gridGap: theme.spacing(1),
  },
  addChipButton: {
    borderRadius: '14px',
    textTransform: 'none',
    fontWeight: 'normal',
    color: theme.palette.grayShades.gray2,
  },
  input: {
    minHeight: '32px',
    height: '32px',
    border: `1px solid ${theme.palette.grayShades.gray4}`,
    '& .MuiInputBase-formControl.MuiInputBase-marginDense': {
      padding: 0,
      margin: 0,
    },
  },
}))

export const ChipList = ({
  options,
  addLabel,
  onInput,
  onChange,
  label,
  addButtonXsSize = 4,
  className = '',
  initialValues = [],
}) => {
  const classes = useStyles()
  const [values, setValues] = useState(initialValues)
  const [active, setActive] = useState(false)

  const completeOptions = options.filter(option => !values.includes(option))

  const delayedOnInput = _.debounce(inputValue => {
    onInput(inputValue)
  }, DEBOUNCE_DELAY)

  const renderAutocompleteInput = params => (
    <TextInput
      className={classes.input}
      autoFocus
      input={params.inputProps}
      {...params}
      variant="outlined"
      size="small"
      onChange={e => delayedOnInput(e.target.value)}
    />
  )

  const handleChange = (event, newValue) => {
    if (!newValue) {
      return
    }

    let value = newValue

    if (newValue.inputValue) {
      value = newValue.inputValue
    }

    setValues([...values, value])
    onChange && onChange([...values, value])
  }

  const handleAddClick = () => {
    setActive(true)
  }

  const handleDelete = value => () => {
    const affiliations = [...values.filter(v => v !== value)]
    setValues(affiliations)
    onChange && onChange([...affiliations])
  }

  const renderAddChip = () => (
    <If
      value={active}
      component={
        <Autocomplete
          freeSolo
          selectOnFocus
          clearOnBlur
          blurOnSelect
          handleHomeEndKeys
          onBlur={() => setActive(false)}
          renderInput={renderAutocompleteInput}
          options={completeOptions}
          onChange={handleChange}
        />
      }
      elseComponent={
        <Button
          variant="outlined"
          onClick={handleAddClick}
          size="small"
          className={classes.addChipButton}
          startIcon={<Add />}
        >
          {addLabel}
        </Button>
      }
    />
  )

  return (
    <Grid container wrap="wrap" spacing={1} alignContent="center" className={className}>
      <Grid item xs={12}>
        <InputLabel shrink error={false}>
          {label}
        </InputLabel>
      </Grid>
      {values.map(value => (
        <Grid item key={value}>
          <Chip label={value} deleteIcon={<Close />} onDelete={handleDelete(value)} color="primary" variant="default" />
        </Grid>
      ))}

      <Grid item xs={addButtonXsSize}>
        {renderAddChip()}
      </Grid>
    </Grid>
  )
}
