import React, { useState } from 'react'
import { NavLink, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { BreadCrumbsHeader } from 'components/BreadcrumbHeader'
import Grid from '@material-ui/core/Grid'
import { queryCache, useQuery } from 'react-query'
import { getInvestor, getInvestorInvestments } from 'api'
import { Spinner } from 'components/Spinner'
import Button from '@material-ui/core/Button'
import Add from '@material-ui/icons/Add'
import Box from '@material-ui/core/Box'
import { InvestmentCreate } from 'components/investment'
import { Container, fade } from '@material-ui/core'
import { InvestorDetailsRouter } from './InvestorDetailsRouter'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.common.blueGray,
    textDecoration: 'none',
    fontWeight: 500,
    marginRight: theme.spacing(7.5),
  },
  activeLink: {
    color: theme.palette.common.dark,
  },
  linksBorder: {
    borderBottom: `1px solid ${fade(theme.palette.grayShades.gray3, 0.5)}`,
  },
  exportButton: {
    marginRight: '14px',
  },
}))

export const InvestorDetails = () => {
  const classes = useStyles()
  let { investorId } = useParams()
  const [openCreateInvestmentModal, setOpenCreateInvestmentModal] = useState(false)
  let { url } = useRouteMatch()
  const history = useHistory()

  if (!parseInt(investorId, 10)) {
    history.push('/investors')
  }

  const { data: investor, isLoading, error: investorError } = useQuery(['investor', investorId], getInvestor, {
    enabled: investorId,
  })

  if (investorError) {
    // TODO ADD ERROR TOAST
    if (investorError.status === 404) {
      history.push('/investors')
    }
  }

  const { data: investments, isLoading: isLoadingInvestments } = useQuery(
    ['investorInvestments', investorId],
    getInvestorInvestments
  )

  const invalidateInvestments = () => queryCache.invalidateQueries('investorInvestments')

  const exportToPDF = async () => {
    const investoDOMElement = document.getElementById('investor-to-pdf')
    const canvas = await html2canvas(investoDOMElement)
    const imgData = canvas.toDataURL('image/png')

    const pdf = new jsPDF({
      orientation: 'l',
      unit: 'mm',
      format: 'a4',
    })

    pdf.addImage(imgData, 'PNG', 10, -8, 277, 0)
    pdf.save(investor.investor_name)
  }

  if (isLoading || isLoadingInvestments || !investor || !investments) {
    return <Spinner color="secondary" size={40} height="300px" centered />
  }

  return (
    <Grid>
      <BreadCrumbsHeader
        section="Investors"
        subsection="Investor Details"
        title={investor.investor_name}
        backPath="/investors"
      >
        <Box ml="auto">
          <Button variant="contained" color="primary" className={classes.exportButton} onClick={() => exportToPDF()}>
            Export as PDF
          </Button>

          <Button
            variant="contained"
            startIcon={<Add />}
            color="primary"
            onClick={() => setOpenCreateInvestmentModal(true)}
          >
            Add Investment
          </Button>

          <InvestmentCreate
            open={openCreateInvestmentModal}
            handleCancel={() => setOpenCreateInvestmentModal(false)}
            selectedInvestor={investor}
            disableInvestorSelect
            nextUrl={`${url}/pending-transaction-report`}
            afterCreate={invalidateInvestments}
          />
        </Box>
      </BreadCrumbsHeader>

      <Container maxWidth="xl" disableGutters>
        <Box px={6} pt={7} pb={12}>
          <Box width="100%" mb={6} className={classes.linksBorder} pb={4}>
            <NavLink to={`${url}/overview`} className={classes.link} activeClassName={classes.activeLink}>
              Overview
            </NavLink>

            <NavLink
              to={`${url}/portfolio-shareholdings`}
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              Portfolio Shareholdings
            </NavLink>

            <NavLink to={`${url}/debt`} className={classes.link} activeClassName={classes.activeLink}>
              Debt Table
            </NavLink>

            <NavLink to={`${url}/transaction-report`} className={classes.link} activeClassName={classes.activeLink}>
              Transaction Report
            </NavLink>

            <NavLink
              to={`${url}/pending-transaction-report`}
              className={classes.link}
              activeClassName={classes.activeLink}
            >
              New Pending Approval
            </NavLink>

            <NavLink to={`${url}/change-log`} className={classes.link} activeClassName={classes.activeLink}>
              Change Log
            </NavLink>
          </Box>

          <InvestorDetailsRouter investor={investor} investments={investments} />
        </Box>
      </Container>
    </Grid>
  )
}
