export const FIELDS_LABELS = {
  additional_notes: 'Notes',
  compounding_frequency: 'Compounding frequency',
  conversion_events: 'Conversion events',
  converts_to_security: 'Converts to',
  discount: 'Discount',
  invested_capital: 'Invested capital',
  downside_ratio: 'Preferential downside ratio',
  exercise_end: 'Exercise period end date',
  exercise_price: 'Exercise price',
  exercise_start: 'Exercise period start date',
  final_repayment_date: 'Final repayment date',
  frequency_of_payments: 'Frequency of payments',
  interest_rate: 'Interest rate',
  is_compounding: 'Straight or compounding',
  max_share_price: 'Valuation cap',
  name: 'Security',
  number_of_shares: 'Number of shares',
  option_price: 'Option price',
  preferential_rights: 'Preferential rights',
  rank: 'Rank',
  security: 'Security',
  share_price: 'Share price',
  type: 'Security type',
  upside_ratio: 'Preferential upside ratio',
  voting: 'Voting',
  warrant_price: 'Warrant price',
  realised_share_price: 'Realised share price',
  realised_capital: 'Realised capital',
}
