import React from 'react'

import { ChangeLog } from 'components/changeLog'
import { getCompanyChangeLog } from 'api'
import { useQuery } from 'react-query'
import { Spinner } from 'components'
import { formatChanges } from 'utils/changes'

export const CompanyChangeLog = ({ companyId }) => {
  const { data, isLoading } = useQuery(['companyChangeLog', companyId], getCompanyChangeLog, { enabled: !!companyId })

  if (isLoading || !data) {
    return <Spinner color="secondary" size={40} centered height="500px" />
  }

  return <ChangeLog formattedChanges={formatChanges(data)} />
}
