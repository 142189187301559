import React from 'react'
import { SecurityVoting } from './SecurityVoting'
import { SecurityRank } from './SecurityRank'
import { SecurityConvertsTo } from './SecurityConvertsTo'

export const AddOption = ({ onChange, validationErrors, errorProps, securityList }) => (
  <>
    <SecurityRank />

    <SecurityConvertsTo
      options={securityList}
      onChange={onChange}
      errorProps={errorProps}
      validationErrors={validationErrors}
    />

    <SecurityVoting />
  </>
)
