import moment from 'moment'

import { DATE_FORMAT } from 'constants/date'
import { SECURITY_TYPES } from 'constants/security-types'

const getExportData = (table, investments, investorMode) =>
  table === 'share'
    ? buildSharecapInvestmentsTable(investments, investorMode)
    : buildDebtInvestmentsTable(investments, investorMode)

function filterInvestmentsByType(investments = [], types = []) {
  return investments.filter(investment => types.includes(investment.investment_security.type))
}

function buildSharecapInvestmentsTable(investments, investorMode) {
  const types = [SECURITY_TYPES.EQUITY, SECURITY_TYPES.WARRANT, SECURITY_TYPES.OPTION]

  const shareInvestments = filterInvestmentsByType(investments, types)

  const header = [
    investorMode ? 'Company' : 'Investor',
    ...commonHeaderTable,
    'Potential upside ratio',
    'Preferential downside ratio',
    'Invested Capital',
    'Number of Shares',
    'Share Price',
  ]
  const body = shareInvestments.map(investment => {
    const { investment_security } = investment
    const { type: investmentType, security, investment_security_values } = investment_security

    return [
      investorMode ? investment.company.name : investment.investor.investor_name,
      ...getCommonBodyTable(investment),
      security[investmentType].upside_ratio,
      security[investmentType].downside_ratio,
      convertToPrice(investment_security_values.invested_capital),
      investment_security_values.number_of_shares,
      convertToPrice(investment_security_values.share_price),
    ]
  })

  return [header, ...body]
}

function buildDebtInvestmentsTable(investments, investorMode) {
  const types = [SECURITY_TYPES.DEBT, SECURITY_TYPES.CONVERTIBLE_DEBT]

  const debtInvestments = filterInvestmentsByType(investments, types)

  const header = [
    investorMode ? 'Company' : 'Investor',
    ...commonHeaderTable,
    'Interest Rate',
    'Compounding Frequency',
    'Frequency of Payments',
    'Discount',
    'Max Share Price',
    'Invested Capital ',
  ]
  const body = debtInvestments.map(investment => {
    const { investment_security } = investment
    const { type: investmentType, security, investment_security_values } = investment_security

    return [
      investorMode ? investment.company.name : investment.investor.investor_name,
      ...getCommonBodyTable(investment),
      security[investmentType].interest_rate,
      security[investmentType].compounding_frequency,
      security[investmentType].frequency_of_payments,
      security[investmentType].discount,
      convertToPrice(security[investmentType].max_share_price),
      convertToPrice(investment_security_values.invested_capital),
    ]
  })

  return [header, ...body]
}

const commonHeaderTable = [
  'Asset Name',
  'Investment Date',
  'Status',
  'Applicable Schemas',
  'Requested Schemas',
  'Fees Due',
  'Cash Received',
  'Investment Type',
  'Legal Investor',
  'Managed By',
  'Rank',
  'Voting',
]

function getCommonBodyTable(investment) {
  const { investment_security, investment_date, investment_status } = investment
  const { type: investmentType, security } = investment_security

  const investmentDate = moment(investment_date).format(DATE_FORMAT)
  const feesDue = investment.fees_due ? 'Yes' : 'No'
  const applicableSchemas = investment.applicable_schemas.join(', ')
  const voting = security[investmentType].voting ? 'Yes' : 'No'

  return [
    investment_security.security.name,
    investmentDate,
    investment_status,
    applicableSchemas,
    investment.requested_schemas,
    feesDue,
    investment.cash_received,
    investment.investment_type,
    investment.legal_investor?.investor_name,
    investment.managed_by.investor_name,
    security[investmentType].rank,
    voting,
  ]
}

const convertToPrice = (value = 0) => (typeof value === 'string' ? parseFloat(value).toFixed(2) : value.toFixed(2))

export default getExportData
