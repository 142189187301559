import React, { useContext } from 'react'
import { Form } from 'react-final-form'
import { Button, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { AuthContext } from 'providers/authProvider/AuthContext'
import { FormTextInput } from 'components'
import { requiredField, minLength, isEmail } from 'validators'
import { AuthPage } from './AuthPage'
import { apiErrors } from 'utils/error'
import { useToast } from 'hooks'

const useStyles = makeStyles(theme => ({
  registrationForm: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '1rem',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    padding: theme.spacing(5.5, 12.5),
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  submitButton: {
    margin: theme.spacing(2, 0, 3, 0),
  },
  fullWidth: {
    gridColumn: '1/3',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  signInLink: {
    marginLeft: theme.spacing(0.5),
  },
  error: {
    width: '100%',
    gridColumn: '1/3',
    margin: '15px 0',
  },
}))

export const SignupForm = () => {
  const { register, apiErrorMessage } = useContext(AuthContext)
  const classes = useStyles()
  const history = useHistory()

  const toastService = useToast()

  const validate = values => {
    const errors = {}

    if (values.confirm_password !== values.password) {
      errors.confirm_password = 'Your password and confirmation password do not match'
    }

    return errors
  }

  const onSubmit = async values => {
    const { confirm_password, ...credentials } = values

    try {
      await register(credentials, {
        throwOnError: true,
      })

      history.replace('/auth/signup/success')
      toastService.showSuccessToast('Registration was successful.')
    } catch (e) {
      toastService.showErrorToast(`Registration failed. ${e.message}`)
      return apiErrors(e)
    }
  }

  return (
    <AuthPage>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, submitting, pristine }) => (
          <form className={classes.registrationForm}>
            <Typography variant="h6" align="center" className={`${classes.fullWidth} ${classes.title}`}>
              Sign Up
            </Typography>

            <FormTextInput
              label="First Name"
              name="first_name"
              placeholder="First Name"
              validators={[requiredField]}
              required
            />

            <FormTextInput
              label="Last Name"
              placeholder="Last Name"
              name="last_name"
              validators={[requiredField]}
              required
            />

            <FormTextInput
              className={classes.fullWidth}
              label="Email"
              placeholder="Email"
              name="email"
              validators={[requiredField, isEmail]}
              required
            />

            <FormTextInput
              className={classes.fullWidth}
              label="Password"
              placeholder="Password"
              name="password"
              type="password"
              validators={[requiredField, minLength(6)]}
              required
            />
            <FormTextInput
              className={classes.fullWidth}
              label="Confirm Password"
              placeholder="Confirm Password"
              name="confirm_password"
              type="password"
              validators={[requiredField, minLength(6)]}
              required
            />

            <Button
              variant="contained"
              color="primary"
              disableElevation
              className={`${classes.fullWidth} ${classes.submitButton}`}
              fullWidth
              onClick={handleSubmit}
              disabled={pristine || submitting}
            >
              Create an Account
            </Button>

            {!!apiErrorMessage && (
              <Alert severity="error" className={classes.error}>
                {apiErrorMessage}
              </Alert>
            )}

            <Typography variant="body2" className={`${classes.fullWidth}`} align="center">
              <span>Already have an account?</span>

              <Typography variant="subtitle2" color="textSecondary" component="span" className={classes.signInLink}>
                <Link to="/auth/login">Sign In</Link>
              </Typography>
            </Typography>
          </form>
        )}
      />
    </AuthPage>
  )
}
