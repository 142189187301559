import React from 'react'
import { AuthPage } from './AuthPage'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  signupSuccess: {
    height: '400px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.customShadows.shadow1,
    padding: theme.spacing(2, 5),
    marginBottom: theme.spacing(2),
  },
  loginLink: {
    position: 'relative',
    top: '140px',
  },
}))
export const SignupSuccess = () => {
  const classes = useStyles()

  return (
    <AuthPage>
      <div className={classes.signupSuccess}>
        <Typography variant="h6" align="center">
          Account was created.
        </Typography>

        <Typography variant="body1" align="center">
          Please contact the administrator to activate it.
        </Typography>

        <Typography variant="subtitle2" color="textSecondary" className={classes.loginLink}>
          <Link to="/auth/login">Login</Link>
        </Typography>
      </div>
    </AuthPage>
  )
}
