import React from 'react'
import { FormDropdown } from 'components'
import { requiredField } from 'validators/requiredField'

export const SecurityConvertsTo = ({ options }) => {
  const convertsToOptions = options.map(option => ({
    name: option.name,
    value: option.id || option.name,
  }))

  return (
    <FormDropdown
      name="converts_to"
      label="Converts To"
      options={convertsToOptions}
      validators={[requiredField]}
      disabled={convertsToOptions.length === 0}
      required={true}
    />
  )
}
