import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import {
  Box,
  CardContent,
  Fade,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  Typography,
  Button,
} from '@material-ui/core'
import { percent, paddedNumber } from 'utils/numberFormat'
import classnames from 'classnames'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { CompanyTypeIcon } from 'components/CompanyTypeIcon'
import { TextTooltip } from 'components/TextTooltip'
import { useQuery } from 'react-query'
import { getCompanyShareCapTable, getCompanyStats } from 'api'
import { CurrencyCell, Spinner, TablePagination, SortableHeader } from 'components'
import { useSorting, useTablePagination } from 'hooks'
import { CSVLink } from 'react-csv'

const useStyles = makeStyles(theme => ({
  notableMetrics: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridColumnGap: theme.spacing(2),
  },
  tableContainer: {
    minHeight: 100,
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.customShadows.shadow1,
    borderRadius: '10px',
    padding: theme.spacing(4),
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  tooltipValue: {
    fontWeight: 'bold',
  },
  exportButton: {
    color: '#fff',
    textDecoration: 'none',
  },
}))

const SORT_LABELS = {
  INVESTOR: 'investor_name',
  FULLY_DILUTED_POSITION_VALUE: 'fully_diluted_position_value',
  VOTING_POSITION: 'voting_position',
  FULLY_DILUTED_PERCENTAGE: 'fully_diluted_percentage',
  VOTING_PERCENTAGE: 'voting_percentage',
  EQUITY_INVESTED_CAPITAL_VALUE: 'equity_invested_capital_value',
  OUTSTANDING_CAPITAL: 'outstanding_capital_unexercised_instruments_value',
}

export const ShareCapTable = ({ company }) => {
  const classes = useStyles()
  const [{ page, rowsPerPage }, onPageChange] = useTablePagination()
  const { data: shareCapTable = {}, isLoading: isLoadingShareCap } = useQuery(
    ['companyShareCap', company.id],
    getCompanyShareCapTable
  )
  const { data: companyStats = {}, isLoading: isLoadingStats } = useQuery(
    ['companyStats', company.id],
    getCompanyStats,
    {
      enabled: company?.id,
    }
  )

  const { stats, table = {} } = shareCapTable
  const { investments_list: investmentsList, footer } = table

  const [{ direction, field, sortedItems: sortedInvestments }, changeSorting] = useSorting({
    getAccessor,
    initialField: SORT_LABELS.INVESTOR,
    items: investmentsList || [],
  })

  if (isLoadingShareCap || isLoadingStats) {
    return <Spinner color="secondary" size={40} centered height="500px" />
  }

  const getTooltipText = (values, format = value => value) =>
    Object.keys(values).map(key => (
      <Grid key={key} container spacing={2} justify={'space-around'}>
        <Grid item>{key}:</Grid>
        <Grid item className={classes.tooltipValue}>
          {format(values[key])}
        </Grid>
      </Grid>
    ))

  const cardClasses = classnames([classes.card])

  const getExportData = () => {
    const header = [
      'Investor',
      'Security Name',
      'Total Fully Diluted Position',
      'Total Voting Position',
      'Fully Diluted %',
      'Voting %',
      'Total Cost of Investment',
      'Outstanding Capital Unexercised Instruments',
    ]

    const exportValues = investmentsList.map(row => {
      let details = [
        [
          row.investor_name,
          '',
          // row.details_investment.length === 1 ? row.security_name : '',
          row.fully_diluted_position_value,
          row.voting_position,
          percent(row.fully_diluted_percentage),
          percent(row.voting_percentage),
          `£${convertToPrice(row.cost_of_investment)}`,
          `£${convertToPrice(row.outstanding_capital_unexercised_instruments_value)}`,
        ],
      ]
      if (row.details_investment.length > 0) {
        console.log(row.details_investment)
        row.details_investment.map(investmentDetails =>
          details.push([
            '',
            investmentDetails.security_name,
            investmentDetails.fully_diluted_position_value,
            investmentDetails.voting_position,
            percent(investmentDetails.fully_diluted_percentage),
            percent(investmentDetails.voting_percentage),
            `£${convertToPrice(investmentDetails.equity_invested_capital_value)}`,
            `£${convertToPrice(investmentDetails.outstanding_capital_unexercised_instruments_value)}`,
          ])
        )
      }
      return details
    })

    const footerList = [
      [],
      [
        'Total',
        '',
        footer.total_fully_diluted_position_value,
        footer.total_voting_position,
        percent(100),
        percent(100),
        `£${convertToPrice(footer.total_equity_invested_capital_value)}`,
        `£${convertToPrice(footer.total_outstanding_capital_unexercised_instruments_value)}`,
      ],
    ]

    return [header, ...exportValues.flat(), ...footerList]
  }

  const convertToPrice = (value = 0) => (typeof value === 'string' ? parseFloat(value).toFixed(2) : value.toFixed(2))

  return (
    <>
      <Fade timeout={1000} in>
        <div className={classes.notableMetrics}>
          <Card className={cardClasses}>
            <CardContent>
              <Typography variant="h6">£{paddedNumber(stats.total_invested_capital)}</Typography>
              <Typography variant="body2" color="textSecondary">
                Total Invested Capital
              </Typography>
              <Typography variant="h6">£{paddedNumber(stats.total_invested_capital_fig_nm)}</Typography>
              <Typography variant="body2" color="textSecondary">
                Total Invested Capital by Fig & NM
              </Typography>
            </CardContent>
          </Card>

          <Card className={cardClasses}>
            <CardContent>
              <Typography variant="h6">{paddedNumber(stats.total_shares)}</Typography>
              <Typography variant="body2" color="textSecondary">
                Total fully diluted number of shares
              </Typography>
              <Typography variant="h6">{paddedNumber(stats.total_shares_fig_nm)}</Typography>
              <Typography variant="body2" color="textSecondary">
                Total Fig and NM fully diluted number of shares
              </Typography>
            </CardContent>
          </Card>

          <Card className={cardClasses}>
            <CardContent>
              <Typography variant="h6">{paddedNumber(stats.total_voting_position)}</Typography>
              <Typography variant="body2" color="textSecondary">
                Total voting number of shares
              </Typography>
              <Typography variant="h6">{paddedNumber(stats.total_voting_position_fig_nm)}</Typography>
              <Typography variant="body2" color="textSecondary">
                Total voting number of shares held by Fig & NM
              </Typography>
            </CardContent>
          </Card>

          <Card className={cardClasses}>
            <CardContent>
              <Typography variant="h6">£{paddedNumber(stats.valuation_current_market_share_price)}</Typography>
              <Typography variant="body2" color="textSecondary">
                Fully diluted valuation at current marketed share price
              </Typography>
              <Typography variant="h6">£{paddedNumber(stats.valuation_current_market_share_price_fig_nm)}</Typography>
              <Typography variant="body2" color="textSecondary">
                Fully diluted value held by Fig & NM investors
              </Typography>
            </CardContent>
          </Card>

          <Card className={cardClasses}>
            <CardContent>
              <Typography variant="h6">{percent(companyStats.fig_investor_issued_number_of_shares)}</Typography>
              <Typography variant="body2" color="textSecondary">
                Total voting % held by Fig & NM
              </Typography>
              <Typography variant="h6">{percent(stats.fully_deluted_nm_and_fig_percentage)}</Typography>
              <Typography variant="body2" color="textSecondary">
                Fully diluted % held by Fig & NM
              </Typography>
            </CardContent>
          </Card>
        </div>
      </Fade>

      <Fade timeout={1000} in>
        <Box mt={7.5} width="100%">
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORT_LABELS.INVESTOR}
                      clickHandler={changeSorting}
                    >
                      Investor
                    </SortableHeader>
                  </TableCell>
                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORT_LABELS.FULLY_DILUTED_POSITION_VALUE}
                      clickHandler={changeSorting}
                    >
                      Total Fully Diluted Position
                    </SortableHeader>
                  </TableCell>
                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORT_LABELS.VOTING_POSITION}
                      clickHandler={changeSorting}
                    >
                      Total Voting Position
                    </SortableHeader>
                  </TableCell>
                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORT_LABELS.FULLY_DILUTED_PERCENTAGE}
                      clickHandler={changeSorting}
                    >
                      Fully Diluted %
                    </SortableHeader>
                  </TableCell>
                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORT_LABELS.VOTING_PERCENTAGE}
                      clickHandler={changeSorting}
                    >
                      Voting %
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORT_LABELS.EQUITY_INVESTED_CAPITAL_VALUE}
                      clickHandler={changeSorting}
                    >
                      Total Cost of Investment
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      name={SORT_LABELS.OUTSTANDING_CAPITAL}
                      clickHandler={changeSorting}
                    >
                      Outstanding Capital Unexercised Instruments
                    </SortableHeader>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedInvestments?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(investor => (
                  <TableRow key={investor.investor_name}>
                    <TableCell>
                      <CompanyTypeIcon type={investor.investor_type} />
                    </TableCell>
                    <TableCell>{investor.investor_name}</TableCell>

                    <TableCell>
                      <TextTooltip
                        arrow
                        title={getTooltipText(investor.fully_diluted_position_by_investment, paddedNumber)}
                      >
                        <span>{paddedNumber(investor.fully_diluted_position_value)}</span>
                      </TextTooltip>
                    </TableCell>

                    <TableCell>{investor.voting_position ? paddedNumber(investor.voting_position) : '-'}</TableCell>

                    <TableCell>{percent(investor.fully_diluted_percentage)}</TableCell>

                    <TableCell>{percent(investor.voting_percentage)}</TableCell>

                    <TableCell>
                      <CurrencyCell
                        value={investor.cost_of_investment}
                        tooltipTitle={getTooltipText(investor.cost_of_investment_by_investment)}
                      />
                    </TableCell>

                    <TableCell width="15%">
                      <CurrencyCell
                        value={investor.outstanding_capital_unexercised_instruments_value}
                        tooltipTitle={getTooltipText(
                          investor.outstanding_capital_unexercised_instruments_by_investment
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell />
                  <TableCell>Total</TableCell>
                  <TableCell>{paddedNumber(footer.total_fully_diluted_position_value)}</TableCell>
                  <TableCell>{paddedNumber(footer.total_voting_position)}</TableCell>
                  <TableCell>{percent(100)}</TableCell>
                  <TableCell>{percent(100)}</TableCell>
                  <TableCell>
                    <CurrencyCell value={footer.total_cost_of_investment} />
                  </TableCell>
                  <TableCell>
                    <CurrencyCell value={footer.total_outstanding_capital_unexercised_instruments_value} />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          <TablePagination
            count={investmentsList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onPageChange}
          />
          <Button variant="contained" color="primary">
            <CSVLink className={classes.exportButton} data={getExportData()}>
              Export table to CSV
            </CSVLink>
          </Button>
        </Box>
      </Fade>
    </>
  )
}

function getAccessor(field) {
  return investment => investment[field]
}
