import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Typography } from '@material-ui/core'
import { Spinner } from './Spinner'
import classnames from 'classnames'
import { FadeContent } from './FadeContent'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  content: {
    position: 'relative',
  },
  modal: {
    width: '100%',
  },
  disabled: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
  spinner: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%)',
  },
}))

export const Modal = ({
  modalTitle,
  modalContent,
  modalActions,
  open,
  modalClass,
  modalHeaderClass = '',
  modalContentClass = '',
  modalActionClass = '',
  isLoading = false,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      classes={{
        paper: `${classes.modal} ${modalClass}`,
      }}
      disableBackdropClick={true}
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <FadeContent>
        <DialogTitle disableTypography={true} className={modalHeaderClass} id="modal-title">
          <Typography variant="h3" className={classes.title}>
            {modalTitle}
          </Typography>
        </DialogTitle>
      </FadeContent>

      <FadeContent>
        <DialogContent className={`${classes.content} ${modalContentClass}`}>
          <div className={classnames({ [classes.disabled]: isLoading })}>{modalContent}</div>

          {isLoading && (
            <div className={classes.spinner}>
              <Spinner color="secondary" size={40} />
            </div>
          )}
        </DialogContent>
      </FadeContent>
      <FadeContent>
        <DialogActions className={`${modalActionClass}`}>{modalActions}</DialogActions>
      </FadeContent>
    </Dialog>
  )
}
