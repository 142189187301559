import React from 'react'
import { Helmet } from 'react-helmet'

import { SignupForm } from 'components/auth'

export const Signup = () => (
  <>
    <Helmet>
      <title>New Model VC Dashboard - Sign Up</title>
    </Helmet>
    <SignupForm />
  </>
)
