import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Typography } from '@material-ui/core'

import { ApprovalsList } from 'components/approvals'
import { SearchBar } from 'components'

export const Approvals = () => {
  const [term, setTerm] = useState('')

  return (
    <>
      <Helmet>
        <title>New Model VC Dashboard - Approvals</title>
      </Helmet>
      <div>
        <SearchBar onSearch={setTerm} header={<Typography variant="h6">Approvals</Typography>} />

        <ApprovalsList searchTerm={term} />
      </div>
    </>
  )
}
