import React from 'react'
import { FormTextInput, FormTextArea } from 'components'
import { SecurityVoting } from './SecurityVoting'
import { SecurityInterestRate } from './SecurityInterestRate'
import { SecurityCompounding } from './SecurityCompounding'
import { SecurityPaymentFrequency } from './SecurityPaymentFrequency'
import { SecurityRank } from './SecurityRank'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { requiredField } from 'validators/requiredField'

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1, 0),
  },
  textArea: {
    gridColumn: '1/3',
  },
}))

export const AddConvertibleDebt = () => {
  const classes = useStyles()
  return (
    <>
      <SecurityVoting className={classes.margin} />

      <SecurityInterestRate className={classes.margin} />

      <SecurityCompounding className={classes.margin} />

      <SecurityPaymentFrequency className={classes.margin} />

      <FormTextInput
        className={classes.margin}
        label="Discount, %"
        required={true}
        name="discount"
        type="number"
        validators={[requiredField]}
        parse={value => parseFloat(value)}
      />

      <FormTextInput
        className={classes.margin}
        label="Valuation cap, £"
        required={true}
        name="max_share_price"
        type="number"
        validators={[requiredField]}
        parse={value => parseFloat(value)}
      />

      <SecurityRank className={classes.margin} />

      <FormTextArea
        className={`${classes.textArea} ${classes.margin}`}
        name="conversion_events"
        required={true}
        validators={[requiredField]}
        label="Conversion events"
        rows={3}
      />

      <FormTextArea
        className={`${classes.textArea} ${classes.margin}`}
        name="additional_notes"
        required={false}
        label="Additional notes"
        rows={3}
      />
    </>
  )
}
