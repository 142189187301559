import { SECURITY_TYPES } from 'constants/security-types'

export const getFields = securityType => {
  let fields = ['name', 'type']

  switch (securityType) {
    case SECURITY_TYPES.OPTION:
      return [...fields, 'voting', 'rank', 'converts_to_security']
    case SECURITY_TYPES.EQUITY:
      return [...fields, 'voting', 'rank', 'upside_ratio', 'downside_ratio']
    case SECURITY_TYPES.WARRANT:
      return [...fields, 'voting', 'rank', 'converts_to_security', 'preferential_rights']
    case SECURITY_TYPES.DEBT:
      return [
        ...fields,
        'interest_rate',
        'is_compounding',
        'compounding_frequency',
        'frequency_of_payments',
        'final_repayment_date',
        'rank',
        'security',
        'additional_notes',
      ]
    case SECURITY_TYPES.CONVERTIBLE_DEBT:
      return [
        ...fields,
        'voting',
        'interest_rate',
        'is_compounding',
        'compounding_frequency',
        'frequency_of_payments',
        'discount',
        'max_share_price',
        'rank',
        'conversion_events',
        'additional_notes',
      ]
    default:
      return fields
  }
}
