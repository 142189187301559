import React from 'react'
import { capitalize, fade } from '@material-ui/core'
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core'

import moment from 'moment'
import { paddedNumber, percent } from 'utils/numberFormat'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import { DATE_FORMAT } from 'constants/date'
import Collapse from '@material-ui/core/Collapse'
import { CurrencyCell } from 'components/CurrencyCell'
import { useTablePagination, useSorting } from 'hooks'
import { TablePagination, FadeContent, SortableHeader, TooltipTypography } from 'components'
import { CSVLink } from 'react-csv'

const useStyles = makeStyles(theme => ({
  notableMetrics: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridColumnGap: theme.spacing(2),
  },
  totalValue: {
    marginLeft: theme.spacing(1),
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.customShadows.shadow1,
    borderRadius: '10px',
    padding: theme.spacing(4),
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  iconCell: {
    textAlign: 'end',
  },
  tableContainer: {
    minHeight: 100,
  },
  tableRow: {
    padding: theme.spacing(2, 5),
    height: '50px',
    backgroundColor: theme.palette.grayShades.gray5,

    '&.MuiTableRow-head': {
      padding: theme.spacing(2, 5),
      height: '50px',
      backgroundColor: fade(theme.palette.common.cloudyBlue, 0.3),
    },

    '& .MuiTableCell-head': {
      color: theme.palette.common.blueGray,
    },
    '& td:first-child, & th:first-child': {
      paddingLeft: theme.spacing(5),
    },
  },
  detailsTable: {
    borderBottom: 'none',
  },
  exportButton: {
    color: '#fff',
    textDecoration: 'none',
  },
}))

const SORT_LABELS = {
  DEBT_OWNER: 'investor_name',
  ASSET_NAME: 'asset_name',
  PRINCIPAL: 'principal',
  REALISED_CAPITAL: 'realised_capital',
  ANNUAL_INTEREST_RATE: 'interest_rate',
  TERMINATION_DATE: 'termination_date',
  CONVERSION_DISCOUNT: 'conversion_discount',
  START_DATE: 'investment_date',
}

export const DebtTable = ({ debtOwner, debtDetails = [] }) => {
  const classes = useStyles()
  const [{ page, rowsPerPage }, onChangePage] = useTablePagination()
  const { stats, investmentsList } = debtDetails

  const [{ direction, field, sortedItems }, changeSorting] = useSorting({
    getAccessor,
    initialField: SORT_LABELS.DEBT_OWNER,
    items: investmentsList,
  })

  const showRealisedCapital = debtOwner === 'Company'

  return (
    <>
      <FadeContent>
        <div className={classes.notableMetrics}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6">
                £<span className={classes.totalValue}>{paddedNumber(stats.totalPrincipal)}</span>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Total principal
              </Typography>
            </CardContent>
          </Card>

          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6">{percent(stats.totalInterest)}</Typography>
              <Typography variant="body2" color="textSecondary">
                Weighted interest rate
              </Typography>
            </CardContent>
          </Card>
        </div>
      </FadeContent>

      <FadeContent>
        <Box mt={5}>
          <TableContainer className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      clickHandler={changeSorting}
                      name={SORT_LABELS.DEBT_OWNER}
                    >
                      {debtOwner}
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    {debtOwner !== 'Company' ? (
                      <SortableHeader
                        direction={direction}
                        byField={field}
                        clickHandler={changeSorting}
                        name={SORT_LABELS.ASSET_NAME}
                      >
                        Asset Name
                      </SortableHeader>
                    ) : (
                      'Asset Name'
                    )}
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      clickHandler={changeSorting}
                      name={SORT_LABELS.PRINCIPAL}
                    >
                      Principal
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    {showRealisedCapital && (
                      <SortableHeader
                        direction={direction}
                        byField={field}
                        clickHandler={changeSorting}
                        name={SORT_LABELS.REALISED_CAPITAL}
                      >
                        Realised Capital
                      </SortableHeader>
                    )}
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      clickHandler={changeSorting}
                      name={SORT_LABELS.ANNUAL_INTEREST_RATE}
                    >
                      Annual Interest Rate
                    </SortableHeader>
                  </TableCell>

                  <TableCell>Interest Calc</TableCell>

                  <TableCell>Interest Payment Frequency</TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      clickHandler={changeSorting}
                      name={SORT_LABELS.TERMINATION_DATE}
                    >
                      Termination Date
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      clickHandler={changeSorting}
                      name={SORT_LABELS.CONVERSION_DISCOUNT}
                    >
                      Conversion Discount
                    </SortableHeader>
                  </TableCell>

                  <TableCell>
                    <SortableHeader
                      direction={direction}
                      byField={field}
                      clickHandler={changeSorting}
                      name={SORT_LABELS.START_DATE}
                    >
                      Start Date
                    </SortableHeader>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(debt => (
                  <DebtRow key={debt.securityId} debt={debt} showRealisedCapital={showRealisedCapital} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            count={investmentsList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
          />
          <Button variant="contained" color="primary">
            <CSVLink className={classes.exportButton} data={getExportData(investmentsList)}>
              Export table to CSV
            </CSVLink>
          </Button>
        </Box>
      </FadeContent>
    </>
  )
}

const Row = ({ debt, className = '', showRealisedCapital }) => {
  const classes = useStyles()
  return (
    <TableRow className={className}>
      <TableCell width="2%" className={classes.iconCell}>
        {debt.iconCell}
      </TableCell>
      <TableCell width="11%">
        <TooltipTypography maxWidth={133}>{debt.investor_name}</TooltipTypography>
      </TableCell>
      <TableCell width="11%">
        <TooltipTypography maxWidth={133}>{debt.asset_name}</TooltipTypography>
      </TableCell>
      <TableCell width={showRealisedCapital ? '10%' : '16%'}>
        <CurrencyCell value={debt.principal} />
      </TableCell>
      <TableCell width={showRealisedCapital ? '10%' : '1%'}>
        {showRealisedCapital && <CurrencyCell value={debt.realised_capital} />}
      </TableCell>
      <TableCell width="11%">{percent(debt.interest_rate)}</TableCell>
      <TableCell width="8%">{debt.interest_calc && capitalize(debt.interest_calc)}</TableCell>
      <TableCell width="11%">
        {debt.interest_frequency_payments && capitalize(debt.interest_frequency_payments)}
      </TableCell>
      <TableCell width="9%">{moment(debt.termination_date).format(DATE_FORMAT)}</TableCell>
      <TableCell width="9%">{debt.conversion_discount === 0 ? 'N/A' : percent(debt.conversion_discount)}</TableCell>
      <TableCell width="8%">{moment(debt.investment_date).format(DATE_FORMAT)}</TableCell>
    </TableRow>
  )
}

const DebtRow = ({ debt, showRealisedCapital }) => {
  const classes = useStyles()

  if (debt.debtDetails) {
    debt.investment_date = Math.min(...debt.debtDetails.map(getAccessor(SORT_LABELS.START_DATE)))
  }

  return (
    <>
      <Row debt={debt} showRealisedCapital={showRealisedCapital} />

      {debt?.debtDetails?.length && (
        <TableRow>
          <TableCell colSpan={11} padding="none" className={classes.detailsTable}>
            <Collapse in={debt.open} timeout="auto">
              <Table>
                <TableBody>
                  {debt.debtDetails.map((details, index) => (
                    <Row
                      key={index}
                      debt={details}
                      className={classes.tableRow}
                      showRealisedCapital={showRealisedCapital}
                    />
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const getExportData = debtDetails => {
  const header = [
    'Investor',
    'Asset Name',
    'Principal',
    'Annual Interest Rate',
    'Interest Calc',
    'Interest Payment Frequency',
    'Termination Date',
    'Conversion Discount',
  ]

  const exportValues = debtDetails.map(debt => {
    const { debtDetails } = debt

    let exportList = []

    exportList.push([debt.investor_name, ...getCommonFields(debt)])

    if (debtDetails && debtDetails.length > 0) {
      debtDetails.forEach(debt => exportList.push(['', ...getCommonFields(debt)]))
    }
    return exportList
  })

  return [header, ...exportValues.flat()]
}

const getCommonFields = debt => [
  debt.asset_name,
  `£${debt.principal}`,
  percent(debt.interest_rate),
  capitalize(debt.interest_calc),
  capitalize(debt.interest_frequency_payments),
  moment(debt.termination_date).format(DATE_FORMAT),
  debt.conversion_discount === 0 ? 'N/A' : percent(debt.conversion_discount),
]

const getAccessor = field => debt => {
  switch (field) {
    case SORT_LABELS.TERMINATION_DATE:
    case SORT_LABELS.START_DATE:
      return moment(debt[field]).valueOf()
    default:
      return debt[field]
  }
}
