import React from 'react'
import TextField from '@material-ui/core/TextField'
import { useField } from 'react-final-form'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { composeValidators } from 'validators/composeValidators'
import { HintTooltip } from './HintTooltip'

export const useStyles = makeStyles(() => ({
  inputRight: {
    textAlign: 'right',
  },
  labelRoot: {
    color: '#b0b0b0',
  },
}))

export const TextInput = ({
  label,
  required,
  className,
  onChange,
  inputTextAlignment,
  endAdornment,
  startAdornment,
  input,
  meta,
  error,
  helperText,
  hintText = '',
  ...props
}) => {
  const classes = useStyles()

  const labelProps = {
    shrink: true,
  }

  const inputProps = {
    classes: {
      input: inputTextAlignment === 'right' ? classes.inputRight : '',
    },
    disableUnderline: true,
    endAdornment,
    startAdornment,
  }

  const inputLabel = () => {
    if (!hintText) {
      return label
    }

    return (
      <>
        {label}
        <HintTooltip text={hintText} />
      </>
    )
  }

  return (
    <TextField
      className={className}
      InputLabelProps={labelProps}
      InputProps={inputProps}
      label={inputLabel()}
      type="text"
      required={required}
      onChange={onChange ? onChange : input ? input.onChange : e => e}
      error={error}
      helperText={helperText}
      {...props}
    />
  )
}

export const FormTextInput = ({
  // RFF props
  name,
  validate,
  // MaterialUI props
  fullWidth = true,
  required = false,
  helperText = '',
  validators = [],
  parse,
  type,
  onChange,
  onBlur,
  externalError = '',
  ...rest
}) => {
  const { input, meta } = useField(name, { validate: composeValidators(...validators), parse, type })
  const showError = (meta.error || meta.submitError || externalError) && (meta.touched || meta.modified)

  const handleChange = event => {
    input.onChange(event)
    onChange && onChange(event)
  }

  const handleBlur = event => {
    input.onBlur(event)
    onBlur && onBlur(event)
  }

  const componentProps = {
    ...rest,
    ...input,
    onChange: handleChange,
    onBlur: handleBlur,
    fullWidth,
    required,
    error: showError,
    helperText: showError ? meta.error || meta.submitError || externalError : helperText,
  }

  return <TextInput {...componentProps} />
}
