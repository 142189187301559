import React from 'react'

import ChangeIcon from 'assets/images/change.svg'
import NewIcon from 'assets/images/new.svg'
import ConvertIcon from 'assets/images/convert.svg'
import RealisedIcon from 'assets/images/realise.svg'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  icon: {
    width: '18px',
    height: '18px',
  },
}))

export const ChangeTypeIcon = ({ type, subType }) => {
  const classes = useStyles()

  if (type === 'create' || type === null) {
    return <img className={classes.icon} src={NewIcon} alt="New" />
  }

  if (type === 'edit') {
    return <img className={classes.icon} src={ChangeIcon} alt="Changed" />
  }

  if (type === 'split') {
    if (subType === 'Live') {
      return <img className={classes.icon} src={ConvertIcon} alt="Converted" />
    }

    if (subType === 'Realised') {
      return <img className={classes.icon} src={RealisedIcon} alt="Realised" />
    }
  }

  return null
}
