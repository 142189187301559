import React from 'react'
import { Grid, Link } from '@material-ui/core'

export const DocumentDetails = ({ value, emptyValue = '-', linkClass = '' }) => {
  if (!value || value.length === 0) {
    return emptyValue
  }

  return (
    <Grid container direction="column">
      {value.map(document => (
        <Grid key={document.storage_url} item>
          <Link
            href={document.storage_url}
            target="_blank"
            rel="noreferrer"
            color="textPrimary"
            underline="always"
            className={linkClass}
          >
            {document.file_name}
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}
