import React from 'react'
import { Helmet } from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Switch from '@material-ui/core/Switch'
import { useQuery, useMutation, queryCache } from 'react-query'

import { getUsers, setAdminRole as setAdminRoleApi, revokeAdminRole as revokeAdminRoleApi } from 'api'
import { ROLES } from 'constants/roles'
import { BreadCrumbsHeader, FadeContent } from 'components'
import { useToast } from 'hooks'

const useStyles = makeStyles(theme => ({
  root: {
    width: '650px',
    minHeight: '600px',
    margin: '0 auto',
    backgroundColor: theme.palette.background.paper,
  },
  pageHeader: {
    padding: theme.spacing(4, 6),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export const UserManagement = () => {
  const classes = useStyles()
  const toastService = useToast()

  const { data: users = [], isLoading } = useQuery('users', getUsers)

  const [setAdminRole] = useMutation(setAdminRoleApi, {
    onSuccess: () => {
      queryCache.invalidateQueries('users')
      toastService.showSuccessToast('Admin permissions granted.')
    },
  })
  const [revokeAdminRole] = useMutation(revokeAdminRoleApi, {
    onSuccess: () => {
      queryCache.invalidateQueries('users')
      toastService.showInfoToast('Admin permissions revoked.')
    },
  })

  const handleToggle = (id, existingRole) => async () => {
    if (existingRole === ROLES.ADMIN) {
      await revokeAdminRole(id)
    } else {
      await setAdminRole(id)
    }
  }

  if (isLoading) {
    return (
      <>
        <div>Loading...</div>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>New Model VC Dashboard - User Management</title>
      </Helmet>
      <FadeContent>
        <Grid>
          <BreadCrumbsHeader title="User Management" hideBackButton className={classes.pageHeader} />

          <Box mt={5}>
            <List
              subheader={
                <ListSubheader disableGutters>
                  <div className={classes.header}>
                    <Typography variant="body1" color="textSecondary" component="div">
                      Users
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="div">
                      Admin Rights
                    </Typography>
                  </div>
                </ListSubheader>
              }
              className={classes.root}
            >
              {users.map(user => (
                <ListItem key={user.id}>
                  <ListItemText primary={user.full_name} secondary={user.email} />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={handleToggle(user.id, user?.role?.name)}
                      checked={user?.role?.name === ROLES.ADMIN}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </FadeContent>
    </>
  )
}
