import React from 'react'
import { Redirect, Switch, useRouteMatch } from 'react-router-dom'
import { RoleRoute } from 'components/RoleRoute'
import { ALL_USERS } from 'constants/roles'

import { useAuth } from 'hooks/useAuth'
import { ShareCapTable } from './ShareCapTable'
import { CompanyDebtTable } from './CompanyDebtTable'
import { CompanyOverview } from './CompanyOverview'
import { PendingTransactionReport, TransactionReport } from 'components/transactionReport'
import { CompanyChangeLog } from './CompanyChangeLog'

export const CompanyDetailsRouter = ({ company, companyInvestments }) => {
  const { role } = useAuth()
  let { path, url } = useRouteMatch()

  return (
    <Switch>
      <RoleRoute
        exact
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}`}
        render={() => <Redirect to={`${url}/overview`} />}
      />

      <RoleRoute
        component={props => <CompanyOverview {...props} company={company} />}
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}/overview`}
      />

      <RoleRoute
        component={props => <ShareCapTable {...props} company={company} />}
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}/share-cap-table`}
      />

      <RoleRoute
        component={props => <CompanyDebtTable {...props} company={company} />}
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}/debt`}
      />

      <RoleRoute
        component={props => <TransactionReport {...props} investments={companyInvestments} company={company} />}
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}/transaction-report`}
      />

      <RoleRoute
        component={props => <PendingTransactionReport {...props} investments={companyInvestments} company={company} />}
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}/pending-transaction-report`}
      />

      <RoleRoute
        component={props => <CompanyChangeLog {...props} companyId={company?.id} />}
        allowedRoles={ALL_USERS}
        role={role}
        path={`${path}/change-log`}
      />
    </Switch>
  )
}
