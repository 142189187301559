import { INVESTMENT_TYPES, SECURITY_TYPES } from '../../constants'

export const tileValuesCalculation = (investments = [], company) => {
  let fullyDiluted = 0
  let totalInvested = 0
  let totalPrincipal = 0
  const currentSharePrice = parseFloat(company.current_market_price)

  for (let investment of investments) {
    if (investment.investment_status !== 'Live') {
      continue
    }

    const security = investment.investment_security
    const securityValues = security.investment_security_values
    const investmentType = investment.investment_type
    const isFigNM = isFigOrNewModel(investmentType)

    if (isNotDebt(security.type)) {
      fullyDiluted += parseInt(securityValues.number_of_shares)

      if (isEquity(security.type) && isFigNM) {
        totalInvested += parseFloat(securityValues.invested_capital)
      }
    } else {
      totalPrincipal += isFigNM ? parseFloat(securityValues.invested_capital) : 0
    }
  }

  return { totalInvested, fullyDilutedValuation: fullyDiluted * currentSharePrice, totalPrincipal }
}

function isFigOrNewModel(investmentType) {
  return investmentType === INVESTMENT_TYPES.FIG || investmentType === INVESTMENT_TYPES.NEW_MODEL
}

function isEquity(securityType) {
  return securityType === SECURITY_TYPES.EQUITY
}

function isNotDebt(securityType) {
  return [SECURITY_TYPES.EQUITY, SECURITY_TYPES.WARRANT, SECURITY_TYPES.OPTION].includes(securityType)
}
