import React from 'react'

import { useAuth } from 'hooks/useAuth'

export const HasRole = ({ roles = [], children, fallback }) => {
  const { isAuthenticated, role } = useAuth()
  const isAllowed = roles.includes(role)

  if (!roles || !roles.length) return null
  if (!isAuthenticated || !isAllowed) return fallback ? <>{fallback}</> : null

  return <>{children}</>
}

export default HasRole
