import { splitAttachments } from 'utils/investment'

export const normalizeInvestmentChanges = (changes = []) => {
  const changesMap = {}
  const ignoredFields = ['subscription_document', 'scheme_paperwork']
  const investorFields = ['investor', 'managed_by', 'legal_investor']

  if (changes === null) {
    return changesMap
  }

  let applicable_schemas = { lhs: [], rhs: [] }

  for (let c of changes) {
    const [prefix] = c.path
    if (ignoredFields.includes(prefix)) {
      continue
    }

    if (investorFields.includes(prefix)) {
      changesMap[prefix] = flattenInvestor(c)
    } else if (prefix === 'attachments') {
      const attachments = transformAttachments(c)
      Object.keys(attachments).forEach(attachmentType => {
        changesMap[attachmentType] = attachments[attachmentType]
      })
    } else if (prefix === 'applicable_schemas') {
      applicable_schemas = collectApplicableSchemas(applicable_schemas, c)
    } else if (prefix === 'investment_date') {
      if (dateChanged(c)) {
        changesMap[prefix] = c
      }
    } else {
      changesMap[prefix] = c
    }
  }

  changesMap.applicable_schemas = applicable_schemas

  return changesMap
}

const getInvestorName = investor => investor?.investor_name

function flattenInvestor(investorChanges) {
  const { lhs = {}, rhs = {} } = investorChanges

  return { lhs: getInvestorName(lhs), rhs: getInvestorName(rhs) }
}

function transformAttachments(c) {
  const lhs = splitAttachments(c.lhs)
  const rhs = splitAttachments(c.rhs)

  const scheme_paperwork = { lhs: lhs.scheme_paperwork, rhs: rhs.scheme_paperwork }
  const share_certificate = { lhs: lhs.share_certificate, rhs: rhs.share_certificate }
  const subscription_document = { lhs: lhs.subscription_document, rhs: rhs.subscription_document }

  return { scheme_paperwork, share_certificate, subscription_document }
}

function collectApplicableSchemas(applicable_schemas, c) {
  const result = { ...applicable_schemas }

  if (c.kind === 'E') {
    result.lhs.push(c.lhs)
    result.rhs.push(c.rhs)
  } else if (c.kind === 'A') {
    result.rhs.push(c.item.rhs)
  }

  return result
}

function dateChanged(changes) {
  return changes.lhs !== changes.rhs
}
