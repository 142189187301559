import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Panel, EmptyList } from 'components'

const useStyles = makeStyles(() => ({
  panel: {
    paddingTop: 0,
  },
  securitiesList: {},
}))

export const CompanyInvestmentList = () => {
  const classes = useStyles()

  return (
    <Panel title="Investments" panelClass={classes.panel}>
      <EmptyList text="No Investments added yet." />
    </Panel>
  )
}
