import React from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { queryCache, useMutation } from 'react-query'
import { approve, reject } from 'api'
import { useSetState } from 'react-use'
import { apiErrors } from 'utils/error'
import classnames from 'classnames'
import { Box, Button, Collapse, Grid, IconButton, TableCell, TableRow, Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import { TooltipTypography, CompanyTypeIcon, ChangeTypeIcon } from 'components'
import { DATE_FORMAT } from 'constants/date'
import { InvestmentDetails, InvestmentSecurityDetails, InvestmentSplitDetails } from 'components/detailsView'
import { RejectModal } from '../RejectModal'
import { mergeInvestmentGroup } from 'utils/changes'
import { useToast } from 'hooks'

const useRowStyles = makeStyles(theme => ({
  button: {
    padding: theme.spacing(0, 1),
    marginLeft: theme.spacing(1),
  },
  approveButton: {
    padding: theme.spacing(0.125, 1),
  },
  rowCollapse: {
    border: 'none',
  },
  menuCell: {
    textAlign: 'right',
  },
}))

export const InvestmentRow = ({ investment }) => {
  const classes = useRowStyles()
  const [rejectChanges] = useMutation(reject)
  const [approveChanges] = useMutation(approve)

  const toastService = useToast()

  const [{ open, showRejectModal }, setState] = useSetState({
    open: false,
    showRejectModal: false,
  })

  const handleRejectClick = () => {
    setState({ showRejectModal: true })
  }

  const onCancel = () => {
    setState({ showRejectModal: false })
  }

  const invalidateApprovals = () => {
    queryCache.invalidateQueries('approvals')
    queryCache.invalidateQueries('countApprovals')
  }

  const onReject = async values => {
    try {
      await rejectChanges(
        { approvalId: investment.id, body: values },
        {
          throwOnError: true,
        }
      )

      invalidateApprovals()
      toastService.showInfoToast(`Changes have been rejected.`)
      onCancel()
    } catch (e) {
      console.log(e)
      if (e.status === 403 && !e.message) {
        e.message = `Forbidden by server`
      }

      toastService.showErrorToast(`Failed to reject changes. ${e.message}`)

      return apiErrors(e)
    }
  }
  const onApprove = async () => {
    try {
      await approveChanges({ approvalId: investment.id }, { throwOnError: true })

      toastService.showSuccessToast(`Changes have been approved.`)
      invalidateApprovals()
    } catch (e) {
      console.log('Approve error', e, e.message)

      if (e.status === 403) {
        e.message = 'Forbidden by server'
        return
      }

      toastService.showErrorToast(`Failed to reject changes. ${e.message}`)

      setState({ showToast: true, toastMessage: `Failed to approve changes: ${e.message}.` })
    }
  }

  const snapshot = investment.resource_snapshot
  const changes = investment.submittions

  const submitter = investment.submitter
  const company = snapshot.company

  const { security, investmentChanges, newInvestment } = mergeInvestmentGroup({ snapshot, changes })

  const detailsRowClass = classnames({ [classes.rowCollapse]: !open })
  const approveButtonClass = classnames(classes.button, classes.approveButton)

  return (
    <>
      <TableRow>
        <TableCell width="5%" padding="none">
          <Grid container alignItems="center">
            <IconButton aria-label="expand row" size="small" onClick={() => setState({ open: !open })}>
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
            </IconButton>
            <ChangeTypeIcon type={investment.group_action} subType={newInvestment?.investment_status} />
          </Grid>
        </TableCell>

        <TableCell width="15%">
          <TooltipTypography variant="body2" maxWidth={125}>
            {company?.name || ''}
          </TooltipTypography>
        </TableCell>

        <TableCell width="15%">
          <Grid container wrap="nowrap">
            <CompanyTypeIcon type={snapshot.investor.investor_type} />
            <Box ml={1}>
              <TooltipTypography variant="body2" maxWidth={125}>
                {snapshot.investor.investor_name}
              </TooltipTypography>
            </Box>
          </Grid>
        </TableCell>

        <TableCell width="15%">
          <TooltipTypography variant="body2" maxWidth={125}>
            {submitter.full_name}
          </TooltipTypography>
        </TableCell>

        <TableCell width="15%">{moment(investmentChanges.created_at).format(DATE_FORMAT)}</TableCell>

        <TableCell className={classes.menuCell}>
          <Button color="primary" variant="outlined" onClick={handleRejectClick} className={classes.button}>
            Reject
          </Button>
          <Button color="primary" variant="contained" onClick={onApprove} className={approveButtonClass}>
            Approve
          </Button>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={6} padding="none" className={detailsRowClass}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {investment.group_action === 'split' ? (
              <InvestmentSplitDetails snapshot={snapshot} changes={changes} />
            ) : (
              <>
                <InvestmentDetails investment={snapshot} changes={investmentChanges?.changes} />
                <InvestmentSecurityDetails security={security} />
              </>
            )}
          </Collapse>
        </TableCell>
      </TableRow>

      <RejectModal
        open={showRejectModal}
        onSubmit={onReject}
        title="Reject Investment"
        onCancel={onCancel}
        description={
          <Typography component="span" variant="body1">
            Investment <strong>{security?.security?.name}</strong> created for{' '}
            <strong>
              {company?.name} / {snapshot?.investor?.investor_name}
            </strong>{' '}
            by <strong>{submitter?.full_name}</strong> will be rejected.
          </Typography>
        }
      />
    </>
  )
}
