import React from 'react'
import { AuthPage } from './AuthPage'
import { Form } from 'react-final-form'
import { Typography, Button, IconButton, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ArrowBack } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { FormTextInput } from 'components'
import { requiredField, isEmail } from 'validators'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { resetPassword as resetPasswordApi } from 'api'
import { apiErrors } from 'utils/error'
import { useToast } from 'hooks'

const useStyles = makeStyles(theme => ({
  form: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(5.5, 12.5),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  header: {
    gridColumn: '1/5',
    marginBottom: theme.spacing(5.5),
  },
  text: {
    gridColumn: '1/5',
    marginBottom: theme.spacing(5),
  },
  input: {
    gridColumn: '1/5',
    marginBottom: theme.spacing(3),
  },
  button: {
    gridColumn: '1/5',
  },
  error: {
    gridColumn: '1/5',
    margin: '15px 0',
  },
}))
export const ForgotPasswordForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const toastService = useToast()

  const [resetPassword, { isLoading, isError, error }] = useMutation(resetPasswordApi, { throwOnError: true })

  const onSubmit = async values => {
    try {
      await resetPassword(values)
      history.push('/auth/login')
      toastService.showSuccessToast('Reset password link sent to provided email.')
    } catch (e) {
      toastService.showErrorToast(`Failed to sent reset link. ${e.message}`)
      console.log(e)
      return apiErrors(e)
    }
  }

  let message = isError && error.message

  return (
    <AuthPage>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, submitting }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container className={classes.header}>
              <Grid item xs={3}>
                <IconButton color="secondary" variant="outlined" onClick={history.goBack}>
                  <ArrowBack />
                </IconButton>
              </Grid>
              <Grid container item xs={8} alignItems="center">
                <Typography variant="h6">Forgot Password?</Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" className={classes.text}>
              Enter the email address associated with your account, and we’ll email you a link to reset your password.
            </Typography>

            <FormTextInput
              className={classes.input}
              label="Email"
              name="email"
              validators={[requiredField, isEmail]}
              required
            />

            {isError && (
              <Alert severity="error" className={classes.error}>
                {message}
              </Alert>
            )}
            <Button
              color="primary"
              disableElevation
              variant="contained"
              className={classes.button}
              disabled={pristine || submitting || isLoading}
              type="submit"
            >
              Send Reset Link
            </Button>
          </form>
        )}
      />
    </AuthPage>
  )
}
