export const INVESTMENT_TYPES = {
  FIG: 'Fig',
  NEW_MODEL: 'New Model',
  NEITHER: 'Neither',
}

export const INVESTMENT_UPLOAD_TYPES = {
  SUBSCRIPTION_DOCUMENT: 'Investment document',
  SHARE_CERTIFICATE: 'Share certificate',
  SCHEME_PAPERWORK: 'Scheme paperwork',
}
