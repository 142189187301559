import React from 'react'
import { Route } from 'react-router-dom'

export const RoleRoute = ({ path, component, role, allowedRoles = [], ...props }) => {
  if (allowedRoles.length > 0) {
    if (!allowedRoles.includes(role)) {
      return null
    }
  }

  return <Route path={path} component={component} {...props} />
}
