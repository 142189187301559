import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Modal } from 'components/Modal'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  actions: {
    paddingTop: 0,
  },
}))

export const ConfirmationDialog = ({
  open,
  title,
  content,
  onAccept,
  onCancel,
  acceptLabel = 'OK',
  cancelLabel = 'CANCEL',
}) => {
  const classes = useStyles()
  return (
    <Modal
      open={open}
      modalTitle={title}
      modalContent={content}
      modalActionClass={classes.actions}
      modalActions={
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button onClick={onCancel} color="primary" variant="outlined">
              {cancelLabel}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onAccept} color="primary" variant="contained" autoFocus>
              {acceptLabel}
            </Button>
          </Grid>
        </Grid>
      }
    />
  )
}
