import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Grid, Typography, Card, CardContent, Box } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { withStyles } from '@material-ui/core/styles'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { sortBy, get } from 'lodash'

import { paddedNumber } from 'utils/numberFormat'

const WhiteTextTypography = withStyles({
  root: {
    color: '#fff',
  },
})(Typography)

const colors = [
  {
    id: 'orange',
    color: '#f78500',
    colorSecondary: '#ffd869',
  },
  {
    id: 'lime',
    color: '#8ac900',
    colorSecondary: '#bee600',
  },
  {
    id: 'blue',
    color: '#0062ef',
    colorSecondary: '#2eb1ff',
  },
  {
    id: 'purple',
    color: '#3e1993',
    colorSecondary: '#8b57ff',
  },
  {
    id: 'pink',
    color: '#e20058',
    colorSecondary: '#ff7c7c',
  },
  {
    id: 'turquoise',
    color: '#0062ef',
    colorSecondary: '#00b2ab',
  },
]

const generateGradient = colorData => ({
  id: colorData.id,
  type: 'linearGradient',
  colors: [
    { offset: 0, color: colorData.color },
    { offset: 100, color: colorData.colorSecondary },
  ],
})

const generateGradientsDefs = colors => colors.map(color => generateGradient(color))
const generateColorFills = colors =>
  colors.map(color => ({
    match: d => d.data.colorId === color.id,
    id: color.id,
  }))
const getColors = colors => colors.map(color => color.color)

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minHeight: '300px',
  },
  chartWrapper: {
    width: '100%',
    minWidth: '600px',
    height: '350px',
    position: 'relative',
    zIndex: 2,
  },
  mainChart: {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    position: 'absolute',
    zIndex: 2,
  },
  nestedChart: {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    position: 'absolute',
    zIndex: 1,
  },
  total: {
    flexGrow: 1,
    textAlign: 'center',
  },
  legend: {
    fontSize: '14px',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderRadius: '10px',
    padding: theme.spacing(4),
    paddingBottom: '5px',
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  verticalAlign: {
    verticalAlign: 'middle',
  },
  tooltip: {
    color: theme.palette.common.white,
    background: theme.palette.common.black,
  },
}))

const CustomTooltip = props => {
  const classes = useStyles()

  return (
    <div className={classes.tooltip}>
      <WhiteTextTypography variant="subtitle1">
        <strong>{props.label}</strong>
      </WhiteTextTypography>
      <WhiteTextTypography variant="body2">
        Gross Invested Capital: <strong>£ {paddedNumber(props.gross_invested_capital)}</strong>
      </WhiteTextTypography>
      <WhiteTextTypography variant="body2">
        Current Value of Holding: <strong>£ {paddedNumber(props.current_value_of_holdings)}</strong>
      </WhiteTextTypography>
    </div>
  )
}

const defaultMargin = { top: 30, right: 160, bottom: 30, left: 160 }

const PieChart = ({ data, enableRadialLabels = true, margin = defaultMargin, innerRadius = 0.7 }) => (
  <ResponsivePie
    data={data}
    colors={getColors(colors)}
    margin={margin}
    innerRadius={innerRadius}
    padAngle={1}
    enableRadialLabels={enableRadialLabels}
    radialLabel="label"
    radialLabelsSkipAngle={10}
    radialLabelsTextXOffset={2}
    radialLabelsTextColor="#333333"
    radialLabelsLinkOffset={0}
    radialLabelsLinkDiagonalLength={16}
    radialLabelsLinkHorizontalLength={4}
    radialLabelsLinkStrokeWidth={enableRadialLabels ? 1 : 0}
    radialLabelsLinkColor={{ from: 'color' }}
    enableSlicesLabels={false}
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor="#333333"
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    defs={generateGradientsDefs(colors)}
    fill={generateColorFills(colors)}
    tooltip={CustomTooltip}
    theme={{
      tooltip: {
        container: {
          background: '#000',
        },
      },
    }}
  />
)

const mergeColorData = (data, colors) =>
  data.map((item, index) => ({
    ...item,
    color: colors[index].color,
    colorId: colors[index].id,
  }))

const parseData = (data = {}, colors, valueProp = 'gross_invested_capital') =>
  mergeColorData(sortBy(get(data, 'shareholdings', []), 'current_value_of_holdings').reverse(), colors).map(item => ({
    ...item,
    total_invested_capital: data.gross_invested_capital,
    total_value_of_holding: data.current_value_of_holdings,
    label: item.company_name,
    id: item.company_name,
    value: item[valueProp],
  }))

export const HoldingReport = ({ data = {} }) => {
  const classes = useStyles()
  const values = parseData(data, colors, 'current_value_of_holdings')
  const nestedValues = parseData(data, colors)

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignContent="center" alignItems="center" justify="center">
        <Grid item container xs={4} justify="center">
          <div className={classes.total}>
            <Card className={classes.card}>
              <CardContent>
                <Box mr="auto">
                  <Typography variant="h6">£ {paddedNumber(data?.total_value_of_holding)}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Current Value of Holding
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignContent="center" alignItems="center" justify="center">
        <Grid item container xs={12} justify="center">
          <div className={classes.chartWrapper}>
            <div className={classes.mainChart}>
              <PieChart data={values} />
            </div>
            <div className={classes.nestedChart}>
              <PieChart
                data={nestedValues}
                enableRadialLabels={false}
                margin={{ top: 80, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.9}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignContent="center" alignItems="center" justify="center">
        <Grid item xs={12}>
          <div className={classes.legend}>
            {values.map(item => (
              <Grid item container xs={12} key={item.label}>
                <Grid item xs={5}>
                  <div style={{ color: item.color, paddingLeft: 24 }}>
                    <FiberManualRecordIcon size={14} className={classes.verticalAlign} />{' '}
                    <span className={classes.verticalAlign}>{item.label}</span>
                  </div>
                </Grid>
                <Grid item xs={7} container justify="center" alignContent="center">
                  <Typography variant="body1" color="textSecondary">
                    £ {paddedNumber(item.value)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
