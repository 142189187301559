import React, { useState } from 'react'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { FormTextInput } from './TextInput'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  icon: {
    padding: 0,
  },
}))

export const FormPasswordInput = ({ ...props }) => {
  let [showPassword, setShowPassword] = useState(false)
  const classes = useStyles()

  const onIconClick = () => setShowPassword(!showPassword)

  const Adornment = (
    <InputAdornment position="end">
      <IconButton tabIndex={-1} onClick={onIconClick} className={classes.icon}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  )

  return <FormTextInput {...props} type={showPassword ? 'text' : 'password'} endAdornment={Adornment} />
}
