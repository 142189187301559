import React from 'react'
import { Fade, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { InvestmentRow } from './InvestmentRow'

const useApprovalsStyles = makeStyles(() => ({
  tableContainer: {
    minHeight: 610,
  },
}))

export const InvestmentApprovals = ({ investments }) => {
  const classes = useApprovalsStyles()

  return (
    <Fade timeout={1000} in>
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Company</TableCell>
              <TableCell>Investor</TableCell>
              <TableCell>Changed by</TableCell>
              <TableCell>Date</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {investments.map(investment => (
              <InvestmentRow key={investment.id} investment={investment} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fade>
  )
}
