import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { If } from 'components'
import { Container } from '@material-ui/core'
import { PanelButton } from './PanelButton'

const useStyles = makeStyles(theme => ({
  panel: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5, 3),
  },
  panelPaper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    borderRadius: '10px',
    border: `1px solid ${theme.palette.grayShades.gray4}`,
  },
  panelHeader: {
    padding: theme.spacing(2.5, 3),
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.grayShades.gray5}`,
  },
  panelTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
}))

export const Panel = ({
  title,
  children,
  headerChildren,
  panelClass = '',
  buttonLabel = '',
  button = null,
  disableGutters = false,
}) => {
  const classes = useStyles()

  return (
    <Container maxWidth="xl" disableGutters={disableGutters}>
      <div className={`${classes.panel} ${panelClass}`}>
        {button && <PanelButton label={buttonLabel} button={button} />}

        <Paper elevation={0} className={classes.panelPaper}>
          <If
            value={title}
            component={
              <div className={classes.panelHeader}>
                <Typography className={classes.panelTitle} variant="body1">
                  {title}
                </Typography>
                {headerChildren}
              </div>
            }
          />

          {children}
        </Paper>
      </div>
    </Container>
  )
}
