export const mergeInvestmentGroup = ({ snapshot, changes }) => {
  let investments = changes.filter(change => change.resource_type === 'Investment')
  let securities = changes.filter(change => change.resource_type === 'InvestmentSecurity')

  let investmentChanges = getSplitItem(investments)
  let newInvestment = investments.find(change => change.id !== investmentChanges.id)?.snapshot
  investmentChanges = { ...investmentChanges, ...snapshot }

  let securityChanges = getSplitItem(securities)
  let security = { ...snapshot.investment_security, ...securityChanges }

  let newSecurity = newInvestment?.investment_security

  return { security, investmentChanges, newInvestment, newSecurity }
}

function getSplitItem(items) {
  if (items.length === 1) {
    return items[0]
  }

  return items.find(change => ['edit', 'delete'].includes(change.action_type.name))
}
