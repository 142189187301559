import { FORM_ERROR } from 'final-form'

export const mapErrors = (errors = []) => {
  const errorsMap = {}

  errors.forEach(({ message, context }) => {
    errorsMap[context.key] = message
  })

  return errorsMap
}

export const apiErrors = e => {
  const errors = {}
  if (e.errors && e.errors.body) {
    e.errors.body.forEach(error => (errors[error.path[0]] = error.message))
  }

  if (e.message) {
    errors[FORM_ERROR] = e.message
  }
  return errors
}
