import React from 'react'
import { Modal, FormPasswordInput } from 'components'
import { minLength, requiredField } from 'validators'
import { Form } from 'react-final-form'
import { Button, FormHelperText, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  modal: {
    width: '480px',
  },
  changePasswordForm: {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
  actions: {
    paddingTop: 0,
  },
}))

export const ChangePassword = ({ open, onSubmit, onCancel, isLoading }) => {
  const classes = useStyles()

  const validate = values => {
    const errors = {}

    if (values.confirm_new_password !== values.new_password) {
      errors.confirm_new_password = 'Must match New Password'
    }

    return errors
  }

  return (
    <Modal
      open={open}
      modalClass={classes.modal}
      modalTitle="Change Password"
      modalContent={
        <Form validate={validate} onSubmit={onSubmit} render={props => <ChangePasswordForm {...props} />} />
      }
      modalActionClass={classes.actions}
      modalActions={
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" type="submit" color="primary" form="change-password" disabled={isLoading}>
              Save
            </Button>
          </Grid>
        </Grid>
      }
      isLoading={isLoading}
    />
  )
}

const ChangePasswordForm = ({ handleSubmit, submitError }) => {
  const classes = useStyles()

  const onSubmit = e => {
    e.preventDefault()
    handleSubmit()
  }

  return (
    <form id="change-password" noValidate autoComplete="off" onSubmit={onSubmit} className={classes.changePasswordForm}>
      <FormPasswordInput label="Old Password" name="old_password" required validators={[requiredField, minLength(6)]} />

      <FormPasswordInput label="New Password" name="new_password" required validators={[requiredField, minLength(6)]} />

      <FormPasswordInput
        label="Confirm New Password"
        name="confirm_new_password"
        required
        validators={[requiredField, minLength(6)]}
      />

      <FormHelperText error={!!submitError}>{submitError}</FormHelperText>
    </form>
  )
}
