import React from 'react'
import { FormCurrencyInput, FormTextInput } from 'components'
import { currency, isUrl, requiredField, isAlphaNumeric, isDropboxUrl } from 'validators'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

export const CompanyDetailsForm = ({ company = {}, classes, onSubmit, handleDirty }) => (
  <Form
    onSubmit={onSubmit}
    mutators={{
      ...arrayMutators,
    }}
    initialValues={{ ...company, add_to_my_companies: company?.isFavorite }}
    render={({ form, handleSubmit, dirty }) => {
      handleDirty(dirty)
      return <CompanyForm classes={classes} form={form} handleSubmit={handleSubmit} company={company} />
    }}
  />
)

const CompanyForm = ({ classes, handleSubmit }) => {
  const handleCompanySubmit = event => {
    event.preventDefault()

    handleSubmit()
  }

  return (
    <form
      className={classes.formClass}
      noValidate
      autoComplete="off"
      id="company-details"
      onSubmit={handleCompanySubmit}
    >
      <FormTextInput label="Company Name" name="name" required validators={[requiredField]} className={classes.name} />

      <FormTextInput
        label="Company Number"
        name="number"
        required
        validators={[requiredField, isAlphaNumeric]}
        className={classes.number}
      />

      <FormTextInput
        label="Registered Address"
        name="registered_address"
        required
        validators={[requiredField]}
        className={classes.registeredAddress}
      />

      <FormCurrencyInput
        label="Share Price at most recent capital raise, £"
        name="most_recent_price"
        required
        validators={[requiredField, currency]}
        className={classes.mostRecentPrice}
      />

      <FormCurrencyInput
        label="Current marketed share price, £"
        name="current_market_price"
        required
        validators={[requiredField, currency]}
        className={classes.currentMarketPrice}
      />

      <FormTextInput
        label="Trading Address"
        name="trading_address"
        required
        validators={[requiredField]}
        className={classes.tradingAddress}
      />

      <FormTextInput
        label="Dropbox"
        name="dropbox_link"
        required
        validators={[requiredField, isUrl, isDropboxUrl]}
        className={classes.dropboxLink}
      />

      <FormTextInput
        label="Website"
        name="website"
        required
        validators={[requiredField, isUrl]}
        className={classes.website}
      />

      <FormTextInput
        label="Companies House link"
        name="company_house_link"
        required
        validators={[requiredField, isUrl]}
        className={classes.companyHouseLink}
      />
    </form>
  )
}
