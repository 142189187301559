import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core//Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { HoldingReport } from './HoldingReport'
import { CapitalReport } from './CapitalReport'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: '100%',
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    bordeRadius: '10px',
    boxShadow: '10px 10px 20px 0 rgba(179, 189, 209, 0.1)',
  },
  title: {
    color: '#000',
  },
}))

export const ReportCharts = ({ data = [] }) => {
  const classes = useStyles()

  const shareholdingsData = data.reduce(
    (acc, item) => ({
      total_net_investment_cost: acc.total_net_investment_cost + item.net_investment_cost,
      total_invested_capital: acc.total_invested_capital + item.gross_invested_capital,
      total_value_of_holding: acc.total_value_of_holding + item.current_value_of_holdings,
      shareholdings: [...acc.shareholdings, item],
    }),
    {
      total_net_investment_cost: 0,
      total_invested_capital: 0,
      total_value_of_holding: 0,
      shareholdings: [],
    }
  )

  if (!data.length) {
    return <div className={classes.root} />
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Grid item container xs={12} justify="center">
              <Typography className={classes.title} variant="h6">
                Current Value of Portfolio
              </Typography>
            </Grid>
            <HoldingReport data={shareholdingsData} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Grid item container xs={12} justify="center">
              <Typography className={classes.title} variant="h6">
                Portfolio Change in Value
              </Typography>
            </Grid>
            <CapitalReport data={shareholdingsData} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
