import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import { CompanySecuritiesDetails } from './CompanySecuritiesDetails'

const useStyles = makeStyles(theme => ({
  details: {
    borderBottom: `1px solid ${theme.palette.grayShades.gray4}`,
    '&:last-child': {
      borderBottom: 'none',
    },
    padding: theme.spacing(3, 5),
  },
}))

export const CompanySecurityAddedView = ({ securities }) => {
  const classes = useStyles()

  return (
    <Paper elevation={0} className={classes.details}>
      <CompanySecuritiesDetails securities={securities} />
    </Paper>
  )
}
