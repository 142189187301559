import React, { useState, useEffect } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import InputLabel from '@material-ui/core/InputLabel'
import { Field, useField } from 'react-final-form'
import { requiredField, composeValidators } from 'validators'
import { RadioGroup as MuiRadioGroup } from '@material-ui/core'

export const RadioGroup = ({
  label,
  required,
  options,
  name,
  onChange,
  labelProps,
  initialValue = '',
  groupClass = '',
  labelClass = '',
  optionsClass = '',
}) => {
  const [error, setError] = useState(initialValue)
  const [helperText, setHelperText] = useState('')

  const RadioField = props => {
    const { input, meta } = props

    useEffect(() => {
      if (meta.touched && meta.error) {
        setError(true)
        setHelperText(meta.error)
      } else {
        setError(false)
        setHelperText('')
      }
    }, [meta])

    const handleChange = event => {
      input.onChange(event)
      onChange && onChange(event)
    }

    return (
      <Radio
        size="small"
        color="primary"
        type="radio"
        checked={input.checked}
        onChange={handleChange}
        name={input.name}
      />
    )
  }

  return (
    <div className={groupClass}>
      <InputLabel error={!!error} shrink={true} required={required} className={labelClass} {...labelProps}>
        {label}
      </InputLabel>
      <div className={optionsClass}>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            control={
              <Field
                parse={() => option.value}
                type="radio"
                name={name}
                component={RadioField}
                data={null}
                allowNull={true}
                validate={requiredField}
                value={option.value}
              />
            }
            label={option.label}
            name={name}
          />
        ))}
      </div>
      <FormHelperText error={!!error}>{helperText}</FormHelperText>
    </div>
  )
}

export const FormRadioGroup = ({
  label,
  required,
  options,
  name,
  labelProps,
  validators = [],
  groupClass = '',
  labelClass = '',
  optionsClass = '',
}) => {
  // This top level field is for validation only
  const { meta } = useField(name, {
    type: 'radio',
    validate: composeValidators(...validators),
  })

  const showError = (meta.error || meta.submitError) && (meta.touched || meta.modified)

  const props = {
    error: !!showError,
    helperText: showError ? meta.error || meta.submitError : '',
  }

  const parseValue = value => {
    if (value === 'true') {
      return true
    }

    if (value === 'false') {
      return false
    }

    return value
  }

  return (
    <div className={groupClass}>
      <InputLabel shrink required={required} error={props.error} name={name} className={labelClass} {...labelProps}>
        {label}
      </InputLabel>
      <MuiRadioGroup row className={optionsClass}>
        {options.map(option => (
          <FormControlLabel
            key={option.label}
            label={option.label}
            control={
              <Field
                name={name}
                component={RadioWrapper}
                size="small"
                parse={parseValue}
                color="primary"
                type="radio"
                value={option.value}
              />
            }
          />
        ))}
      </MuiRadioGroup>
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </div>
  )
}

const RadioWrapper = ({ input: { checked, value, name, onChange, ...restInput }, meta, ...rest }) => (
  <Radio {...rest} name={name} inputProps={restInput} onChange={onChange} checked={checked} value={value} />
)
