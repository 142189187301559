import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { TextInput } from './TextInput'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useField } from 'react-final-form'
import { composeValidators } from 'validators/composeValidators'

const useStyles = makeStyles(() => ({
  select: {
    backgroundColor: 'transparent',
  },
}))

export const Dropdown = ({
  value,
  onChange,
  error,
  helperText,
  handleChange,
  options,
  className,
  label,
  disabled,
  ...rest
}) => {
  const classes = useStyles()

  const _handleChange = event => {
    onChange(event)
    handleChange && handleChange(event)
  }

  return (
    <TextInput
      {...rest}
      select
      className={className}
      label={label}
      value={value}
      error={error}
      helperText={helperText}
      onChange={_handleChange}
      disabled={disabled}
    >
      {options.map(option => (
        <MenuItem classes={{ root: classes.select }} key={option.name} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </TextInput>
  )
}

export const FormDropdown = ({
  // RFF props
  name,
  validate,
  // MaterialUI props
  required = false,
  helperText = '',
  validators = [],
  parse,
  type,
  onChange = e => e,
  ...rest
}) => {
  const { input, meta } = useField(name, { validate: composeValidators(...validators), parse, type })
  const showError = (meta.error || meta.submitError) && (meta.touched || meta.modified)

  const componentProps = {
    ...rest,
    ...input,
    required,
    error: showError,
    helperText: showError ? meta.error || meta.submitError : helperText,
    onChange: e => {
      input.onChange(e)
      onChange(e)
    },
  }

  return <Dropdown {...componentProps} />
}
