import { createMuiTheme } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 960,
      lg: 1440,
      xl: 2560,
    },
  },
  typography: {
    fontFamily: ["'DMSans'", 'sans-serif'],
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#8AC900',
    },
    error: {
      main: '#D11665',
    },
    green: {
      main: '#8AC900',
      hover: '#54b500',
      focus: '#4ba100',
    },
    grayShades: {
      gray1: '#59606B',
      gray2: '#8A909E',
      gray3: '#B4BDCB',
      gray4: '#DDE2E9',
      gray5: '#F3F5F7',
      gray6: '#FBFBFB',
    },
    common: {
      white: '#FFFFFF',
      wave: '#00AFAA',
      yellow: '#FCD21F',
      orange: '#EA8A2D',
      pink: '#D11665',
      violet: '#553A82',
      blue: '#0065E6',
      blueGray: '#89909f',
      black: '#000000',
      dark: '#1a1f26',
      pattensBlue: '#dce4ec',
      slateGray: '#58606c',
      cloudyBlue: '#B2BDCC',
    },
    background: {
      default: '#FFFFFF',
      lightGray: 'rgba(240, 242, 245, 0.2)',
    },
  },
  spacing: 8,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
  },

  customShadows: {
    shadow1: '10px 10px 20px 0 rgba(179, 189, 209, 0.1)',
    shadow2: '0 5px 20px 0 rgba(205, 210, 226, 0.1)',
    shadow3: '10px 10px 0px 0 rgba(138,201,0, 0.3)',
  },
})

theme.overrides = {
  ...theme.overrides,
  MuiTypography: {
    body1: {
      fontSize: '0.75rem',
      '&.MuiTypography-colorTextSecondary': {
        fontWeight: 'bold',
        color: theme.palette.common.dark,
      },
    },
    body2: {
      fontSize: '0.75rem',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 'bold',
      '&.MuiTypography-colorTextSecondary': {
        color: theme.palette.common.dark,
      },
    },
    h6: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: 'normal',
    },
    subtitle1: {
      fontSize: '0.75rem',
      '&.MuiTypography-colorTextSecondary': {
        color: theme.palette.common.blueGray,
      },
    },
    subtitle2: {
      '&.MuiTypography-colorTextSecondary': {
        color: theme.palette.secondary.main,
        '& a': {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  MuiTextField: {
    root: {
      '& .MuiInputBase-root': {
        marginTop: theme.spacing(3),
      },
      minHeight: theme.spacing(10.5),
    },
  },
  MuiInputLabel: {
    root: {
      whiteSpace: 'nowrap',
      color: theme.palette.grayShades.gray2,
      '&$focused': {
        color: theme.palette.grayShades.gray1,
      },
    },
  },
  MuiTabs: {
    root: {
      minHeight: theme.spacing(4.5),
    },
    indicator: {
      display: 'none',
    },
  },
  MuiTab: {
    root: {
      minHeight: theme.spacing(4.5),
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.grayShades.gray3}`,
      color: theme.palette.common.blueGray,
      '&:first-child': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '&:last-child': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        marginLeft: '-1px',
      },
      '&:not(:first-child):not(:last-child)': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        marginLeft: '-1px',
      },
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      color: theme.palette.secondary.main,
    },
  },
  MuiButton: {
    root: {
      textTransform: 'none',
      '&:disabled': {
        opacity: 0.5,
      },
    },
    containedPrimary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.green.hover,
      },
      '&$disabled': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    outlinedPrimary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.green.main,
      border: '1px solid',
      borderColor: theme.palette.green.main,
      '&:hover': {
        color: theme.palette.green.hover,
        borderColor: theme.palette.green.hover,
      },
      '&:focus': {
        color: theme.palette.green.focus,
        borderColor: theme.palette.green.focus,
        backgroundColor: theme.palette.primary.main,
      },
    },
    outlinedSecondary: {
      borderRadius: '14px',
      borderColor: theme.palette.grayShades.gray4,
      color: theme.palette.grayShades.gray2,
      fontWeight: 'normal',
      '&:hover': {
        backgroundColor: theme.palette.grayShades.gray4,
        borderColor: theme.palette.grayShades.gray4,
        color: theme.palette.grayShades.gray1,
      },
      '&:focus': {
        backgroundColor: theme.palette.grayShades.gray5,
        borderColor: theme.palette.grayShades.gray3,
      },
    },
    outlined: {
      fontWeight: 'bold',
    },
    text: {
      fontWeight: 'bold',
    },
    contained: {
      fontWeight: 'bold',
      boxShadow: 'none',
    },
  },
  MuiIconButton: {
    colorPrimary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.green.hover,
      },
      '&$disabled': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
      },
    },
    colorSecondary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.grayShades.gray1,
      border: `1px solid ${theme.palette.grayShades.gray4}`,
      '&:hover': {
        backgroundColor: theme.palette.grayShades.gray4,
      },
    },
  },
  MuiCheckbox: {
    root: {
      background: 'transparent',
      border: 'none',
      '&:hover': {
        background: 'transparent',
      },
    },
    colorSecondary: {
      color: theme.palette.grayShades.gray3,
    },
  },
  MuiDialog: {
    paper: {
      borderRadius: '10px',
    },
  },
  MuiDialogTitle: {
    root: {
      backgroundColor: fade(theme.palette.grayShades.gray5, 0.5),
      padding: theme.spacing(3),
      borderBottom: `1px solid ${theme.palette.common.pattensBlue}`,
    },
  },
  MuiDialogContent: {
    root: {
      padding: theme.spacing(5),
    },
  },
  MuiDialogActions: {
    root: {
      padding: theme.spacing(5),
    },
  },
  MuiRadio: {
    colorPrimary: {
      color: theme.palette.grayShades.gray3,
      backgroundColor: 'transparent',
      '&.Mui-checked': {
        color: theme.palette.secondary.main,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  NewModelLink: {
    color: theme.palette.secondary.main,
  },
  MuiAutocomplete: {
    root: {
      '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
        paddingBottom: 0,
        '& .MuiAutocomplete-input:first-child': {
          padding: theme.spacing(1, 0),
        },
      },
    },
    listbox: {
      maxHeight: '300px',
    },
  },
  MuiInput: {
    focused: {
      borderColor: theme.palette.secondary.main,
    },
    root: {
      borderRadius: '4px',
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.grayShades.gray4}`,
      padding: theme.spacing(0, 1.5),
    },
    input: {
      padding: theme.spacing(1, 0),
    },
  },
  MuiList: {
    root: {
      borderRadius: '10px',
    },
  },
  MuiListSubheader: {
    root: {
      padding: theme.spacing(3),
    },
  },
  MuiListItem: {
    secondaryAction: {
      padding: theme.spacing(1.5, 3),
      border: `1px solid ${fade(theme.palette.grayShades.gray4, 0.5)}`,
      backgroundColor: fade(theme.palette.grayShades.gray5, 0.5),
    },
    button: {
      '&:hover': {
        backgroundColor: fade(theme.palette.secondary.main, 0.2),
      },
      '&.Mui-selected': {
        backgroundColor: fade(theme.palette.secondary.main, 0.3),
        color: theme.palette.common.dark,
        '&:hover': {
          backgroundColor: fade(theme.palette.secondary.main, 0.3),
        },
      },
    },
  },
  MuiListItemSecondaryAction: {
    root: {
      paddingRight: theme.spacing(3),
    },
  },
  MuiTableContainer: {
    root: {
      borderRadius: '10px',
      backgroundColor: theme.palette.primary.main,
      boxShadow: theme.customShadows.shadow1,
    },
  },
  MuiTableRow: {
    root: {
      backgroundColor: fade(theme.palette.grayShades.gray5, 0.5),
      borderColor: fade(theme.palette.grayShades.gray4, 0.5),
      color: theme.palette.common.dark,
    },
    footer: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  MuiTableHead: {
    root: {
      borderBottom: `1px solid ${theme.palette.common.pattensBlue}`,
      '& .MuiTableRow-root': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  MuiTableCell: {
    root: {
      fontSize: '0.75rem',
      padding: theme.spacing(1.5),
    },
    head: {
      fontWeight: 'bold',
      color: theme.palette.common.blueGray,
      lineHeight: '1rem',
    },
    footer: {
      fontWeight: 'bold',
      color: theme.palette.common.dark,
    },
  },
  MuiTableFooter: {
    root: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  MuiTooltip: {
    tooltip: {
      color: theme.palette.primary.main,
      backgroundColor: '#58606c',
      padding: theme.spacing(2),
    },
  },
  MuiChip: {
    colorPrimary: {
      color: theme.palette.common.dark,
      backgroundColor: fade(theme.palette.secondary.main, 0.2),
    },
    deleteIconColorPrimary: {
      color: theme.palette.common.gray4,
    },
  },

  MuiSwitch: {
    root: {
      height: '16px',
      width: '35px',
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(19px)',
        color: theme.palette.primary.main,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
    },
    thumb: {
      width: 14,
      height: 14,
      color: theme.palette.primary.main,
    },
    track: {
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.grayShades.gray3,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
  },
  MuiSelect: {
    select: {
      '&:focus': { backgroundColor: 'inherit' },
    },
  },
  MuiAccordion: {
    rounded: {
      borderRadius: '10px',
    },
    root: {
      border: `1px solid ${theme.palette.grayShades.gray4}`,
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
    },
  },
  MuiAccordionSummary: {
    content: {
      '&.Mui-expanded': {
        margin: theme.spacing(1, 0),
      },
    },
  },
  MuiPickersYear: {
    root: {
      color: theme.palette.common.dark,
    },
  },
}
