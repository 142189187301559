import isNumericValidation from 'validator/es/lib/isNumeric'

const defaultoOptions = {
  no_symbols: true,
}
export const isNumeric = (value, options = defaultoOptions) => {
  if (typeof value === 'number') {
    return undefined
  }

  return isNumericValidation(value, options) ? undefined : 'Only numbers are allowed'
}
