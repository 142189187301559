import React from 'react'

import Container from '@material-ui/core/Container'
import { AppRouter } from 'AppRouter'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    padding: 0,
  },
}))

function App() {
  const classes = useStyles()

  return (
    <Container maxWidth={false} className={classes.container}>
      <AppRouter />
    </Container>
  )
}

export default App
