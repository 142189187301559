import React from 'react'
import { TextInput } from './TextInput'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Search from '@material-ui/icons/Search'

const useStyles = makeStyles(theme => ({
  searchBar: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4, 6),
    boxShadow: '0 5px 20px 0 rgba(205, 210, 226, 0.1)',
    marginBottom: theme.spacing(5),
  },
  search: {
    '& .MuiInput-formControl': {
      marginTop: '0',
    },
    '& .MuiInput-root': {
      color: theme.palette.grayShades.gray2,
      border: `2px solid ${theme.palette.grayShades.gray4}`,
    },
    marginLeft: theme.spacing(5),
    width: '400px',
    minHeight: theme.spacing(4.5),
  },
}))

export const SearchBar = ({ header, children, onSearch }) => {
  const classes = useStyles()

  const handleSearch = e => {
    onSearch(e.target.value)
  }

  return (
    <Grid container alignItems="center" direction="row" className={classes.searchBar}>
      {header}
      <Grid item>
        <TextInput
          placeholder="Search"
          startAdornment={<Search />}
          className={classes.search}
          onChange={handleSearch}
        />
      </Grid>
      {children}
    </Grid>
  )
}
