export const mergeCompanyGroup = ({ snapshot, changes }) => {
  let companyChanges = changes.find(change => change.resource_type === 'Company')

  const existingSecurities = snapshot.security

  let companySecurities = changes
    .filter(change => change.resource_type === 'Security')
    .map(s => s.new_data)
    .map(security => {
      if (security.converts_to) {
        let convertToSecurity = existingSecurities.find(s => s.id === security.converts_to)

        if (convertToSecurity) {
          security.converts_to_security = convertToSecurity
        }
      }

      return security
    })

  companyChanges = { ...companyChanges, ...snapshot }

  return { companyChanges, companySecurities }
}
