export const companyFields = [
  'name',
  'number',
  'registered_address',
  'most_recent_price',
  'current_market_price',
  'trading_address',
  'dropbox_link',
  'website',
  'company_house_link',
]
