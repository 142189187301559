import React from 'react'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  toast: {
    minWidth: '200px',
    borderRadius: '10px',
  },
}))

export const Toast = ({ open, severity, message, variant = 'filled', closeToast }) => {
  const classes = useStyles()

  return (
    <Snackbar
      className={classes.toast}
      open={open}
      autoHideDuration={6000}
      onClose={closeToast}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert severity={severity} variant={variant} onClose={closeToast} className={classes.toast}>
        {message}
      </Alert>
    </Snackbar>
  )
}
