import React from 'react'
import { ReactQueryConfigProvider as LibReactQueryConfigProvider } from 'react-query'

if (process.env.NODE_ENV === 'development') {
  LibReactQueryConfigProvider.displayName = 'LibReactQueryConfigProvider'
}

const queryConfig = {
  queries: {
    retry: 2,
    cacheTime: 10 * 1000,
    refetchAllOnWindowFocus: false,
    refetchOnWindowFocus: false,
  },
}

export const ReactQueryConfigProvider = ({ children }) => (
  <LibReactQueryConfigProvider config={queryConfig}>{children}</LibReactQueryConfigProvider>
)
