const regex = /^\d+(\.\d{1,2}){0,1}$/g

export const currency = value => {
  if (typeof value === 'number') {
    value = value.toString(10)
  }

  if (value) {
    const match = value.match(regex)

    if (match === null) {
      return 'Invalid currency format'
    }
  }
  return undefined
}
