import React from 'react'
import { SecurityVoting } from './SecurityVoting'
import { SecurityRank } from './SecurityRank'
import { FormCurrencyInput } from 'components'
import { currency, requiredField } from 'validators'

export const AddEquity = () => (
  <>
    <FormCurrencyInput
      label="Preferential upside ratio"
      hintText="Any preferential upside return as a ratio to invested capital."
      name="upside_ratio"
      validators={[requiredField, currency]}
      required
    />

    <FormCurrencyInput
      label="Preferential downside ratio"
      hintText="Any preferential or liquidation  return as a ratio to invested capital."
      name="downside_ratio"
      validators={[requiredField, currency]}
      required
    />

    <SecurityVoting />
    <SecurityRank />
  </>
)
