import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { CompanyCreateForm, CompanySecurityList } from 'components/company'

export const CompanyCreate = () => {
  const [securityList, setSecurityList] = useState([])

  const addSecurity = values => setSecurityList([...securityList, values])

  return (
    <div>
      <Helmet>
        <title>New Model VC Dashboard - Create Company</title>
      </Helmet>
      <CompanyCreateForm securityList={securityList} />

      <CompanySecurityList securityList={securityList} addSecurity={addSecurity} />
    </div>
  )
}
