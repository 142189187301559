export const splitAttachments = (attachments = []) => {
  const subscription_document = []
  const scheme_paperwork = []
  const share_certificate = []

  for (let attachment of attachments) {
    if (attachment.document_type === 'SHARE_CERTIFICATE') {
      share_certificate.push(attachment)
    } else if (attachment.document_type === 'SUBSCRIPTION_DOCUMENT') {
      subscription_document.push(attachment)
    } else if (attachment.document_type === 'SCHEME_PAPERWORK') {
      scheme_paperwork.push(attachment)
    }
  }

  return { share_certificate, subscription_document, scheme_paperwork }
}
