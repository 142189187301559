import React from 'react'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { TextTooltip } from './TextTooltip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  hintIcon: {
    float: 'right',
    marginLeft: '5px',
    fontSize: '1rem',
  },
}))

export const HintTooltip = ({ text }) => {
  const classes = useStyles()

  return (
    <TextTooltip arrow title={text}>
      <InfoOutlinedIcon fontSize="small" className={classes.hintIcon} />
    </TextTooltip>
  )
}
