import { API } from './ApiClient'

const transformInvestor = investor => ({
  ...investor,
  introducer: investor.introducer.introducer_name,
  investor_affiliations: investor.investor_affiliations.map(affiliation => affiliation.affiliation_name),
})

const createInvestor = body => API.post('/investor', { body })
const getAllAffiliations = () => API.post('/investor/affiliation', { body: { affiliation_name: '' } })
const getAffiliations = body => API.post('/investor/affiliation', { body })
const getIntroducers = body =>
  API.post('/investor/introducer', { body }).then(introducers =>
    introducers.map(introducer => introducer.introducer_name)
  )
const updateInvestor = body => API.put(`/investor/${body.id}`, { body })
const getInvestors = () => API.get('/investor').then(investors => investors.map(transformInvestor))

const getInvestor = (key, id) => API.get(`/investor/${id}`).then(investor => transformInvestor(investor))
const getInvestorInvestments = (key, id) => API.get(`/investor/${id}/investments`)
const getInvestorDebts = (key, investorId) => API.get(`/investor/${investorId}/investments/debt-table`)
const getPortfolioShareholdings = (key, investorId) =>
  API.get(`/investor/${investorId}/investments/portfolio-shareholdings`)
const getInvestorCompanies = (key, investorId) => API.get(`/investor/${investorId}/companies`)
const getInvestorChangeLog = (key, id) => API.get(`/investor/${id}/change_log`)
const checkInvestorName = body => API.post('/investor/name', { body })

export {
  createInvestor,
  getInvestors,
  getAllAffiliations,
  getAffiliations,
  updateInvestor,
  getIntroducers,
  getInvestor,
  getInvestorInvestments,
  getInvestorDebts,
  getPortfolioShareholdings,
  getInvestorCompanies,
  getInvestorChangeLog,
  checkInvestorName,
}
