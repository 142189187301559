export const nameToLabelMapping = {
  investment_status: 'Status',
  investment_date: 'Investment date',
  cash_received: 'Cash received',
  fees_due: 'Fees due',
  investment_type: 'Investment type',
  subscription_document: 'Investment document',
  scheme_paperwork: 'Scheme paperwork',
  share_certificate: 'Share certificate',
  applicable_schemas: 'Applicable investment schemes',
  requested_schemas: 'Requested investment schemes',
  investor: 'Beneficial investor',
  realisation_date: 'Realisation date',

  // Synthetic fields added on UI side
  legal_investor: 'Legal investor',
  managed_by: 'Managed by',
  asset_name: 'Asset name',
}
