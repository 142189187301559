import React, { useState } from 'react'
import MomentUtils from '@date-io/moment'
import InputAdornment from '@material-ui/core/InputAdornment'
import DateRange from '@material-ui/icons/DateRange'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { TextInput } from './TextInput'
import { DATE_FORMAT } from 'constants/date'
import { useField } from 'react-final-form'
import { composeValidators } from 'validators/composeValidators'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const FormDateInput = ({
  name,
  label,
  required,
  validators = [],
  format = DATE_FORMAT,
  variant = 'inline',
  helperText = '',
  maxDate = undefined,
  minDate = undefined,
  ...rest
}) => {
  const { input, meta } = useField(name, {
    validate: composeValidators(...validators),
    parse: value => value && moment.utc(value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf(),
    format: value => value && moment.utc(value).format(format),
  })
  const showError = (meta.error || meta.submitError) && (meta.touched || meta.modified)

  const props = {
    ...rest,
    ...input,
    error: showError,
    helperText: showError ? meta.error || meta.submitError : helperText,
  }

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleInputClick = event => {
    setAnchorEl(event.target)
    openPicker()
  }

  const openPicker = () => setOpen(true)

  const popoverProps = {
    anchorEl,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  }

  return (
    <div className={classes.container}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <TextInput
          {...props}
          label={label}
          required={required}
          onClick={handleInputClick}
          onKeyDown={e => e.preventDefault()}
          endAdornment={
            <InputAdornment position="end">
              <DateRange />
            </InputAdornment>
          }
        />

        <KeyboardDatePicker
          autoOk
          variant={variant}
          margin="normal"
          open={open}
          onChange={input.onChange}
          onClose={() => setOpen(false)}
          PopoverProps={popoverProps}
          TextFieldComponent={() => null}
          maxDate={maxDate}
          minDate={minDate}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}
