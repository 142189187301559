import React from 'react'
import { Panel } from 'components'
import { Grid, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { Link } from 'react-router-dom'
import Fade from '@material-ui/core/Fade'

const useStyles = makeStyles(() => ({
  companies: {
    padding: 0,
  },
}))

export const InvestorCompanies = ({ companies = [] }) => {
  const classes = useStyles()

  return (
    <Panel
      disableGutters
      panelClass={classes.companies}
      title={
        <Grid container spacing={1} component="span">
          <Grid item component="span">
            <Typography variant="body1" color="textSecondary" component="span">
              Company
            </Typography>
          </Grid>
          <Grid item component="span">
            <Typography variant="body2" color="textSecondary" component="span">
              ({companies?.length})
            </Typography>
          </Grid>
        </Grid>
      }
    >
      <Fade timeout={1000} in>
        <Box px={3} py={5} className={classes.links}>
          <Grid container spacing={7}>
            {companies.map(company => (
              <Grid item key={company.id}>
                <Link to={`/companies/details/${company.id}`}>{company.name}</Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Fade>
    </Panel>
  )
}
