import React, { useState } from 'react'
import MUIAutocomplete from '@material-ui/lab/Autocomplete'
import { noop } from 'lodash'
import { makeStyles } from '@material-ui/core'

import { TextInput, HintTooltip } from 'components'

const styles = () => ({
  root: {
    width: '100%',
  },
  popupIndicatorOpen: {
    transform: 'rotate(0)',
  },
})

const useStyles = makeStyles(styles, { name: 'Autocomplete' })
const defaultAutocompleteProps = {
  freeSolo: true,
  selectOnFocus: true,
  handleHomeEndKeys: true,
  openOnFocus: true,
  multiple: true,
}

const Autocomplete = ({
  classes: _classes,
  name,
  label,
  placeholder,
  validators = [],
  helperText,
  value,
  defaultValue,
  disabled,
  options,
  error,
  min,
  loading,
  required,
  handleChange,
  handleInputChange,
  getOptionLabel,
  hintText = '',
  AutocompleteProps = defaultAutocompleteProps,
  filterOptions,
}) => {
  const classes = useStyles({ classes: _classes })
  const [open, setOpen] = useState(false)

  const onInputChange = (e, value = '', type) => {
    if (value.length >= min) {
      handleInputChange(value, type, e)
    }
  }

  const inputLabel = () => {
    if (!hintText) {
      return label
    }

    return (
      <>
        {label}
        <HintTooltip text={hintText} />
      </>
    )
  }

  return (
    <div className={classes.root}>
      <MUIAutocomplete
        {...AutocompleteProps}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        onChange={handleChange}
        onInputChange={onInputChange}
        loading={loading}
        options={options}
        getOptionLabel={getOptionLabel}
        defaultValue={defaultValue}
        value={value}
        ChipProps={{
          variant: 'default',
          color: 'primary',
        }}
        filterOptions={filterOptions}
        renderInput={params => (
          <TextInput
            {...params}
            placeholder={placeholder}
            required={required}
            name={name}
            label={inputLabel()}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            validators={validators}
            error={error}
            helperText={helperText}
            disabled={disabled}
          />
        )}
      />
    </div>
  )
}

Autocomplete.defaultProps = {
  defaultValue: [],
  value: [],
  options: [],
  min: 1,
  helperText: '',
  placeholder: '',
  handleInputChange: noop,
  handleChange: noop,
  getOptionLabel: option => option,
}

export default Autocomplete
