export const normalizeCompanyChanges = (changes = []) => {
  const changesMap = {}

  for (let c of changes) {
    const {
      path: [field],
    } = c
    changesMap[field] = c
  }

  return changesMap
}
