import { isEqual } from 'lodash'

export const normalizeInvestorChanges = (changes = []) => {
  const changesMap = {}

  if (changes === null) {
    return changesMap
  }

  for (let c of changes) {
    const {
      path: [field],
    } = c

    if (field === 'introducer') {
      changesMap[field] = convertIntroducer(c)
    } else if (field === 'investor_affiliations') {
      const flatAffiliations = flattenAffiliations(c)

      if (!isEqual(flatAffiliations.rhs, flatAffiliations.lhs)) {
        changesMap[field] = flatAffiliations
      }
    } else {
      changesMap[field] = c
    }
  }

  return changesMap
}

function convertIntroducer(changes = {}) {
  const lhs = changes.lhs?.introducer_name
  const rhs = changes.rhs?.introducer_name

  return { ...changes, lhs, rhs }
}

function flattenAffiliations(changes = {}) {
  const lhs = (changes.lhs || []).map(a => a.affiliation_name)
  const rhs = (changes.rhs || []).map(a => a.affiliation_name)

  return { ...changes, lhs, rhs }
}
