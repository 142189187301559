import React from 'react'
import { Grid } from '@material-ui/core'
import { TablePagination as MuiTablePagination } from '@material-ui/core'

export const TablePagination = ({ count, page, onChangePage, rowsPerPage }) => (
  <MuiTablePagination
    component={({ children }) => (
      <Grid container justify="center">
        {children}
      </Grid>
    )}
    count={count}
    rowsPerPage={rowsPerPage}
    page={page}
    rowsPerPageOptions={[rowsPerPage]}
    onChangePage={onChangePage}
    labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
  />
)
