import React, { useState } from 'react'
import { IconButton, TableCell, TableRow } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

const useStyles = makeStyles(() => ({
  rowCollapse: {
    border: 'none',
  },
  button: {
    padding: 0,
  },
}))

export const ChangeLogRow = ({ description, changedBy, date, detailsView, approvedBy, reviewedAt }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const detailsRowClass = classnames({ [classes.rowCollapse]: !open })
  const toggle = () => setOpen(!open)

  return (
    <>
      <TableRow>
        <TableCell width="1%">
          <IconButton aria-label="expand row" onClick={toggle} size="small" className={classes.button}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell width="20%">{description}</TableCell>
        <TableCell width="15%">{changedBy}</TableCell>
        <TableCell width="10%">{date}</TableCell>
        <TableCell width="15%">{approvedBy}</TableCell>
        <TableCell width="10%">{reviewedAt}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={6} padding="none" className={detailsRowClass}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {detailsView}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
