import React from 'react'
import { Helmet } from 'react-helmet'

import { SignupSuccess as SignupSuccessComponent } from 'components/auth'

export const SignupSuccess = () => (
  <>
    <Helmet>
      <title>New Model VC Dashboard - Sign Up</title>
    </Helmet>
    <SignupSuccessComponent />
  </>
)
