export const SEQURITY_LABELS = {
  name: 'Name',
  type: 'Type',
  voting: 'Voting',
  rank: 'Rank',
  converts_to_security: 'Converts to',
  upside_ratio: 'Preferential upside ratio',
  downside_ratio: 'Preferential downside ratio',
  preferential_rights: 'Preferential rights',
  interest_rate: 'Interest rate',
  is_compounding: 'Straight or Compounding',
  compounding_frequency: 'Compounding Frequency',
  frequency_of_payments: 'Frequency of payments',
  final_repayment_date: 'Final Repayment Date',
  security: 'Security',
  additional_notes: 'Additional notes',
  discount: 'Discount',
  max_share_price: 'Valuation cap',
  conversion_events: 'Conversion events',
}
