import React from 'react'
import { TextInput, FormTextInput } from './TextInput'

const handleKeyDown = e => e.key === ' ' && e.preventDefault()

export const CurrencyInput = ({ label, required, inputTextAlignment, ...props }) => (
  <TextInput label={label} required={required} inputTextAlignment="right" {...props} onKeyDown={handleKeyDown} />
)

export const FormCurrencyInput = ({ label, required, inputTextAlignment, ...props }) => (
  <FormTextInput label={label} required={required} inputTextAlignment="right" {...props} onKeyDown={handleKeyDown} />
)
