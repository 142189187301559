export const nameToLabelMapping = {
  investor_name: 'Investor name',
  introducer: 'Introducer',
  address: 'Address',
  email: 'Email',
  phone_number: 'phone',
  investor_type: 'Primary affiliation',
  investor_affiliations: 'Affiliations',
  bio: 'Bio',
}
