import React, { useState } from 'react'
import { useMutation } from 'react-query'
import Grid from '@material-ui/core/Grid'

import { getAffiliations, checkInvestorName } from 'api'
import { isEmail, requiredField } from 'validators'
import { ChipList, FormDropdown, FormTextArea, FormTextInput } from 'components'
import { Form, useField } from 'react-final-form'
import { InvestorIntroducer } from './InvestorIntroducer'
import { INVESTOR_TYPE } from 'constants/investor-type'

// TODO: make async investor name check as async validation
// instead of current implementation
// tried to implement but there was some issue when using debounce

// const getIsInvestorNameAvailable = async name => {
//   if (name && name.length > 2) {
//     const { nameExists } = await checkInvestorName({ investor_name: name })
//     return nameExists
//   } else {
//     return false
//   }
// }

// const isInvestorNameAvailable = debounce(async name => {
//   const isNameExists = await getIsInvestorNameAvailable(name)
//   if (isNameExists) {
//     return 'Investor already exists'
//   }
// }, 300)

export const InvestorForm = ({ onSubmit, investor, handleDirty, className = '', classes = {} }) => (
  <Form onSubmit={onSubmit} initialValues={investor}>
    {props => {
      handleDirty && handleDirty(props.dirty)
      return (
        <FormComponent
          handleSubmit={props.handleSubmit}
          investor={investor}
          className={className}
          classes={classes}
          form={props.form}
        />
      )
    }}
  </Form>
)

const FormComponent = ({ handleSubmit, form, investor, className, classes }) => {
  const { input } = useField('affiliations', { initialValue: investor.investor_affiliations })
  const [affiliations, setAffiliations] = useState((investor && investor.investor_affiliations) || [])
  const [loadAffiliations, { data: affiliationsData = [] }] = useMutation(getAffiliations)
  const [checkName] = useMutation(checkInvestorName)
  const [userExistsError, setUserExistsError] = useState('')
  const primaryAffiliationOptions = Object.values(INVESTOR_TYPE).map(value => ({ name: value, value }))

  const handleInvestorSubmit = event => {
    event.preventDefault()
    form.change('id', investor.id)
    handleSubmit()
  }

  const handleAffiliationsChange = affiliations => {
    input.onChange(affiliations)

    setAffiliations(affiliations)
  }
  const handleAffiliationsInput = value => loadAffiliations({ affiliation_name: value })

  const affiliationOptions = () => affiliationsData.map(affiliation => affiliation.affiliation_name)

  const onInvestorNameBlur = async event => {
    const name = event.target.value

    if (name) {
      const { nameExists } = await checkName({ investor_name: name })

      if (nameExists) {
        setUserExistsError('Investor already exists')
      } else {
        setUserExistsError('')
      }
    }
  }

  return (
    <Grid>
      <form className={className} id="investor-form" onSubmit={handleInvestorSubmit} noValidate autoComplete="off">
        <FormTextInput
          name="investor_name"
          required={true}
          label="Investor Name"
          validators={[requiredField]}
          className={classes.investor_name}
          onBlur={onInvestorNameBlur}
          onChange={() => setUserExistsError('')}
          externalError={userExistsError}
        />

        <InvestorIntroducer />

        <FormTextInput name="address" label="Address" className={classes.address} />

        <FormTextInput
          name="email"
          type="email"
          required
          label="Email"
          validators={[requiredField, isEmail]}
          className={classes.email}
        />

        <FormTextInput
          name="phone_number"
          label="Phone"
          required
          validators={[requiredField]}
          className={classes.phone}
        />

        <FormDropdown
          name="investor_type"
          label="Primary Affiliation"
          options={primaryAffiliationOptions}
          validators={[requiredField]}
          required
        />

        <ChipList
          onInput={handleAffiliationsInput}
          options={affiliationOptions()}
          addLabel="Add Affiliation"
          onChange={handleAffiliationsChange}
          initialValues={affiliations}
          className={classes.affiliations}
          label="Affiliations"
        />

        <FormTextArea
          required={false}
          rows={4}
          name="bio"
          label="Bio"
          placeholder="Write investor's bio here"
          className={classes.bio}
        />
      </form>
    </Grid>
  )
}
