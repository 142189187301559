import moment from 'moment'
import { DATE_FORMAT } from 'constants/date'
import { getInvestmentChanges } from './getInvestmentChanges'
import { getInvestorChanges } from './getInvestorChanges'
import { getCompanyChanges } from './getCompanyChanges'

export const formatChanges = (data, investorView) =>
  data.map(c => {
    const {
      group_action,
      group_type,
      submitter,
      submitted_at,
      submittions,
      resource_snapshot,
      id,
      reviewer,
      reviewed_at,
    } = c

    const commonChanges = {
      id,
      changedBy: submitter.full_name,
      date: moment(submitted_at).format(DATE_FORMAT),
      approvedBy: reviewer.full_name,
      reviewedAt: moment(reviewed_at).format(DATE_FORMAT),
      actionType: group_action,
    }

    let typeChanges
    if (group_type === 'Investment') {
      typeChanges = getInvestmentChanges({
        action: group_action,
        snapshot: resource_snapshot,
        changes: submittions,
        investorView,
      })
    } else if (group_type === 'Investor') {
      typeChanges = getInvestorChanges({
        action: group_action,
        snapshot: resource_snapshot,
        changes: submittions,
        investorView,
      })
    } else if (group_type === 'Company') {
      typeChanges = getCompanyChanges({
        action: group_action,
        snapshot: resource_snapshot,
        changes: submittions,
        investorView,
      })
    }

    return { ...commonChanges, ...typeChanges }
  })
