export const nameToLabelMapping = {
  name: 'Company name',
  number: 'Company number',
  registered_address: 'Registered address',
  most_recent_price: 'Share price at most recent capital raise, £',
  current_market_price: 'Current marketed share price, £',
  trading_address: 'Trading address',
  dropbox_link: 'Dropbox',
  website: 'Website',
  company_house_link: 'Companies House link',
}
