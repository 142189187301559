export const investorFields = [
  'investor_name',
  'introducer',
  'address',
  'email',
  'phone_number',
  'investor_type',
  'investor_affiliations',
  'bio',
]
