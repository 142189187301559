import React from 'react'
import { Form } from 'react-final-form'
import { AccordionSummary, Button, Grid, IconButton, Typography } from '@material-ui/core'
import { Modal } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { InvestmentDetails, InvestmentSecurityDetails } from '../detailsView'
import { useSetState } from 'react-use'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { RealiseDetails } from './RealiseDetails'
import { RealiseEquity } from './RealiseEquity'
import { SECURITY_TYPES } from 'constants/security-types'
import { RealiseDebtType } from './RealiseDebt'
import { RealiseDebtDetails } from './RealiseDebtDetails'

const useStyles = makeStyles(theme => ({
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: theme.spacing(3),
  },
  realiseAll: {
    gridColumn: '3/4',
    marginTop: theme.spacing(-1),
  },
  realiseAllDebt: {
    gridColumn: '2/3',
    marginTop: theme.spacing(-1),
  },
  splitDetails: {
    gridColumn: '1/4',
  },
  investmentAccordion: {
    marginBottom: theme.spacing(2),
  },
  investmentDetails: {
    padding: theme.spacing(0, 1),
    marginTop: theme.spacing(-3),
  },
  securityDetails: {
    padding: theme.spacing(0, 1),
    marginTop: theme.spacing(-3),
  },
  realiseDetails: {
    margin: theme.spacing(2, 0, 3, 0),
    padding: theme.spacing(3),
  },
}))

export const InvestmentRealise = ({ investment, onSubmit, open, handleClose, isLoading }) => {
  const [{ openInvestmentDetails, openSecurityDetails }, setState] = useSetState({
    realiseAll: false,
    openInvestmentDetails: false,
    openSecurityDetails: false,
  })

  const classes = useStyles()

  const toggleInvestmentDetails = () => setState({ openInvestmentDetails: !openInvestmentDetails })

  const toggleSecurityDetails = () => setState({ openSecurityDetails: !openSecurityDetails })

  const currentPrice = investment.investment_security.investment_security_values.share_price
  const investedCapital = investment.investment_security.investment_security_values.invested_capital

  const type = investment.investment_security.type

  return (
    <Modal
      open={open}
      modalTitle="Realise Transaction"
      modalContent={
        <>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, values, form }) => (
              <form
                id="realise-investment"
                onSubmit={handleSubmit}
                className={classes.form}
                noValidate
                autoComplete="off"
              >
                {type === SECURITY_TYPES.EQUITY ? (
                  <>
                    <RealiseEquity form={form} investment={investment} classes={classes} />

                    <div className={classes.splitDetails}>
                      <RealiseDetails
                        className={classes.realiseDetails}
                        sharesCount={values.shares_sold_amount}
                        realisePrice={values.realised_share_price}
                        initialPrice={currentPrice}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <RealiseDebtType form={form} investment={investment} classes={classes} />

                    <div className={classes.splitDetails}>
                      <RealiseDebtDetails
                        className={classes.realiseDetails}
                        investedCapital={investedCapital}
                        realisedCapital={values.realised_capital}
                      />
                    </div>
                  </>
                )}

                <div className={classes.splitDetails}>
                  <Accordion
                    className={classes.investmentAccordion}
                    elevation={1}
                    expanded={openInvestmentDetails}
                    TransitionProps={{ unmountOnExit: true }}
                  >
                    <AccordionSummary onClick={toggleInvestmentDetails}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <IconButton aria-label="expand row" size="small">
                            {openInvestmentDetails ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <Typography variant="h4" color="textSecondary" component="span">
                            Investment Details
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InvestmentDetails
                        investment={investment}
                        panelClass={classes.investmentDetails}
                        title=""
                        xs={4}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion elevation={1} expanded={openSecurityDetails} TransitionProps={{ unmountOnExit: true }}>
                    <AccordionSummary onClick={toggleSecurityDetails}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <IconButton aria-label="expand row" size="small">
                            {openSecurityDetails ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <Typography variant="h4" color="textSecondary" component="span">
                            Security Details
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <InvestmentSecurityDetails
                        security={investment.investment_security}
                        panelClass={classes.securityDetails}
                        title=""
                        xs={4}
                      />
                    </AccordionDetails>
                  </Accordion>
                </div>
              </form>
            )}
          />
        </>
      }
      modalActions={
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button onClick={handleClose} color="primary" variant="outlined" disabled={isLoading}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              form="realise-investment"
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
              disabled={isLoading}
            >
              Realise
            </Button>
          </Grid>
        </Grid>
      }
      isLoading={isLoading}
    />
  )
}
