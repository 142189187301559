import React from 'react'
import { Form } from 'react-final-form'
import { useMutation } from 'react-query'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
import { Alert } from '@material-ui/lab'
import Collapse from '@material-ui/core/Collapse'
import { BreadCrumbsHeader, ConfirmationDialog, FadeContent, FormCurrencyInput, FormTextInput, Panel } from 'components'
import { postCompany } from 'api'
import { currency, isAlphaNumeric, isDropboxUrl, isUrl, requiredField } from 'validators'
import { useSetState } from 'react-use'
import { useHistory } from 'react-router-dom'
import { apiErrors } from 'utils/error'
import { useToast } from 'hooks'

const useStyles = makeStyles(theme => ({
  createCompanyForm: {
    padding: theme.spacing(3, 5),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: theme.spacing(4),
    gridRowGap: theme.spacing(2),
  },
  addToList: {
    display: 'flex',
    alignItems: 'center',
  },
  addToListCheckbox: {
    paddingLeft: 0,
  },
}))

export const CompanyCreateForm = ({ securityList }) => {
  const classes = useStyles()
  const history = useHistory()
  const toastService = useToast()

  const [createCompany, { isLoading }] = useMutation(postCompany, { throwOnError: true })
  const [{ openConfirmation }, setState] = useSetState({ openConfirmation: false })

  const handleCancelClick = values => () => {
    const keys = Object.keys(values)
    if (keys.length === 0) {
      handleAccept()
    } else {
      setState({ openConfirmation: true })
    }
  }

  const onSubmit = async values => {
    const company = { ...values }
    company.securities = securityList
    try {
      await createCompany(company)
      toastService.showSuccessToast(`${company.name} successfully created.`)
      history.push('/companies')
    } catch (e) {
      toastService.showErrorToast(`Failed to create ${company.name}. ${e.message}`)
      return apiErrors(e)
    }
  }

  const getConfirmationContent = form => {
    const field = form.getFieldState('name') || { value: '' }
    const name = field.value
    return `You are going to cancel ${name} creation. All the filed information will be lost.`
  }

  const handleCancel = () => {
    setState({ openConfirmation: false })
  }

  const handleAccept = () => {
    history.push('/companies')
  }

  const handleAddToMyCompaniesChange = form => event => form.change('add_to_my_companies', event.target.checked)

  return (
    <Form
      initialValues={{ post_money_valuation: 0 }}
      onSubmit={onSubmit}
      render={({ handleSubmit, form, values, submitError, pristine }) => (
        <FadeContent>
          <Grid>
            <BreadCrumbsHeader
              section="Companies"
              subsection="Create Company"
              title="Create Company"
              backPath="/companies"
            >
              <Box ml="auto">
                <Grid container item spacing={2}>
                  <Grid item>
                    <Button color="primary" onClick={handleCancelClick(values)} variant="outlined">
                      CANCEL
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button color="primary" variant="contained" onClick={handleSubmit} disabled={isLoading || pristine}>
                      CREATE
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </BreadCrumbsHeader>

            <Panel title="Company details">
              <form className={classes.createCompanyForm} noValidate autoComplete="off">
                <FormTextInput label="Company name" name="name" required validators={[requiredField]} />
                <FormTextInput
                  label="Company number"
                  name="number"
                  required
                  validators={[requiredField, isAlphaNumeric]}
                />
                <FormTextInput
                  label="Registered address"
                  name="registered_address"
                  required
                  validators={[requiredField]}
                />

                <FormCurrencyInput
                  label="Share price at most recent capital raise, £"
                  name="most_recent_price"
                  required
                  validators={[requiredField, currency]}
                />
                <FormCurrencyInput
                  label="Current marketed share price, £"
                  name="current_market_price"
                  required
                  validators={[requiredField, currency]}
                />
                <FormTextInput label="Trading Address" name="trading_address" required validators={[requiredField]} />

                <FormTextInput
                  label="Dropbox"
                  name="dropbox_link"
                  required
                  validators={[requiredField, isUrl, isDropboxUrl]}
                />
                <FormTextInput label="Website" name="website" required validators={[requiredField, isUrl]} />
                <FormTextInput
                  label="Companies House link"
                  name="company_house_link"
                  required
                  validators={[requiredField, isUrl]}
                />

                <div />
                <div className={classes.addToList}>
                  <FormControlLabel
                    onChange={handleAddToMyCompaniesChange(form)}
                    label='Add company to "My Companies" list'
                    control={
                      <Checkbox
                        className={classes.addToListCheckbox}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                  />
                </div>
                <Collapse in={!!submitError}>
                  <Alert variant="filled" severity="error">
                    {submitError}
                  </Alert>
                </Collapse>
              </form>
              <ConfirmationDialog
                open={openConfirmation}
                content={getConfirmationContent(form)}
                title="Cancel company creation"
                onCancel={handleCancel}
                onAccept={handleAccept}
              />
            </Panel>
          </Grid>
        </FadeContent>
      )}
    />
  )
}
