import React from 'react'
import { FormTextArea, Modal } from 'components'
import { Form } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'
import { requiredField } from 'validators'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles(theme => ({
  modal: {
    width: '500px',
  },
  modalContent: {
    paddingBottom: 0,
  },
  description: {
    fontSize: '0.875rem',
    marginBottom: theme.spacing(3),
  },
}))

export const RejectModal = ({ open, title, description, onSubmit, onCancel }) => {
  const classes = useStyles()

  return (
    <Modal
      open={open}
      modalClass={classes.modal}
      modalTitle={title}
      modalContentClass={classes.modalContent}
      modalContent={
        <>
          <div className={classes.description}>{description}</div>

          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitError }) => (
              <form id="reject-changes" onSubmit={handleSubmit} noValidate>
                <FormTextArea
                  name="comment"
                  label="Comment"
                  required
                  rows={5}
                  placeholder="Write a comment..."
                  validators={[requiredField]}
                  error={submitError}
                />

                <FormHelperText error={!!submitError}>{submitError}</FormHelperText>
              </form>
            )}
          />
        </>
      }
      modalActions={
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button onClick={onCancel} color="primary" variant="outlined">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" form="reject-changes" color="primary" variant="contained" autoFocus>
              Reject
            </Button>
          </Grid>
        </Grid>
      }
    />
  )
}
