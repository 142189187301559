import React from 'react'
import { INVESTOR_TYPE } from 'constants/investor-type'
import FigIcon from 'assets/images/fig.svg'
import NewModelIcon from 'assets/images/new-model.svg'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  icon: {
    width: '18px',
    height: '18px',
    display: 'block',
  },
}))

export const CompanyTypeIcon = ({ type }) => {
  const classes = useStyles()

  if (type === INVESTOR_TYPE.FIG) {
    return <img className={classes.icon} src={FigIcon} alt="Fig Investor Icon" />
  }

  if (type === INVESTOR_TYPE.NEW_MODEL) {
    return <img className={classes.icon} src={NewModelIcon} alt="New Model Icon" />
  }

  return <span className={classes.icon} />
}
