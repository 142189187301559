import React, { useState } from 'react'
import { Panel, DetailsValue, FadeContent } from 'components'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, IconButton, Link } from '@material-ui/core'
import { nameToLabelMapping, companyFields } from 'utils/company'
import Edit from '@material-ui/icons/Edit'
import { CompanyEdit } from 'components/company/CompanyEdit'
import { queryCache, useMutation } from 'react-query'
import { apiErrors } from 'utils/error'
import { updateCompany as updateCompanyApi } from 'api'
import { useToast } from 'hooks'
import { currency } from 'utils/numberFormat'

const useStyles = makeStyles(theme => ({
  detailsPanel: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  companyDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridRowGap: theme.spacing(3),
  },
  link: {
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
  },
}))

export const CompanyDetailsView = ({ company }) => {
  const [openEdit, setOpenEdit] = useState(false)
  const [updateCompany, { isLoading }] = useMutation(updateCompanyApi, { throwOnError: true })
  const toastService = useToast()

  const openEditModal = () => setOpenEdit(true)
  const closeEditModal = () => setOpenEdit(false)

  const handleEditSubmit = async updatedCompany => {
    try {
      await updateCompany({ company: updatedCompany, companyId: company.id })
      queryCache.invalidateQueries('company')
      closeEditModal()
      toastService.showSuccessToast(`Successfully updated company ${updatedCompany.name}.`)
    } catch (e) {
      toastService.showErrorToast(`Failed to update company ${updatedCompany.name}. ${e.message}`)
      return apiErrors(e)
    }
  }

  const classes = useStyles()

  const urlFields = new Set(['company_house_link', 'dropbox_link', 'website'])

  const renderLink = url => (
    <Link href={url} target="_blank" rel="noreferrer" color="textPrimary" underline="always" className={classes.link}>
      {url}
    </Link>
  )

  const renderMappings = {
    current_market_price: currency,
    most_recent_price: currency,
  }

  return (
    <Panel
      disableGutters
      title="Company details"
      buttonLabel="Edit company details"
      panelClass={classes.detailsPanel}
      button={
        <IconButton color="primary" variant="outlined" onClick={openEditModal}>
          <Edit />
        </IconButton>
      }
    >
      <FadeContent>
        <Box p={3} className={classes.companyDetails}>
          {companyFields.map(field => {
            if (urlFields.has(field)) {
              return (
                <Grid item xs={10} xl={11} key={field}>
                  <DetailsValue label={nameToLabelMapping[field]} value={company[field]} renderValue={renderLink} />
                </Grid>
              )
            }
            return (
              <DetailsValue
                key={field}
                label={nameToLabelMapping[field]}
                value={company[field]}
                renderValue={renderMappings[field]}
              />
            )
          })}
        </Box>
      </FadeContent>

      <CompanyEdit
        company={openEdit && company}
        handleClose={closeEditModal}
        handleSubmit={handleEditSubmit}
        isLoading={isLoading}
      />
    </Panel>
  )
}
