import React from 'react'
import Paper from '@material-ui/core/Paper'
import classnames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import { DetailsValue, CurrencyValue } from 'components'

const useStyles = makeStyles(theme => ({
  realiseDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    backgroundColor: theme.palette.grayShades.gray5,
    borderBottom: 'none',
    boxShadow: 'none',
  },
}))

const renderRealiseCurrencyCell = value => <CurrencyValue value={value} justify="flex-start" />

export const RealiseDebtDetails = ({ className = '', realisedCapital = 0, investedCapital = 0 }) => {
  const classes = useStyles()

  return (
    <Paper elevation={1} className={classnames([classes.realiseDetails, className])}>
      <DetailsValue label="Realised Capital" value={realisedCapital} renderValue={renderRealiseCurrencyCell} />

      <DetailsValue label="Invested Capital " value={investedCapital} renderValue={renderRealiseCurrencyCell} />
    </Paper>
  )
}
