import { API } from './ApiClient'

const postInvestment = body => API.post('/investment', { body })
const updateInvestment = body => API.put(`/investment/${body.id}`, { body })
const getUploadUrl = body => API.post('/investment/upload-url', { body })
const getAllInvestments = () => API.get('/investment')
const splitInvestment = ({ id, body }) => API.post(`/investment/${id}/split`, { body })
const convertInvestment = ({ id, body }) => API.post(`/investment/${id}/convert`, { body })
const cancelInvestment = id => API.post(`/investment/${id}/cancel`)

const uploadFile = (url, file) =>
  fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
      'x-amz-acl': 'public-read',
    },
    body: file,
  })

export {
  postInvestment,
  getUploadUrl,
  uploadFile,
  updateInvestment,
  getAllInvestments,
  splitInvestment,
  convertInvestment,
  cancelInvestment,
}
