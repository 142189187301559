import React from 'react'
import { DetailsView } from './DetailsView'
import { FIELDS_LABELS, formatSecurityField, getAllSecurityFields, normalizeSecurityChanges } from 'utils/security'
import { SECURITY_TYPES } from 'constants/security-types'

export const InvestmentSecurityDetails = ({
  security,
  showChangedOnly = false,
  title = 'Security Details',
  panelClass = '',
  xs = 3,
  splitSecurity = false,
}) => {
  const securityType = security.security.type
  let securityFields = getAllSecurityFields(securityType)

  let labelsMapping = FIELDS_LABELS

  if (splitSecurity) {
    const insertIndex = 6

    securityFields = [
      ...securityFields.slice(0, insertIndex),
      'realised_share_price',
      'realised_capital',
      ...securityFields.slice(insertIndex),
    ]

    const getCapitalLabel = type => {
      if (type === SECURITY_TYPES.DEBT || type === SECURITY_TYPES.CONVERTIBLE_DEBT) {
        return 'Invested capital relating to realisation'
      }

      return 'Invested capital relating to sold shares'
    }

    labelsMapping = {
      ...labelsMapping,
      number_of_shares: 'Number of shares sold',
      share_price: 'Initial transaction share price',
      invested_capital: getCapitalLabel(securityType),
    }
  }

  const securityEntity = {
    ...security,
    ...security.security,
    ...security.security[securityType],
    ...security.investment_security_values,
  }

  const renderMapping = {}
  securityFields.forEach(field => (renderMapping[field] = value => formatSecurityField(value, field)))

  const changesMap = normalizeSecurityChanges(security.changes)

  if (Object.keys(changesMap).length === 0 && showChangedOnly) {
    return null
  }

  return (
    <DetailsView
      title={title}
      fields={securityFields}
      entity={securityEntity}
      changesMap={changesMap}
      labelsMapping={labelsMapping}
      renderMappings={renderMapping}
      showChangedOnly={showChangedOnly}
      panelClass={panelClass}
      xs={xs}
    />
  )
}
