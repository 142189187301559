import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { paddedNumber, currency } from 'utils/numberFormat'
import { TextTooltip } from './TextTooltip'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  noWrap: {
    whiteSpace: 'nowrap',
  },
}))

export const CurrencyCell = ({ value, tooltipTitle = '', asDecimal = false }) => {
  const classes = useStyles()

  const format = asDecimal ? currency : paddedNumber
  const content = <span className={classnames({ [classes.noWrap]: !tooltipTitle })}>{format(value)}</span>

  return (
    <Grid container alignItems="center">
      <Grid item xs={3} xl={2}>
        <Typography variant="subtitle1" color="textSecondary" component="span">
          £
        </Typography>
      </Grid>
      <Grid item container xs={7} xl={6} justify="flex-end">
        <TextTooltip arrow title={tooltipTitle}>
          {content}
        </TextTooltip>
      </Grid>
    </Grid>
  )
}
