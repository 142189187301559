import React, { useState } from 'react'
import { DebtTable, Spinner } from 'components'
import { IconButton } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { getInvestorDebts } from 'api'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

export const InvestorDebtTable = () => {
  const [expands, setExpands] = useState([false, false])
  let { investorId } = useParams()

  const { data: debts = [], isLoading } = useQuery(['investprDebts', investorId], getInvestorDebts)

  const onIconClick = i => () => {
    const expandsCopy = [...expands]
    expandsCopy[i] = !expands[i]
    setExpands(expandsCopy)
  }

  if (isLoading) {
    return <Spinner color="secondary" size={40} centered height="500px" />
  }

  const { stats, table } = debts
  const { investments_list: investmentsList } = table

  const debtsDetails = parseDebts(investmentsList, stats, onIconClick, expands)

  return (
    <DebtTable
      debtOwner="Company"
      totalPrincipal={debtsDetails.totalPrincipal}
      weightedInterestRate={debtsDetails.weightedInterestRate}
      debtDetails={debtsDetails}
    />
  )
}

function parseDebts(debts, stats, onIconClick, expands) {
  let investmentsList = []
  let totalPrincipal = stats.totalPrincipal
  let totalInterest = stats.totalInterest

  for (let debt of debts) {
    let companyIndex = investmentsList.length

    let companyDebt = {
      iconCell: (
        <IconButton size="small" onClick={onIconClick(companyIndex)}>
          {expands[companyIndex] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
        </IconButton>
      ),
      open: expands[companyIndex],
      investor_name: debt.company_name,
      principal: debt.principal,
      interest_rate: debt.interest_rate,
      realised_capital: debt.company_realised,
      termination_date: debt.company_termination_date,
      asset_name: '',
      interest_calc: '',
      interest_frequency_payments: '',
      conversion_discount: '',
      debtDetails: debt.investments,
    }

    investmentsList.push(companyDebt)
  }

  return {
    stats: {
      totalInterest,
      totalPrincipal,
    },
    investmentsList,
  }
}
