import React from 'react'
import { FormDropdown } from 'components'
import { requiredField } from 'validators/requiredField'
import { PAYMENT_FREQUENCIES } from 'constants/compunding-frequency'

export const SecurityPaymentFrequency = ({ className }) => (
  <FormDropdown
    className={className}
    label="Frequency of payments"
    name="frequency_of_payments"
    options={PAYMENT_FREQUENCIES}
    required
    validators={[requiredField]}
  />
)
