import { splitAttachments } from './splitAttachements'

export const enrichInvestment = investment => {
  const security = investment.investment_security

  return {
    ...investment,
    invested_capital: security.investment_security_values.invested_capital,
    asset_name: security?.security?.name,
    investor: investment.investor.investor_name,
    managed_by: investment.managed_by?.investor_name,
    legal_investor: investment.legal_investor?.investor_name,
    ...splitAttachments(investment.attachments),
  }
}
