import React, { useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import { useQuery } from 'react-query'
import { getInvestors } from 'api'
import { makeStyles } from '@material-ui/core/styles'

import { Modal } from 'components'
import { InvestmentForm } from './InvestmentForm'
import { splitAttachments } from 'utils/investment'
import { SECURITY_TYPE_LABELS } from 'constants/security-type-labels'

const useStyles = makeStyles(theme => ({
  modalContentClass: {
    margin: 0,
    paddingBottom: 0,
  },
  convertInvestmentForm: {
    padding: theme.spacing(0),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
  },
  investmentDate: {
    gridColumn: '1/4',
  },
  investmentCapital: {
    gridColumn: '2/4',
  },
  securityList: {
    gridColumn: '1/3',
    padding: 0,
  },
  convertInput: {
    gridColumn: '1/3',
  },
  convertAll: {
    margin: theme.spacing(-2, 0, 0, 2),
    marginLeft: 'auto',
  },
}))

export const InvestmentConvert = ({ open, investment, handleClose, onSubmit, isLoading = false }) => {
  const classes = useStyles()

  const { data: investors = [], isLoading: loadingInvestors } = useQuery('investors', getInvestors)

  const [dirty, setDirty] = useState(false)

  const { company, investor } = investment

  const convertInvestment = ({
    share_certificate = [],
    subscription_document = [],
    scheme_paperwork = [],
    ...data
  }) => {
    const investorId = data.beneficial_investor_id

    let investmentPayload = {
      company_id: data.company_id,
      investor_id: investorId,
      investment_date: data.investment_date,
      applicable_schemas: data.applicable_schemes,
      requested_schemas: data.requested_schemes,
      cash_received: data.cash_received,
      security_id: data.security_id,
      investment_security: data.securities,
      fees_due: data.fees_due,
      investment_type: data.investment_type,
      legal_investor_id: data.legal_investor_id || investorId,
      managed_by_id: data.managed_by_id || investorId,
      attachments: [...share_certificate, ...subscription_document, ...scheme_paperwork].map(
        ({ storage_url, file_name, file_type, document_type }) => ({
          storage_url,
          file_name,
          file_type,
          document_type,
        })
      ),
    }

    onSubmit(investmentPayload)
  }

  return (
    <Modal
      open={open}
      modalTitle="Convert Transaction"
      modalContentClass={classes.modalContentClass}
      modalContent={
        <>
          <InvestmentForm
            company={company}
            investors={investors}
            investment={investment}
            selectedInvestor={investor}
            initialValues={getInitialValues({ investment, company, investor })}
            className={classes.convertInvestmentForm}
            classes={{
              investmentDate: classes.investmentDate,
              investmentCapital: classes.investmentCapital,
              securityList: classes.securityList,
              convertInput: classes.convertInput,
              convertAll: classes.convertAll,
            }}
            onSubmit={convertInvestment}
            disableCompanySelect
            disableInvestorSelect
            disableSecuritySelect
            convertInvestmentForm
            handleDirty={isDirty => setDirty(isDirty)}
          />
        </>
      }
      modalActions={
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button onClick={handleClose} color="primary" variant="outlined" disabled={isLoading}>
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              form="investment-form"
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
              disabled={isLoading || !dirty}
            >
              Convert
            </Button>
          </Grid>
        </Grid>
      }
      isLoading={loadingInvestors || isLoading}
    />
  )
}

function getInitialValues({ company, investment, investor }) {
  const { investment_security } = investment
  const { security, investment_security_values } = investment_security
  const { converts_to_security } = security[security.type]

  const price = getConvertSharePrice(investment_security_values)

  return {
    name: company?.name,
    company_id: company?.id,
    ...investment,
    status: investment.status.name,
    security_id: converts_to_security.id,
    security_type: SECURITY_TYPE_LABELS[converts_to_security.type],
    securities: {
      share_price: price,
      additional_notes: `Converted from ${security.name}`,
    },
    applicable_schemes: investment.applicable_schemas,
    requested_schemes: investment.requested_schemas,
    beneficial_investor_id: investor.id,
    legal_investor_id: investment?.legal_investor?.id,
    managed_by_id: investment?.managed_by?.id,
    ...splitAttachments(investment.attachments),
    investment_date: undefined,
    invested_capital: undefined,
  }
}

function getConvertSharePrice(investmentValues) {
  const exercise_price = parseFloat(investmentValues.exercise_price)
  const price = parseFloat(investmentValues.warrant_price || investmentValues.option_price)

  return (exercise_price + price).toString(10)
}
