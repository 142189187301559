import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { SecurityVoting } from './SecurityVoting'
import { SecurityRank } from './SecurityRank'
import { SecurityConvertsTo } from './SecurityConvertsTo'
import { FormRadioGroup } from 'components/RadioGroup'
import { PREFERENTIAL_RIGHTS } from 'constants/preferntial-rights'
import { requiredField } from 'validators'

const useStyles = makeStyles(() => ({
  optionFields: {
    display: 'flex',
  },
  voting: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const AddWarrant = ({ securityList }) => {
  const classes = useStyles()

  return (
    <>
      <SecurityRank />
      <SecurityConvertsTo options={securityList} />

      <SecurityVoting />
      <FormRadioGroup
        label="Preferential rights"
        name="preferential_rights"
        required={true}
        groupClass={classes.voting}
        optionsClass={classes.options}
        options={[
          { label: 'Yes', value: PREFERENTIAL_RIGHTS.YES },
          { label: 'No', value: PREFERENTIAL_RIGHTS.NO },
        ]}
        validators={[requiredField]}
      />
    </>
  )
}
