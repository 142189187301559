import React from 'react'
import { requiredField } from 'validators'
import { FormDateInput } from 'components'

export const AddConvertibleDebtInvestment = () => (
  <>
    <FormDateInput
      label="Final repayment/conversion date"
      name="securities.final_repayment_date"
      validators={[requiredField]}
      required
    />
  </>
)
