import React from 'react'
import { Helmet } from 'react-helmet'

import { LoginForm } from 'components/auth'

export const Login = () => (
  <>
    <Helmet>
      <title>New Model VC Dashboard - Login</title>
    </Helmet>
    <LoginForm />
  </>
)
