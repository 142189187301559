import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Person from '@material-ui/icons/Person'
import Badge from '@material-ui/core/Badge'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { NavLink, Link, useHistory, useLocation } from 'react-router-dom'
import { queryCache, useQuery } from 'react-query'
import { withStyles } from '@material-ui/core/styles'

import { useAuth } from 'hooks/useAuth'
import { countApprovals } from 'api/approvals'
import { HasRole } from 'components'
import { ADMIN_USERS } from 'constants/roles'
import { Menu, MenuItem } from '@material-ui/core'
import NewModelLogo from 'assets/images/nmvc-logo.svg'
import FigLogo from 'assets/images/fig-logo.svg'

const useStyles = makeStyles(theme => ({
  appHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(3, 6),
    borderBottom: `1px solid ${theme.palette.common.pattensBlue}`,
  },
  homeLink: {
    fontSize: '1rem',
  },
  appLinks: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  appLink: {
    display: 'flex',
    listStyle: 'none',
    margin: theme.spacing(0, 2.5),
    color: theme.palette.common.blueGray,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  activeLink: {
    color: 'black',
  },
  profileSection: {
    display: 'flex',
    alignItems: 'center',
  },
  profileButton: {
    backgroundColor: theme.palette.grayShades.gray4,
    color: theme.palette.grayShades.gray3,
    marginLeft: theme.spacing(3),
  },
  logo: {
    height: '30px',
    margin: 0,
  },
  logoSpacer: {
    width: '1px',
    backgroundColor: theme.palette.common.pattensBlue,
    margin: theme.spacing(0, 3),
  },
}))

const StyledBadge = withStyles(() => ({
  root: {
    paddingRight: '15px',
  },
}))(Badge)

export const AppHeader = () => {
  const classes = useStyles()
  const { isAuthenticated, profile, logout, role } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const [prevCount, setPrevCount] = useState(0)
  const { pathname } = useLocation()
  const history = useHistory()

  const { data: approvalsCount = {} } = useQuery('countApprovals', countApprovals, {
    enabled: ADMIN_USERS.includes(role),
    refetchInterval: 30 * 1000,
  })

  const { count } = approvalsCount

  const invalidateApprovals = () => {
    queryCache.invalidateQueries('approvals')
  }

  if (count !== prevCount) {
    setPrevCount(count)
    if (pathname === '/approvals') {
      invalidateApprovals()
    }
  }

  const handleMenuClick = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleLogoutClick = async () => {
    await logout()
    history.replace('/auth/login')
  }

  const handleProfileClick = () => {
    history.push('/user/profile')
    handleClose()
  }

  return (
    <header className={classes.appHeader}>
      <Link to="/companies" className={`${classes.appLink} ${classes.logo}`}>
        <img src={NewModelLogo} alt="New Model Logo" />

        <div className={classes.logoSpacer} />

        <img src={FigLogo} alt="New Model Logo" />
      </Link>

      <ul className={classes.appLinks}>
        <NavLink to="/companies" className={classes.appLink} activeClassName={classes.activeLink}>
          Companies
        </NavLink>

        <NavLink to="/investors" className={classes.appLink} activeClassName={classes.activeLink}>
          Investors
        </NavLink>

        <HasRole roles={ADMIN_USERS}>
          <NavLink to="/approvals" className={classes.appLink} activeClassName={classes.activeLink}>
            <StyledBadge badgeContent={count} max={99} color="error" onClick={invalidateApprovals}>
              Approvals
            </StyledBadge>
          </NavLink>
        </HasRole>

        <HasRole roles={ADMIN_USERS}>
          <NavLink to="/admin/user-management" className={classes.appLink} activeClassName={classes.activeLink}>
            User Management
          </NavLink>
        </HasRole>
      </ul>

      <div className={classes.profileSection}>
        {isAuthenticated && (
          <>
            <Typography component="span">Hello, {profile.first_name}</Typography>

            <IconButton
              color="primary"
              aria-label="edit profile"
              className={classes.profileButton}
              onClick={handleMenuClick}
            >
              <Person />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              elevation={1}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem onClick={handleProfileClick}>Profile Settings</MenuItem>
              <MenuItem onClick={handleLogoutClick}>Sign Out</MenuItem>
            </Menu>
          </>
        )}
      </div>
    </header>
  )
}
