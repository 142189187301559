import React from 'react'
import { DetailsView, DocumentDetails } from 'components/detailsView'
import { enrichInvestment, nameToLabelMapping, normalizeInvestmentChanges, investmentFields } from 'utils/investment'
import moment from 'moment'
import { DATE_FORMAT } from 'constants/date'

export const InvestmentDetails = ({
  investment,
  changes = [],
  showChangedOnly = false,
  title = 'Investment Details',
  panelClass = '',
  xs = 3,
}) => {
  const investmentSnapshot = enrichInvestment(investment)

  let fields = investmentFields

  if (investment.investment_status === 'Realised') {
    fields = [...fields, 'realisation_date']
  }

  const renderMappings = {
    applicable_schemas: value => value.join(', '),
    investment_date: value => moment(value).format(DATE_FORMAT),
    realisation_date: value => moment(value).format(DATE_FORMAT),
    fees_due: value => (value ? 'Yes' : 'No'),
    subscription_document: value => <DocumentDetails value={value} />,
    scheme_paperwork: value => <DocumentDetails value={value} />,
    share_certificate: value => <DocumentDetails value={value} />,
  }

  const changesMap = normalizeInvestmentChanges(changes)

  return (
    <DetailsView
      title={title}
      fields={fields}
      entity={investmentSnapshot}
      changesMap={changesMap}
      labelsMapping={nameToLabelMapping}
      renderMappings={renderMappings}
      showChangedOnly={showChangedOnly}
      panelClass={panelClass}
      xs={xs}
    />
  )
}
