import React, { useState } from 'react'
import { BreadCrumbsHeader, ConfirmationDialog, FadeContent, Panel } from 'components'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { createInvestor as createInvestorApi } from 'api'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { apiErrors } from 'utils/error'
import { useToast } from 'hooks'
import { InvestorForm } from './InvestorForm'

const useStyles = makeStyles(theme => ({
  createInvestorForm: {
    padding: theme.spacing(2.5, 5, 5.5, 5),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: theme.spacing(4),
    gridRowGap: theme.spacing(2),
  },
  investorBio: {
    gridColumn: '1/3',
  },
  affiliations: {
    gridColumn: '1/4',
  },
}))

export const InvestorCreate = () => {
  const classes = useStyles()
  const history = useHistory()
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [createInvestor, { isLoading }] = useMutation(createInvestorApi, { throwOnError: true })
  const toastService = useToast()

  const onSubmit = async values => {
    const { introducer, ...rest } = values
    const investor = { ...rest, introducer_name: introducer }

    try {
      await createInvestor(investor)
      history.push('/investors')
      toastService.showSuccessToast(`Investor ${investor.investor_name} created successfully.`)
    } catch (e) {
      toastService.showErrorToast(`Failed to create investor ${investor.investor_name}. ${e.message}`)
      console.log(e)
      return apiErrors(e)
    }
  }

  const handleCancel = () => {
    if (!isDirty) {
      history.push('/investors')
    } else {
      setOpenConfirmationModal(isDirty)
    }
  }

  const cancel = () => setOpenConfirmationModal(false)

  const handleAccept = () => {
    setOpenConfirmationModal(false)
    history.push('/investors')
  }

  return (
    <FadeContent>
      <Grid>
        <BreadCrumbsHeader
          section="Investors"
          subsection="Create Investor"
          title="Create Investor"
          backPath="/investors"
        >
          <Box ml="auto">
            <Grid item container spacing={2}>
              <Grid item>
                <Button color="primary" variant="outlined" disabled={isLoading} onClick={handleCancel}>
                  CANCEL
                </Button>
              </Grid>
              <Grid item>
                <Button
                  form="investor-form"
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={isLoading || !isDirty}
                >
                  CREATE
                </Button>
              </Grid>
            </Grid>
          </Box>
        </BreadCrumbsHeader>

        <Panel title="Investor Details">
          <InvestorForm
            className={classes.createInvestorForm}
            onSubmit={onSubmit}
            investor={{}}
            handleDirty={dirty => setIsDirty(dirty)}
            classes={{
              bio: classes.investorBio,
              affiliations: classes.affiliations,
            }}
          />
        </Panel>

        <ConfirmationDialog
          open={openConfirmationModal}
          title="Cancel Investor Creation"
          onCancel={cancel}
          onAccept={handleAccept}
          content={`You are going to cancel creation. All the filed information will be lost.`}
        />
      </Grid>
    </FadeContent>
  )
}
