import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { ArrowDropDown } from '@material-ui/icons'
import Menu from '@material-ui/core/Menu'
import { useSetState } from 'react-use'
import MenuItem from '@material-ui/core/MenuItem'
import { TooltipTypography } from './TooltipTypography'

const useStyles = makeStyles(theme => ({
  filterSpacer: {
    width: '1px',
    height: '24px',
    backgroundColor: theme.palette.common.pattensBlue,
  },
  filterButton: {
    justifyContent: 'flex-start',
    color: theme.palette.grayShades.gray2,
    fontWeight: 400,
    padding: 0,
    marginRight: theme.spacing(2),
  },
  buttonText: {
    maxWidth: '100px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  filterButtonLabelText: {
    marginRight: theme.spacing(1),
  },
}))

export const Filters = ({ onFilter, collection, initialFiltersConfig, label = 'Filters' }) => {
  const classes = useStyles()

  const [{ anchorEl, selectedFilterType, filtersConfig }, setState] = useSetState({
    anchorEl: null,
    selectedFilterType: null,
    filtersConfig: initialFiltersConfig,
  })

  const handleClick = event => {
    setState({ anchorEl: event.currentTarget })
  }

  const handleClose = () => setState({ anchorEl: null })

  const handleHover = filterType => () => {
    setState({ selectedFilterType: filterType })
  }

  const handleFilterClick = option => () => {
    const updatedFilter = {
      ...filtersConfig,
      [selectedFilterType]: { ...filtersConfig[selectedFilterType], value: option },
    }
    setState({
      filtersConfig: updatedFilter,
    })

    const filteredCollection = Object.keys(updatedFilter).reduce((prev, currentFilter) => {
      const filterValue = updatedFilter[currentFilter].value
      if (filterValue === 'All') {
        return prev
      }

      return prev.filter(item => updatedFilter[currentFilter].filterFn(item, filterValue)) || []
    }, collection)

    onFilter(filteredCollection)

    handleClose()
  }

  return (
    <>
      <Typography variant="body1">{label}</Typography>
      <Box className={classes.filterSpacer} mx={2} />

      {Object.keys(filtersConfig).map(filterType => (
        <Button
          key={filterType}
          variant="text"
          onClick={handleClick}
          className={classes.filterButton}
          onMouseEnter={handleHover(filterType)}
        >
          <Typography className={classes.filterButtonLabelText}>{filtersConfig[filterType].label}</Typography>
          <TooltipTypography className={classes.buttonText}>{filtersConfig[filterType].value}</TooltipTypography>
          <ArrowDropDown />
        </Button>
      ))}

      {selectedFilterType && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          elevation={1}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {filtersConfig[selectedFilterType].options.map(option => (
            <MenuItem key={option} onClick={handleFilterClick(option)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}

Filters.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  onFilter: PropTypes.func,
  initialFiltersConfig: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    filterOptions: PropTypes.arrayOf(PropTypes.string),
    filterFn: PropTypes.func,
  }),
}
