import React, { useCallback, useState } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import arrayMutators from 'final-form-arrays'
import { currency, isNumeric, requiredField } from 'validators'
import {
  FileInput,
  FormAutocomplete,
  FormCurrencyInput,
  FormDateInput,
  FormDropdown,
  FormRadioGroup,
  FormTextInput,
  TextInput,
} from 'components'
import { Form, useFormState } from 'react-final-form'
import { useQuery } from 'react-query'
import { getCompanies, getSecuritiesByCompany, getUploadUrl, uploadFile } from 'api'
import { SecurityList } from 'components/security/SecurityList'
import { AddSecurityInvestment } from '../security/AddSecurityInvestment'
import { INVESTMENT_TYPES, INVESTMENT_UPLOAD_TYPES, SECURITY_TYPES } from '../../constants'
import { SECURITY_TYPE_LABELS } from 'constants/security-type-labels'

export const InvestmentForm = ({
  onSubmit,
  company,
  className = '',
  classes = {},
  investors,
  selectedInvestor,
  disableCompanySelect,
  disableInvestorSelect,
  disableSecuritySelect,
  convertInvestmentForm = false,
  initialValues,
  investment = {},
  handleDirty = () => {},
}) => {
  const validate = values => {
    const errors = { securities: {} }

    const startDate = get(values, 'securities.exercise_start')
    const endDate = get(values, 'securities.exercise_end')

    if (startDate && endDate) {
      if (startDate >= endDate) {
        errors.securities.exercise_start = 'Start Date must be before End Date'
      }

      if (endDate <= startDate) {
        errors.securities.exercise_end = 'End Date must be after Start Date'
      }
    }

    return errors
  }

  const MemoizedForm = useCallback(
    () => (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
        validate={validate}
      >
        {props => {
          handleDirty(props.dirty)
          return (
            <FormComponent
              handleSubmit={props.handleSubmit}
              company={company}
              investors={investors}
              selectedInvestor={selectedInvestor}
              className={className}
              classes={classes}
              form={props.form}
              disableCompanySelect={disableCompanySelect}
              disableInvestorSelect={disableInvestorSelect}
              disableSecuritySelect={disableSecuritySelect}
              convertInvestmentForm={convertInvestmentForm}
              investment={investment}
            />
          )
        }}
      </Form>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return <MemoizedForm />
}
const FormComponent = ({
  handleSubmit,
  company,
  className,
  investors,
  classes,
  selectedInvestor,
  disableCompanySelect,
  disableInvestorSelect,
  disableSecuritySelect,
  convertInvestmentForm = false,
  investment = {},
  form,
}) => {
  const { values: formValues = {} } = useFormState({ subscription: { values: true } })
  const [convertAll, setConvertAll] = useState(false)

  const { data: companySecurities = [], isLoading } = useQuery(
    ['securities', formValues?.company_id],
    getSecuritiesByCompany,
    {
      enabled: formValues.company_id,
    }
  )

  const { data: companies = [] } = useQuery('companies', getCompanies)

  const selectedApplicableSchemes = get(formValues, 'applicable_schemes', [])
  const selectedSecurity = getSelectedSecurity(companySecurities, formValues)

  let investorsOptions = sortBy(investors, ['investor_name']).map(investor => investor.id)
  investorsOptions = ['', ...investorsOptions]

  const securityOptions = companySecurities.map(security => ({ name: security.name, value: security.id }))
  const companyOptions = companies.map(company => ({ name: company.name, value: company.id }))
  const investmentTypesOptions = Object.values(INVESTMENT_TYPES).map(value => ({ name: value, value: value }))

  const onFileSelect = async (files, field) => {
    const filesToUpload = []
    for (let index = 0; index < Array.from(files).length; index++) {
      const file = files[index]

      const fileFields = {
        file_type: file.type,
        file_name: file.name,
        document_type: field.toUpperCase(),
      }

      const urlData = await getUploadUrl(fileFields)

      await onFileUpload(urlData.uploadUrl, file)

      filesToUpload.push({
        index,
        file,
        ...fileFields,
        storage_url: removeURLParameters(urlData.uploadUrl),
      })
    }

    return filesToUpload
  }

  const onFileUpload = (...data) => uploadFile(...data)

  const cashReceivedOptions = [
    { name: 'Yes', value: 'Yes' },
    { name: 'No', value: 'No' },
    { name: 'Pending', value: 'Pending' },
  ]

  const applicableSchemesOptions = ['N/A', 'SEIS', 'EIS', 'IR', 'ER', 'VCT', 'EMI']

  const handleInvestmentSubmit = event => {
    event.preventDefault()
    handleSubmit(event)
  }

  const onConvertAllChange = event => {
    const { checked } = event.target

    if (checked) {
      form.change('securities.number_of_shares', getNumberOfShares(investment).toString(10))
      setConvertAll(true)
    }

    setConvertAll(checked)
  }

  const getInvestorOptionLabel = option => investors.find(investor => investor.id === option)?.investor_name || ''

  const filterOptions = (options, { inputValue, getOptionLabel }) =>
    options.filter(option => !!option).filter(option => getOptionLabel(option).startsWith(inputValue))

  const investorAutocompleteProps = {
    freeSolo: false,
    selectOnFocus: true,
    handleHomeEndKeys: true,
    openOnFocus: true,
    filterOptions,
  }

  return (
    <Grid>
      <form noValidate autoComplete="off" className={className} id="investment-form" onSubmit={handleInvestmentSubmit}>
        {convertInvestmentForm && (
          <Grid container direction="column" className={classes.convertInput}>
            <FormTextInput
              label="Number of Shares Converted"
              name="securities.number_of_shares"
              required
              disabled={convertAll}
              onChange={onNumberOfSharesOrSharePriceChange(form, formValues)}
              validators={[requiredField, isNumeric, validateConvertedShares(getNumberOfShares(investment))]}
            />

            <FormControlLabel
              className={classes.convertAll}
              label="Convert All Shares"
              control={
                <Checkbox
                  onChange={onConvertAllChange}
                  checked={convertAll}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
            />
          </Grid>
        )}

        {disableCompanySelect ? (
          <FormTextInput name="name" required label="Company" disabled value={(company && company.name) || ''} />
        ) : (
          <FormDropdown
            name="company_id"
            label="Company"
            options={companyOptions}
            validators={[requiredField]}
            required
          />
        )}
        {disableInvestorSelect ? (
          <TextInput
            name="beneficial_investor_id"
            required
            label="Beneficial Investor"
            hintText="Ultimate beneficiary of the investment."
            disabled
            value={(selectedInvestor && selectedInvestor.investor_name) || ''}
          />
        ) : (
          <FormAutocomplete
            label="Beneficial Investor"
            hintText="Ultimate beneficiary of the investment."
            name="beneficial_investor_id"
            options={investorsOptions}
            validators={[requiredField]}
            getOptionLabel={getInvestorOptionLabel}
            AutocompleteProps={investorAutocompleteProps}
            required
          />
        )}

        <FormAutocomplete
          label="Legal Investor"
          hintText="Legal name of the investor (this may differ from Beneficial Investor 
                      where the investment is made via a legal entity, for example)."
          name="legal_investor_id"
          options={investorsOptions}
          validators={[requiredField]}
          getOptionLabel={getInvestorOptionLabel}
          AutocompleteProps={investorAutocompleteProps}
          required
        />

        <FormAutocomplete
          label="Managed by"
          hintText="Where the investment is managed by a third party on behalf of the Beneficial Investor 
                      please add their name here."
          name="managed_by_id"
          options={investorsOptions}
          validators={[requiredField]}
          getOptionLabel={getInvestorOptionLabel}
          AutocompleteProps={investorAutocompleteProps}
          required
        />

        <FormDateInput name="investment_date" label="Date" required={true} validators={[requiredField]} />

        <div />
        {isLoading ? (
          <FormDropdown name="security_id_loading" label="Security Name" options={securityOptions} disabled />
        ) : (
          <FormDropdown
            name="security_id"
            label="Security Name"
            options={securityOptions}
            validators={[requiredField]}
            disabled={disableSecuritySelect || securityOptions?.length === 0}
            onChange={() => form.change('securities', {})}
            required
          />
        )}

        <TextInput
          name="security_type"
          label="Security Type"
          value={SECURITY_TYPE_LABELS[selectedSecurity?.type]}
          disabled
        />

        <SecurityList
          securityList={selectedSecurity ? [selectedSecurity] : []}
          showEmptyList={false}
          className={classes.securityList}
        />

        <AddSecurityInvestment
          type={selectedSecurity?.type}
          disableNumberOfShares={convertInvestmentForm}
          onSharesOrPriceChange={onNumberOfSharesOrSharePriceChange(form, formValues)}
          formValues={formValues}
        />

        <FormCurrencyInput
          label="Invested Capital, £"
          name="securities.invested_capital"
          validators={[requiredField, currency]}
          required
        />

        {layoutSpacer(selectedSecurity?.type)}

        <FormAutocomplete
          label="Applicable Investment Schemes"
          name="applicable_schemes"
          options={applicableSchemesOptions}
          required
          validators={[requiredField]}
        />
        <FormDropdown
          name="requested_schemes"
          label="Requested Investment Schemes"
          options={selectedApplicableSchemes.map(value => ({ name: value, value: value }))}
          required
          disabled={!selectedApplicableSchemes.length}
          validators={[requiredField]}
        />
        <FileInput
          multiple
          name="subscription_document"
          label={INVESTMENT_UPLOAD_TYPES['subscription_document'.toLocaleUpperCase()]}
          onFileSelect={onFileSelect}
          required
          validators={[requiredField]}
        />
        <FileInput
          multiple
          name="share_certificate"
          label={INVESTMENT_UPLOAD_TYPES['share_certificate'.toLocaleUpperCase()]}
          onFileSelect={onFileSelect}
        />

        <FileInput
          multiple
          name="scheme_paperwork"
          label={INVESTMENT_UPLOAD_TYPES['scheme_paperwork'.toLocaleUpperCase()]}
          onFileSelect={onFileSelect}
        />

        <span />

        <FormDropdown
          name="investment_type"
          label="Investment Type"
          options={investmentTypesOptions}
          required
          validators={[requiredField]}
        />
        <FormRadioGroup
          name="fees_due"
          label="Fees Due"
          required
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          validators={[requiredField]}
        />
        <FormDropdown
          name="cash_received"
          label="Cash Received"
          options={cashReceivedOptions}
          required
          validators={[requiredField]}
        />
      </form>
    </Grid>
  )
}

function getSelectedSecurity(companySecurities, formValues) {
  return companySecurities.find(security => security.id === get(formValues, 'security_id'))
}

function removeURLParameters(url) {
  const { origin, pathname } = new URL(url)

  return `${origin}${pathname}`
}

function getNumberOfShares(investment) {
  const { investment_security } = investment
  const { investment_security_values } = investment_security

  return investment_security_values.number_of_shares
}

const validateConvertedShares = maxValue => value => {
  if (maxValue && parseFloat(value) > parseFloat(maxValue)) {
    return `Number of Shares Converted must be less than or equal to ${maxValue}`
  }
  return undefined
}

const onNumberOfSharesOrSharePriceChange = (form, formValues) => event => {
  const investedCapitalKey = 'securities.invested_capital'
  const numberOfSharesKey = 'securities.number_of_shares'
  const priceKeys = ['securities.share_price', 'securities.option_price', 'securities.warrant_price']

  let value = event.target.value
  let key = event.target.name

  if (!value) {
    return
  }

  let investedCapital

  if (key === numberOfSharesKey) {
    const priceKey = priceKeys.find(key => get(formValues, key))

    if (!priceKey) {
      return
    }

    investedCapital = Number(value) * Number(get(formValues, priceKey))
  } else {
    const numberOfShares = get(formValues, numberOfSharesKey)

    if (!numberOfShares) {
      return
    }

    investedCapital = Number(value) * Number(numberOfShares)
  }

  form.change(investedCapitalKey, investedCapital)
}

function layoutSpacer(type) {
  if (type === SECURITY_TYPES.EQUITY || type === SECURITY_TYPES.CONVERTIBLE_DEBT) {
    return null
  }

  return <div />
}
