import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Typography } from '@material-ui/core'
import { FadeContent } from './FadeContent'

const useStyles = makeStyles(theme => ({
  emptyList: {
    height: '11em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: theme.customShadows.shadow1,
  },
}))

export const EmptyList = ({ text, children, className = '' }) => {
  const classes = useStyles()

  return (
    <FadeContent>
      <div className={`${classes.emptyList} ${className}`}>
        <Typography>{text}</Typography>
        {children}
      </div>
    </FadeContent>
  )
}
