import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { withStyles } from '@material-ui/core/styles'

import { paddedNumber } from 'utils/numberFormat'

const TOTAL_TITLE = 'Net Investment Cost'
const DIFF_TITLE = 'Change in Value'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    minHeight: '300px',
    width: '100%',
    height: '500px',
  },
  total: {
    flexGrow: 1,
  },
}))

const WhiteTextTypography = withStyles({
  root: {
    color: '#fff',
  },
})(Typography)

const CustomTooltip = props => {
  const classes = useStyles()

  if (props.id === 'secondary') {
    if (props.data.type === 'total') {
      return `${props.data.name}: £ ${paddedNumber(props.data.total_net_investment_cost)}`
    }

    if (props.data.type === 'current_value') {
      return `${props.data.name}: £ ${paddedNumber(props.data.total_value_of_holding)}`
    }

    return props.data.name
  }

  return (
    <div className={classes.tooltip}>
      <WhiteTextTypography variant="subtitle1">
        <strong>{props.data.name}</strong>
      </WhiteTextTypography>
      <WhiteTextTypography variant="body2">Value: £ {paddedNumber(Math.abs(props.value))}</WhiteTextTypography>
    </div>
  )
}

const BarChart = ({ data }) => (
  <ResponsiveBar
    data={data}
    keys={['value', 'secondary']}
    indexBy="name"
    margin={{ top: 10, right: 10, bottom: 10, left: 250 }}
    padding={0.3}
    layout="horizontal"
    colors={{ scheme: 'nivo' }}
    borderRadius={4}
    defs={[
      {
        id: 'lines',
        type: 'patternLines',
        background: '#fff',
        color: '#dde2e9',
        rotation: -45,
        lineWidth: 1,
        spacing: 3,
      },
      {
        id: 'negative',
        type: 'patternLines',
        background: '#e20065',
        color: '#f2579d',
        rotation: -45,
        lineWidth: 1,
        spacing: 3,
      },
      {
        id: 'positive',
        type: 'patternLines',
        background: '#8ac900',
        color: '#c8f564',
        rotation: -45,
        lineWidth: 1,
        spacing: 3,
      },
    ]}
    fill={[
      {
        match: d => {
          const isMainSerie = d.data.id !== 'secondary'
          const isCurrentValueSeries = d.data.data.type === 'current_value'
          const isPositive = Math.sign(d.data.value) === 1

          return isMainSerie && isCurrentValueSeries && isPositive
        },
        id: 'positive',
      },
      {
        match: d => d.data.id === 'secondary',
        id: 'lines',
      },
      {
        match: d => Math.sign(d.data.value) === -1,
        id: 'negative',
      },
      {
        match: d => Math.sign(d.data.value) === 1,
        id: 'positive',
      },
    ]}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={null}
    axisLeft={{
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendPosition: 'middle',
      legendOffset: -40,
      format: d => {
        const boldValues = [DIFF_TITLE, TOTAL_TITLE]
        return (
          <tspan
            style={{
              fontWeight: boldValues.includes(d) ? 'bold' : 'normal',
              fontFamily: 'inherit',
              fontSize: '14px',
            }}
            y={0}
          >
            {d}
          </tspan>
        )
      },
    }}
    markers={[
      {
        axis: 'x',
        value: 0,
        lineStyle: { stroke: 'rgba(221, 226, 233, .9)', strokeWidth: 1 },
      },
    ]}
    enableGridX={false}
    enableGridY={false}
    label={d => {
      if (d.id === 'secondary') {
        return ''
      }
      return `£ ${paddedNumber(Math.abs(d.value))}`
    }}
    tooltip={CustomTooltip}
    theme={{
      tooltip: {
        container: {
          background: '#000',
          color: '#fff',
        },
      },
    }}
    labelSkipWidth={40}
    labelSkipHeight={12}
    labelTextColor="black"
    legends={[]}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
)

const parseData = data => {
  const { total_net_investment_cost, total_value_of_holding, shareholdings = [] } = data
  const diff = total_value_of_holding - total_net_investment_cost
  const isPositive = Math.sign(diff) !== -1

  const currentValue = {
    name: DIFF_TITLE,
    type: 'current_value',
    value: diff,
    secondary: isPositive ? -1 * total_net_investment_cost : -1 * total_value_of_holding,
    total_net_investment_cost,
    total_value_of_holding,
  }

  const investedCapital = {
    name: TOTAL_TITLE,
    type: 'total',
    value: 0,
    secondary: -1 * total_net_investment_cost,
    total_net_investment_cost,
    total_value_of_holding,
  }

  const values = shareholdings.map(item => ({
    name: item.company_name,
    type: 'company',
    value: item.current_value_of_holdings - item.net_investment_cost,
    secondary: 0,
    total_net_investment_cost,
    total_value_of_holding,
  }))

  return [currentValue, ...values.reverse(), investedCapital]
}
export const CapitalReport = ({ data = {} }) => {
  const classes = useStyles()
  const values = parseData(data)

  return (
    <div className={classes.root}>
      <BarChart data={values} />
    </div>
  )
}
