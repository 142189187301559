import React from 'react'
import { BreadCrumbsHeader, Panel } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography, Fade } from '@material-ui/core'
import { changePassword as changePasswordApi, updateProfile as updateProfileApi } from 'api'
import { queryCache, useMutation } from 'react-query'
import { apiErrors } from 'utils/error'
import { ChangePassword } from './ChangePassword'
import { Edit } from '@material-ui/icons'
import { UpdateProfile } from './UpdateProfile'
import { useSetState } from 'react-use'
import { useAuth } from 'hooks/useAuth'
import { useHistory } from 'react-router-dom'
import { useToast } from 'hooks'

const useStyles = makeStyles(theme => ({
  profile: {
    width: '50%',
    margin: '0 auto',
  },
  password: {
    width: '50%',
    paddingTop: 0,
    margin: '0 auto',
  },
  label: {
    color: theme.palette.common.blueGray,
  },
  info: {
    color: theme.palette.common.dark,
  },
  buttonIcon: {
    fontSize: '16px',
    marginRight: theme.spacing(1),
  },
  profileInformation: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    padding: theme.spacing(3),
    gridColumnGap: theme.spacing(2),
  },
}))

export const Profile = () => {
  const classes = useStyles()
  const { profile, logout, loaded } = useAuth()
  const history = useHistory()

  const [{ openChangePassword, openUpdateProfile }, setState] = useSetState({
    openChangePassword: false,
    openUpdateProfile: false,
  })

  const [changePassword, { isLoading: changingPassword }] = useMutation(changePasswordApi, { throwOnError: true })
  const [updateProfile, { isLoading }] = useMutation(updateProfileApi, { throwOnError: true })

  const toastService = useToast()

  const handleUpdateProfileSubmit = async profileValues => {
    try {
      await updateProfile(profileValues)
      queryCache.invalidateQueries('profile')
      setState({ openUpdateProfile: false })
      toastService.showSuccessToast('Profile has been updated.')
    } catch (e) {
      toastService.showErrorToast(`Failed to update profile. ${e.message}`)
      return apiErrors(e)
    }
  }

  const handleUpdateProfileCancel = () => {
    setState({ openUpdateProfile: false })
  }

  const handleUpdateProfileClick = () => {
    setState({ openUpdateProfile: true })
  }

  const handleChangePassword = () => {
    setState({ openChangePassword: true })
  }

  const handleChangePasswordCancel = () => {
    setState({ openChangePassword: false })
  }

  const handleChangePasswordSubmit = async values => {
    try {
      await changePassword(values)
      setState({ openChangePassword: false })
      logout()
      history.replace('/auth/login')
      toastService.showSuccessToast('Password has been changed.')
    } catch (e) {
      toastService.showErrorToast(`Failed to change password. ${e.message}`)
      return apiErrors(e)
    }
  }

  if (!loaded) {
    return null
  }

  return (
    <>
      <BreadCrumbsHeader title="Profile Settings" hideBackButton />
      <Fade timeout={1000} in>
        <div>
          <Panel
            title="Personal Information"
            panelClass={classes.profile}
            headerChildren={
              <>
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.button}
                  onClick={handleUpdateProfileClick}
                >
                  <Edit className={classes.buttonIcon} />
                  Edit
                </Button>

                <UpdateProfile
                  open={openUpdateProfile}
                  isLoading={isLoading}
                  onCancel={handleUpdateProfileCancel}
                  onSubmit={handleUpdateProfileSubmit}
                />
              </>
            }
          >
            <div className={classes.profileInformation}>
              <div>
                <Typography variant="subtitle2" className={classes.label}>
                  First name
                </Typography>
                <div className={classes.dark}>{profile.first_name}</div>
              </div>

              <div>
                <Typography variant="subtitle2" className={classes.label}>
                  Last name
                </Typography>
                <div className={classes.dark}>{profile.last_name}</div>
              </div>

              <div>
                <Typography variant="subtitle2" className={classes.label}>
                  Email
                </Typography>
                <div className={classes.dark}>{profile.email}</div>
              </div>
            </div>
          </Panel>
        </div>
      </Fade>
      <Fade timeout={1000} in>
        <div>
          <Panel
            title="Password"
            panelClass={classes.password}
            headerChildren={
              <>
                <Button variant="outlined" color="secondary" className={classes.button} onClick={handleChangePassword}>
                  <Edit className={classes.buttonIcon} />
                  Change password
                </Button>

                <ChangePassword
                  open={openChangePassword}
                  onCancel={handleChangePasswordCancel}
                  onSubmit={handleChangePasswordSubmit}
                  isLoading={changingPassword}
                />
              </>
            }
          />
        </div>
      </Fade>
    </>
  )
}
