import React from 'react'

import { SECURITY_TYPES } from 'constants/security-types'
import { AddOptionInvestment } from './AddOptionInvestment'
import { AddWarrantsInvestment } from './AddWarrantsInvestment'
import { AddEquityInvestment } from './AddEquityInvestment'
import { AddConvertibleDebtInvestment } from './AddConvertibleDebtInvestment'

export const AddSecurityInvestment = ({ type, disableNumberOfShares, onSharesOrPriceChange, formValues }) => {
  const securityTypeView = type => {
    switch (type) {
      case SECURITY_TYPES.EQUITY:
        return (
          <AddEquityInvestment
            disableNumberOfShares={disableNumberOfShares}
            onSharesOrPriceChange={onSharesOrPriceChange}
          />
        )
      case SECURITY_TYPES.OPTION:
        return <AddOptionInvestment onSharesOrPriceChange={onSharesOrPriceChange} formValues={formValues} />
      case SECURITY_TYPES.WARRANT:
        return <AddWarrantsInvestment onSharesOrPriceChange={onSharesOrPriceChange} formValues={formValues} />
      case SECURITY_TYPES.CONVERTIBLE_DEBT:
        return <AddConvertibleDebtInvestment />
      default:
        return null
    }
  }

  return <>{securityTypeView(type)}</>
}
