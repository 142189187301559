import React, { useState } from 'react'
import { CompanyDetailsForm } from './CompanyDetailsForm'
import { Modal } from 'components/Modal'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  formClass: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(3),
    gridRowGap: theme.spacing(2),
  },
  fullWidth: {
    gridColumn: '1/3',
  },
  currentMarketPrice: {
    gridColumn: '1/2',
    gridRow: '2',
  },
  mostRecentPrice: {
    gridColumn: '2/3',
    gridRow: '2',
  },
  postMoneyValuation: {
    gridColumn: '1/2',
    gridRow: '3',
  },
  tradingAddress: {
    gridColumn: '1/3',
    gridRow: '3',
  },
}))

export const CompanyEdit = ({ company, handleClose, handleSubmit, isLoading = false }) => {
  const classes = useStyles()
  const [dirty, setDirty] = useState(false)

  return (
    <Modal
      open={!!company}
      modalTitle="Edit Company Details"
      modalContent={
        <CompanyDetailsForm
          company={company}
          handleDirty={isDirty => setDirty(isDirty)}
          classes={{
            formClass: classes.formClass,
            registeredAddress: classes.fullWidth,
            currentMarketPrice: classes.currentMarketPrice,
            mostRecentPrice: classes.mostRecentPrice,
            postMoneyValuation: classes.postMoneyValuation,
            tradingAddress: classes.tradingAddress,
            dropboxLink: classes.fullWidth,
            website: classes.fullWidth,
            companyHouseLink: classes.fullWidth,
            uploadLogo: classes.fullWidth,
            addToList: classes.fullWidth,
          }}
          onSubmit={handleSubmit}
        />
      }
      modalActions={
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button onClick={e => handleClose(e, dirty)} color="primary" variant="outlined" disabled={isLoading}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              form="company-details"
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
              disabled={isLoading || !dirty}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      }
      isLoading={isLoading}
    />
  )
}
