import React from 'react'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

import { theme } from './theme'

if (process.env.NODE_ENV === 'development') {
  MuiThemeProvider.displayName = 'MuiThemeProvider'
}

export const ThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
)
