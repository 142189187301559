import React from 'react'
import ReactDOM from 'react-dom'
import { Router as ReactRouter } from 'react-router-dom'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from 'providers/themeProvider/ThemeProvider'
import { AuthProvider } from 'providers/authProvider/AuthProvider'
import { ReactQueryConfigProvider } from 'providers/reactQueryConfigProvider/ReactQueryConfigProvider'
import { history } from 'utils/history'
import { ToastProvider } from 'providers/ToastProvider'

import './index.css'
import './api/firebase'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <ReactQueryConfigProvider>
        <ToastProvider>
          <AuthProvider>
            <ReactRouter history={history}>
              <App />
            </ReactRouter>
          </AuthProvider>
        </ToastProvider>
      </ReactQueryConfigProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
