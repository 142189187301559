import React from 'react'
import { FormTextInput } from 'components'
import { requiredField } from 'validators/requiredField'

export const SecurityRank = ({ className }) => (
  <FormTextInput
    required={true}
    className={className}
    label="Rank"
    hintText="Seniority of Security Type. With 1 being the most senior."
    type="number"
    name="rank"
    validators={[requiredField]}
    parse={value => parseInt(value)}
  />
)
