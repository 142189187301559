import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Box, Button, Container, Grid, Link, Menu, MenuItem, Typography } from '@material-ui/core'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import Add from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation, useQuery } from 'react-query'
import moment from 'moment'
import { useSetState } from 'react-use'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'

import { If, SearchBar, Spinner } from 'components'
import { InvestorList as List } from 'components/investor'
import { filterByFields } from 'utils/search'
import { getAllAffiliations, getInvestors } from 'api'
import { INVESTOR_TYPE } from 'constants/investor-type'

const useStyles = makeStyles(theme => ({
  link: {
    marginLeft: 'auto',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  flexContainer: {
    justifyContent: 'flex-end',
  },
  filterSpacer: {
    width: '1px',
    height: '24px',
    backgroundColor: theme.palette.common.pattensBlue,
  },
  filterButton: {
    justifyContent: 'flex-start',
    color: theme.palette.grayShades.gray2,
    fontWeight: 400,
  },
  filterButtonLabelText: {
    marginRight: theme.spacing(1),
  },
}))

const FILTER_TYPE = {
  INVESTOR_TYPE: 'INVESTOR_TYPE',
  AFFILIATION: 'AFFILIATION',
  YEAR: 'YEAR',
}

const INVESTOR_SEARCH_FIELDS = ['investor_name']

export const InvestorList = () => {
  let { url } = useRouteMatch()
  const classes = useStyles()
  const { data, isLoading } = useQuery('investors', getInvestors)

  const [loadAllAffiliations, { data: affiliationsData = [] }] = useMutation(getAllAffiliations)

  const affiliationNames = [...new Set(affiliationsData.map(affiliation => affiliation.affiliation_name))]

  const [
    { anchorEl, filteredInvestors, activeFilter, investorTypeFilter, affiliationFilter, yearFilter, options },
    setState,
  ] = useSetState({
    anchorEl: null,
    options: [],
    investorTypeFilter: 'All',
    affiliationFilter: 'All',
    yearFilter: 'All',
    activeFilter: FILTER_TYPE.INVESTOR_TYPE,
  })

  useEffect(() => setState({ filteredInvestors: data }), [data, setState])
  useEffect(() => {
    async function fetchAffiliations() {
      await loadAllAffiliations()
    }

    fetchAffiliations()
  }, [loadAllAffiliations])

  const filter = () => {
    const filters = {
      [FILTER_TYPE.INVESTOR_TYPE]: {
        filterFn: investor => investor.investor_type === investorTypeFilter,
        filterValue: investorTypeFilter,
      },
      [FILTER_TYPE.AFFILIATION]: {
        filterFn: investor => (investor.investor_affiliations || []).includes(affiliationFilter),
        filterValue: affiliationFilter,
      },
      [FILTER_TYPE.YEAR]: {
        filterFn: investor => moment(investor.updated_at).format('YYYY') === yearFilter,
        filterValue: yearFilter,
      },
    }

    return Object.keys(filters).reduce((prev, current) => {
      if (filters[current].filterValue === 'All') {
        return prev
      }
      return prev.filter(filters[current].filterFn) || []
    }, filteredInvestors)
  }

  const filterClick = filterType => value => {
    switch (filterType) {
      case FILTER_TYPE.INVESTOR_TYPE:
        setState({ investorTypeFilter: value })
        break
      case FILTER_TYPE.AFFILIATION:
        setState({ affiliationFilter: value })
        break
      case FILTER_TYPE.YEAR:
        setState({ yearFilter: value })
        break

      default:
        break
    }

    handleClose()
  }

  const handleClick = event => {
    setState({ anchorEl: event.currentTarget })
  }

  const handleHover = filterType => () => {
    let currentOptions

    switch (filterType) {
      case FILTER_TYPE.INVESTOR_TYPE:
        currentOptions = ['All', ...Object.values(INVESTOR_TYPE)]
        break
      case FILTER_TYPE.AFFILIATION:
        currentOptions = ['All', ...affiliationNames]
        break
      case FILTER_TYPE.YEAR:
        currentOptions = ['All', ...new Set(data.map(investor => moment(investor.updated_at).format('YYYY')).flat(1))]
        break
      default:
        break
    }

    setState({ activeFilter: filterType, options: currentOptions })
  }

  const handleClose = () => setState({ anchorEl: null })

  const onSearch = searchValue => {
    setState({
      filteredInvestors: filterByFields(INVESTOR_SEARCH_FIELDS)(data, searchValue),
    })
  }

  return (
    <>
      <Helmet>
        <title>New Model VC Dashboard - Investors</title>
      </Helmet>
      <div>
        <SearchBar onSearch={onSearch} header={<Typography variant="h6">Investors</Typography>}>
          <Link component={RouterLink} to={`${url}/create`} className={classes.link}>
            <Button color="primary" variant="contained" startIcon={<Add />}>
              Create Investor
            </Button>
          </Link>
        </SearchBar>
      </div>

      <Container maxWidth="xl">
        <If
          value={isLoading || !filteredInvestors}
          component={<Spinner color="secondary" size="40px" height="300px" centered />}
          elseComponent={
            <Box pr={5.5} pl={6}>
              <Grid container alignItems="center">
                <Grid container item xs={8} alignItems="center">
                  <Typography variant="body1">Filters</Typography>

                  <Box className={classes.filterSpacer} mx={2} />

                  <Button
                    variant="text"
                    className={classes.filterButton}
                    onClick={handleClick}
                    onMouseEnter={handleHover(FILTER_TYPE.INVESTOR_TYPE)}
                  >
                    <Typography className={classes.filterButtonLabelText}>Primary Affiliation:</Typography>
                    <Typography>{investorTypeFilter}</Typography>
                    <ArrowDropDown />
                  </Button>

                  <Button
                    variant="text"
                    className={classes.filterButton}
                    onClick={handleClick}
                    onMouseEnter={handleHover(FILTER_TYPE.AFFILIATION)}
                  >
                    <Typography className={classes.filterButtonLabelText}>Affiliation:</Typography>
                    <Typography>{affiliationFilter}</Typography>
                    <ArrowDropDown />
                  </Button>

                  <Button
                    variant="text"
                    className={classes.filterButton}
                    onClick={handleClick}
                    onMouseEnter={handleHover(FILTER_TYPE.YEAR)}
                  >
                    <Typography className={classes.filterButtonLabelText}>Year:</Typography>
                    <Typography>{yearFilter}</Typography>
                    <ArrowDropDown />
                  </Button>

                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    elevation={1}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  >
                    {options.map(option => (
                      <MenuItem key={option} onClick={() => filterClick(activeFilter)(option)}>
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>

              <div>
                <List investors={filter(filteredInvestors)} />
              </div>
            </Box>
          }
        />
      </Container>
    </>
  )
}
