import React from 'react'
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell } from '@material-ui/core'
import { ChangeLogRow } from './ChangeLogRow'
import { FadeContent } from '../FadeContent'

export const ChangeLog = ({ formattedChanges = [] }) => (
  <FadeContent>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Change Event</TableCell>
            <TableCell>Changed by</TableCell>
            <TableCell>Date of Change</TableCell>
            <TableCell>Approved by</TableCell>
            <TableCell>Approved at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formattedChanges.map(changes => (
            <ChangeLogRow key={changes.id} {...changes} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </FadeContent>
)
