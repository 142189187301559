import React from 'react'
import { AppHeader, RoleRoute } from 'components'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { CompanyCreate, CompanyList } from './pages/company'
import { InvestorCreate, InvestorList } from './pages/investor'
import { UserManagement } from 'pages/admin/userManagement/UserManagement'
import { ForgotPassword, Login, Signup, SignupSuccess, UpdatePassword } from './pages/auth'
import { useAuth } from 'hooks/useAuth'
import { UserProfile } from 'pages/profile'
import { CompanyDetailsPage } from 'pages/company'
import { InvestorDetailsPage } from 'pages/investor'
import { Approvals } from 'pages/approvals'
import { ADMIN_USERS } from 'constants/roles'

export const AppRouter = () => {
  const { isAuthenticated, role } = useAuth()
  const { pathname, search } = useLocation()

  const nextPath = new URLSearchParams(search).get('nextPath')

  return (
    <>
      <Switch>
        {isAuthenticated && (
          <>
            <AppHeader />

            <RoleRoute
              path="/companies"
              component={CompanyList}
              role={role}
              allowedRoles={['admin', 'superadmin', 'user']}
              exact
            />

            <RoleRoute
              path="/companies/details/:companyId"
              component={CompanyDetailsPage}
              role={role}
              allowedRoles={['admin', 'superadmin', 'user']}
            />

            <RoleRoute
              path="/companies/create"
              component={CompanyCreate}
              role={role}
              allowedRoles={['admin', 'superadmin', 'user']}
              exact
            />

            <RoleRoute
              path="/investors"
              component={InvestorList}
              role={role}
              allowedRoles={['admin', 'superadmin', 'user']}
              exact
            />

            <RoleRoute
              path="/investors/details/:investorId"
              component={InvestorDetailsPage}
              role={role}
              allowedRoles={['admin', 'superadmin', 'user']}
            />

            <RoleRoute
              path="/investors/create"
              component={InvestorCreate}
              role={role}
              allowedRoles={['admin', 'superadmin', 'user']}
            />

            <RoleRoute path="/approvals" component={Approvals} role={role} allowedRoles={ADMIN_USERS} />

            <RoleRoute
              path="/admin/user-management"
              component={UserManagement}
              role={role}
              allowedRoles={['admin', 'superadmin']}
            />

            <RoleRoute
              path="/user/profile"
              component={UserProfile}
              role={role}
              allowedRoles={['user', 'admin', 'superadmin']}
            />

            {pathname === '/auth/login' ? <Redirect to={nextPath || '/companies'} /> : null}

            {pathname === '/' ? <Redirect to="/companies" /> : null}
          </>
        )}

        {!isAuthenticated && (
          <>
            <Route path="/auth/login" exact component={Login} />

            <Route exact path="/auth/signup" component={Signup} />

            <Route path="/auth/signup/success" component={SignupSuccess} />

            <Route path="/auth/forgot" component={ForgotPassword} />

            <Route path="/auth/update-password" component={UpdatePassword} />
          </>
        )}
      </Switch>
    </>
  )
}
