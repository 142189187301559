import React from 'react'
import { Assignment } from '@material-ui/icons'
import { Grid, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { DocumentDetails } from 'components/detailsView'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.grayShades.gray3,
    marginRight: theme.spacing(0.5),
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  text: {
    color: theme.palette.common.slateGray,
  },
  link: {
    color: theme.palette.primary.main,
  },
}))

export const ViewDocuments = ({ documents = [] }) => {
  const classes = useStyles()

  return (
    <Grid className={classes.text} component="span">
      <Tooltip
        title={<DocumentDetails value={documents} linkClass={classes.link} emptyValue="No files." />}
        interactive
        arrow
      >
        <span>
          <Typography variant="subtitle1" component="span">
            <Assignment className={classes.icon} />
          </Typography>
          <span>View</span>
        </span>
      </Tooltip>
    </Grid>
  )
}
