import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { nameToLabelMapping, investorFields } from 'utils/investor'
import { DetailsValue } from 'components'
import Chip from '@material-ui/core/Chip'
import Fade from '@material-ui/core/Fade'

const useStyles = makeStyles(theme => ({
  investorDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridRowGap: theme.spacing(3),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  bio: {
    gridColumn: '1/4',
  },
}))

export const InvestorDetailsView = ({ investor }) => {
  const classes = useStyles()

  return (
    <Fade timeout={1000} in>
      <Box px={3} py={5} className={classes.investorDetails}>
        {investorFields.map(field => (
          <DetailsValue
            key={field}
            label={nameToLabelMapping[field]}
            value={investor[field]}
            renderValue={getFieldRender(field, classes)}
            className={getFieldClass(field, classes)}
          />
        ))}
      </Box>
    </Fade>
  )
}

function getFieldRender(field, classes) {
  return {
    investor_affiliations: values =>
      values.map(value => (
        <Chip key={value} label={value} color="primary" variant="default" className={classes.chip} />
      )),
  }[field]
}

function getFieldClass(field, classes) {
  return {
    bio: classes.bio,
  }[field]
}
