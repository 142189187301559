import firebase from 'firebase/app'

import { API } from './ApiClient'

const enableFBClient = !!process.env.REACT_APP_ENABLE_FB_CLIENT

export const serverLogin = config => API.post('/auth/login', { body: config })
const clientLogin = config => firebase.auth().signInWithEmailAndPassword(config.email, config.password)

export const login = enableFBClient ? clientLogin : serverLogin
export const logout = () => API.get('/auth/logout')
export const register = userData => API.post('/auth/register', { body: userData })
export const getProfile = () => API.get('/auth/profile')
export const updateProfile = body => API.post('/auth/profile', { body })
export const changePassword = body => API.post('/auth/change-password', { body })
export const getRefreshToken = () => API.post('/auth/refresh-token')
export const refreshToken = refresh_token => API.post('/auth/refresh_token', { body: { refresh_token } })
export const resetPassword = credentials => API.post('/auth/reset-password', { body: credentials })
export const updatePassword = credentials => API.post('/auth/update-password', { body: credentials })
