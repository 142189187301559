import React, { useRef } from 'react'
import { FormLabel, FormControl, FormHelperText, Typography } from '@material-ui/core'
import { useFieldArray } from 'react-final-form-arrays'
import { composeValidators } from 'validators/composeValidators'
import Button from '@material-ui/core/Button'
import Add from '@material-ui/icons/Add'
import Attachment from '@material-ui/icons/Attachment'
import Clear from '@material-ui/icons/Clear'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classnames from 'classnames'
import { useSetState } from 'react-use'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  fileInput: {
    display: 'block',
  },
  input: {
    display: 'none',
  },
  filesList: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  fileItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileName: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  fileNameText: {
    paddingLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
  },
  notUploadedFile: {
    fontStyle: 'italic',
  },
  button: {
    margin: '7px 0',
  },
  clearButton: {
    cursor: 'pointer',
  },
  loader: {
    color: 'inherit',
  },
}))

export const FileInput = ({
  multiple = true,
  buttonProps = {},
  name,
  className,
  label,
  onFileSelect,
  required,
  validators = [],
}) => {
  const [{ loading }, setState] = useSetState({
    loading: false,
  })

  const { fields = [], meta } = useFieldArray(name, {
    validate: composeValidators(...validators),
  })

  const showError = (meta.error || meta.submitError) && (meta.touched || meta.modified)

  const inputFileRef = useRef(null)
  const classes = useStyles()

  const onClick = () => inputFileRef.current.click()
  const onChange = async () => {
    setState({ loading: true })
    const filesData = await onFileSelect(inputFileRef.current.files, name)
    setState({
      loading: false,
    })

    filesData.forEach(file => {
      fields.push(file)
    })
  }

  const cleanFile = index => {
    fields.remove(index)
  }

  return (
    <div className={`${classes.fileInput} ${className}`}>
      <FormControl fullWidth component="fieldset">
        <FormLabel error={showError} component="legend" classes={{ root: classes.label }}>
          <Typography component="span" variant="body1">
            {label} {required ? '*' : ''}
          </Typography>
        </FormLabel>
        <Button
          color="primary"
          variant="outlined"
          startIcon={loading ? <CircularProgress size={24} className={classes.loader} /> : <Add />}
          className={classes.button}
          onClick={onClick}
          disabled={loading}
          {...buttonProps}
          fullWidth
        >
          {loading ? 'Loading...' : 'UPLOAD FILE'}
          <input
            accept="*"
            ref={inputFileRef}
            type="file"
            onChange={onChange}
            multiple={multiple}
            className={classes.input}
          />
        </Button>
        {showError && <FormHelperText error={showError}>{meta.error || meta.submitError}</FormHelperText>}
      </FormControl>

      <div className={classes.filesList}>
        {fields &&
          fields.value &&
          fields.value.map(f => (
            <div key={f.file_name} className={classes.fileItem}>
              <span className={classes.fileName}>
                <Attachment />
                <span className={classnames(classes.fileNameText)}>{f.file_name}</span>
              </span>
              <Clear className={classes.clearButton} onClick={() => cleanFile(f.index)} />
            </div>
          ))}
      </div>
    </div>
  )
}
