import React, { useState } from 'react'
import { TextTooltip } from './TextTooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export const TooltipTypography = ({ maxWidth = '100%', ...props }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const onMouseEnter = event => {
    const currentTarget = event.currentTarget
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setOpen(true)
    }
  }

  const onMouseLeave = () => setOpen(false)

  return (
    <TextTooltip arrow title={props.children} open={open}>
      <Typography
        {...props}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classes.text}
        style={{ maxWidth }}
      >
        {props.children}
      </Typography>
    </TextTooltip>
  )
}
