import React from 'react'
import { Helmet } from 'react-helmet'

import { Profile } from 'components/profile'

export const UserProfile = () => (
  <>
    <Helmet>
      <title>New Model VC Dashboard - Profile</title>
    </Helmet>
    <Profile />
  </>
)
