import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { InvestmentDetails } from './InvestmentDetails'
import { InvestmentSecurityDetails } from './InvestmentSecurityDetails'
import { mergeInvestmentGroup } from 'utils/changes'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  splitInvestmentDetails: {
    borderRadius: 0,
    borderRight: `2px solid ${theme.palette.grayShades.gray4}`,
  },
  transactionHeader: {
    padding: theme.spacing(1, 5),
    backgroundColor: theme.palette.grayShades.gray4,
  },
}))

export const InvestmentSplitDetails = ({ snapshot, changes }) => {
  const classes = useStyles()

  const { security, investmentChanges, newInvestment, newSecurity } = mergeInvestmentGroup({ snapshot, changes })

  return (
    <>
      <Grid container className={classes.transactionHeader}>
        <Typography variant="h4">Transaction 1</Typography>
      </Grid>
      <Grid container>
        <Grid item container xs={6} className={classes.splitInvestmentDetails}>
          <InvestmentDetails investment={snapshot} changes={investmentChanges?.changes} xs={4} />
        </Grid>
        <Grid item container xs={6}>
          <InvestmentSecurityDetails security={security} xs={4} />
        </Grid>
      </Grid>

      <Grid container className={classes.transactionHeader}>
        <Typography variant="h4">Transaction 2</Typography>
      </Grid>
      <Grid container>
        <Grid item container xs={6} className={classes.splitInvestmentDetails}>
          <InvestmentDetails investment={newInvestment} xs={4} />
        </Grid>
        <Grid item container xs={6}>
          <InvestmentSecurityDetails
            security={newSecurity}
            xs={4}
            splitSecurity={newInvestment.investment_status === 'Realised'}
          />
        </Grid>
      </Grid>
    </>
  )
}
