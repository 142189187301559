import React, { useState } from 'react'
import { FormDateInput, FormCurrencyInput } from 'components'
import { currency, isNumeric, maxValue, requiredField } from 'validators'
import { Checkbox, FormControlLabel } from '@material-ui/core'

export const RealiseEquity = ({ form, investment, classes }) => {
  const [realiseAll, setRealiseAll] = useState(false)
  const numberOfShares = Number(investment.investment_security.investment_security_values.number_of_shares)

  const onRealizeAllChange = event => setRealiseAll(event.target.checked)

  if (realiseAll) {
    setTimeout(() => form.change('shares_sold_amount', numberOfShares))
  }

  return (
    <>
      <FormDateInput label="Realisation Date" name="realisation_date" required validators={[requiredField]} />

      <FormCurrencyInput
        label="Realised Share Price"
        name="realised_share_price"
        required
        validators={[requiredField, currency]}
      />

      {realiseAll ? (
        <FormCurrencyInput label="Shares Sold" name="shares_sold_amount" required disabled />
      ) : (
        <FormCurrencyInput
          label="Shares Sold"
          name="shares_sold_amount"
          required
          validators={[requiredField, isNumeric, maxValue(numberOfShares, 'Shares Sold')]}
        />
      )}

      <FormControlLabel
        className={classes.realiseAll}
        label="Sell All Shares"
        control={
          <Checkbox
            onChange={onRealizeAllChange}
            checked={realiseAll}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        }
      />
    </>
  )
}
