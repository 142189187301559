import React, { createContext } from 'react'
import { useSetState } from 'react-use'
import { Toast } from 'components'

export const ToastContext = createContext()

export const ToastProvider = ({ children }) => {
  const [{ open, message, severity }, setState] = useSetState({
    open: false,
    severity: '',
    message: '',
    variant: '',
  })

  function showSuccessToast(message) {
    setState({ open: true, message, severity: 'success' })
  }

  function showInfoToast(message) {
    setState({ open: true, message, severity: 'info' })
  }

  function showErrorToast(message) {
    setState({ open: true, message, severity: 'error' })
  }

  function closeToast() {
    setState({ open: false })
  }

  const toastService = { showInfoToast, showSuccessToast, showErrorToast }

  return (
    <ToastContext.Provider value={toastService}>
      <>
        {children}
        <Toast open={open} message={message} severity={severity} closeToast={closeToast} />
      </>
    </ToastContext.Provider>
  )
}
