import React from 'react'
import { requiredField, currency, isNumeric } from 'validators'
import { FormCurrencyInput, FormTextArea, FormTextInput } from 'components'

export const AddEquityInvestment = ({ disableNumberOfShares, onSharesOrPriceChange }) => (
  <>
    <FormTextInput
      label="Number of shares"
      required
      name="securities.number_of_shares"
      validators={[requiredField, isNumeric]}
      disabled={disableNumberOfShares}
      onChange={onSharesOrPriceChange}
    />

    <FormCurrencyInput
      label="Share price, £"
      name="securities.share_price"
      validators={[requiredField, currency]}
      required
      onChange={onSharesOrPriceChange}
    />

    <FormTextArea label="Additional notes" name="securities.additional_notes" rows={1} />
  </>
)
