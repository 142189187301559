import React, { useEffect } from 'react'
import { useSetState } from 'react-use'
import Button from '@material-ui/core/Button'
import Add from '@material-ui/icons/Add'
import { Helmet } from 'react-helmet'

import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import { SearchBar } from 'components/SearchBar'
import { CompanyList as List } from 'components/company'
import { Typography, Link, Tabs, Tab, Box, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { EmptyList, If, Panel, Spinner } from 'components'
import { filterByFields } from 'utils/search'
import { useQuery } from 'react-query'
import { getCompanies, getFavoriteCompanies } from 'api'

const useStyles = makeStyles(theme => ({
  link: {
    marginLeft: 'auto',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  createLink: {
    marginTop: theme.spacing(2),
    '&:hover': {
      textDecoration: 'none',
    },
  },
  flexContainer: {
    justifyContent: 'flex-end',
  },
}))

export const CompanyList = () => {
  let { url } = useRouteMatch()
  const { data: companies, isLoading } = useQuery('companies', getCompanies)
  const { data: favoriteCompanies } = useQuery(['favoriteCompanies'], getFavoriteCompanies)

  const [{ selectedTab, filteredCompanies, myFilteredCompanies }, setState] = useSetState({
    selectedTab: 0,
    filteredCompanies: companies,
    myFilteredCompanies: favoriteCompanies,
  })

  useEffect(() => setState({ filteredCompanies: companies }), [companies, setState])
  useEffect(() => setState({ myFilteredCompanies: favoriteCompanies }), [favoriteCompanies, setState])

  const classes = useStyles()

  const onSearch = searchValue => {
    setState({ filteredCompanies: filterByFields(['name'])(companies, searchValue) })
    setState({ myFilteredCompanies: filterByFields(['name'])(favoriteCompanies, searchValue) })
  }

  const handleChange = (event, newValue) => setState({ selectedTab: newValue })

  if (isLoading || !myFilteredCompanies || !filteredCompanies) {
    return <Spinner color="secondary" size={40} centered height="500px" />
  }

  return (
    <>
      <Helmet>
        <title>New Model VC Dashboard - Companies</title>
      </Helmet>
      <div>
        <SearchBar onSearch={onSearch} header={<Typography variant="h6">Companies</Typography>}>
          <Link component={RouterLink} to={`${url}/create`} className={classes.link}>
            <Button color="primary" variant="contained" startIcon={<Add />}>
              Create Company
            </Button>
          </Link>
        </SearchBar>
      </div>
      <Container maxWidth="lg">
        <Box pr={5.5} pl={6}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            classes={{ flexContainer: classes.flexContainer }}
            component="div"
          >
            <Tab label="My Companies" color="primary" variant="contained" />
            <Tab label="All Companies" color="primary" variant="contained" />
          </Tabs>
          <If
            value={selectedTab === 1}
            component={
              <div>
                <If
                  value={filteredCompanies.length}
                  component={<List companies={filteredCompanies} />}
                  elseComponent={
                    <Panel>
                      <EmptyList text="No companies added yet.">
                        <Link component={RouterLink} to={`${url}/create`} className={classes.createLink}>
                          <Button color="primary" variant="outlined" startIcon={<Add />}>
                            Create Company
                          </Button>
                        </Link>
                      </EmptyList>
                    </Panel>
                  }
                />
              </div>
            }
          />

          <If
            value={selectedTab === 0}
            component={
              <div>
                <If
                  value={myFilteredCompanies.length}
                  component={<List companies={myFilteredCompanies} />}
                  elseComponent={
                    <Panel>
                      <EmptyList text="No companies added yet." />
                    </Panel>
                  }
                />
              </div>
            }
          />
        </Box>
      </Container>
    </>
  )
}
