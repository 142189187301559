export const COMPOUNDING_FREQUENCY = {
  BULLET: 'bullet',
  DAILY: 'daily',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  ANNUALLY: 'annually',
}

export const PAYMENT_FREQUENCIES = [
  {
    name: 'Bullet',
    value: COMPOUNDING_FREQUENCY.BULLET,
  },
  {
    name: 'Daily',
    value: COMPOUNDING_FREQUENCY.DAILY,
  },
  {
    name: 'Monthly',
    value: COMPOUNDING_FREQUENCY.MONTHLY,
  },
  {
    name: 'Quarterly',
    value: COMPOUNDING_FREQUENCY.QUARTERLY,
  },
  {
    name: 'Annually',
    value: COMPOUNDING_FREQUENCY.ANNUALLY,
  },
]
