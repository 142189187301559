import React from 'react'
import { useQuery } from 'react-query'
import { getInvestorChangeLog } from 'api'
import { Spinner } from 'components'
import { ChangeLog } from 'components/changeLog'
import { formatChanges } from 'utils/changes'

export const InvestorChangeLog = ({ investorId }) => {
  const { data, isLoading } = useQuery(['investorChangeLog', investorId], getInvestorChangeLog)

  if (isLoading || !data) {
    return <Spinner color="secondary" size={40} centered height="500px" />
  }

  const investorView = true
  return <ChangeLog formattedChanges={formatChanges(data, investorView)} />
}
