import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles, fade } from '@material-ui/core/styles'
import classnames from 'classnames'
import { TextTooltip } from '../TextTooltip'

const useStyles = makeStyles(theme => ({
  valueText: {
    color: theme.palette.common.dark,
    padding: theme.spacing(0, 1),
  },
  oldValue: {
    backgroundColor: fade(theme.palette.common.yellow, 0.4),
  },
  newValue: {
    backgroundColor: fade(theme.palette.green.main, 0.4),
  },
}))

export const FieldChangesDetails = ({
  label,
  value,
  className = '',
  renderValue = value => value,
  fieldClasses = {},
  ...props
}) => {
  const classes = useStyles()

  const oldValueClasses = classnames([classes.valueText, classes.oldValue, fieldClasses.oldValueClass])
  const newValueClasses = classnames([classes.valueText, classes.newValue, fieldClasses.newValueClass])

  const render = (value, key) => (value && value[key] ? renderValue(value[key]) : '-')

  return (
    <Grid container item direction="column" className={className} {...props}>
      <Box pt={3}>
        <Typography variant="subtitle1" color="textSecondary">
          {label}
        </Typography>

        <Grid container item>
          <Box pr={2}>
            <TextTooltip title="Old Value">
              <Typography variant="body1" className={oldValueClasses} component="div">
                {render(value, 'lhs')}
              </Typography>
            </TextTooltip>
          </Box>

          <TextTooltip title="New Value">
            <Typography variant="body1" className={newValueClasses} component="div">
              {render(value, 'rhs')}
            </Typography>
          </TextTooltip>
        </Grid>
      </Box>
    </Grid>
  )
}
