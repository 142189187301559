const CURRENCY_SEPARATOR = ','

const asInt = value =>
  parseInt(value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, CURRENCY_SEPARATOR)

export const decimal = value => currencyFloat(value)

export const currency = (value = '0.00') => {
  if (!value) {
    return '0.00'
  }

  return currencyFloat(value)
}

export const currencyFloat = (value = '0.00') => {
  if (!value) {
    return '0.00'
  }

  try {
    const floatValue = parseFloat(value)

    const [dec, frac] = [Math.trunc(floatValue), floatValue % 1]

    return asInt(dec) + frac.toFixed(2).slice(-3)
  } catch (e) {
    console.log(e)
    return 'Error in number format'
  }
}

export const percent = value => {
  if (!value) return (0).toFixed(1) + '%'

  if (value >= 0 && value <= 100) {
    return Number(value).toFixed(1) + '%'
  }

  return Math.trunc(value * 100) + '%'
}

export const paddedNumber = value => {
  if (!value) {
    return '0'
  }

  try {
    return asInt(value)
  } catch (e) {
    console.log(e)
    return 'Error in number format'
  }
}

export const roundTo2 = value => Math.round((value + Number.EPSILON) * 100) / 100
