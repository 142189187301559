import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SecurityList } from '../security/SecurityList'
import { Typography } from '@material-ui/core'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  list: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  singleSecurity: {
    marginBottom: 0,
  },
}))

export const CompanySecuritiesDetails = ({ securities }) => {
  const classes = useStyles()

  const securityClass = classnames({ [classes.singleSecurity]: securities.length < 2 })

  return (
    <div className={classes.securities}>
      {securities?.length > 1 && (
        <Typography variant="body1" color="textSecondary">
          Securities
        </Typography>
      )}

      <SecurityList
        securityList={securities}
        className={classes.list}
        securityClass={securityClass}
        emptyText="No Securities"
        showEmptyList={false}
      />
    </div>
  )
}
