import React from 'react'
import { mergeInvestmentGroup } from './mergeInvestmentGroup'
import { InvestmentDetails, InvestmentSecurityDetails, InvestmentSplitDetails } from 'components/detailsView'

export const getInvestmentChanges = ({ action, snapshot, changes = [], investorView = false }) => {
  const { security, investmentChanges } = mergeInvestmentGroup({ snapshot, changes })

  let description = ''

  let detailsView = null

  let descriptionAddition = investorView ? investmentChanges?.company?.name : investmentChanges?.investor?.investor_name
  if (descriptionAddition) {
    descriptionAddition = ` (${descriptionAddition})`
  }

  if (action === 'edit') {
    description = 'Investment details changed' + descriptionAddition

    detailsView = (
      <>
        <InvestmentDetails investment={snapshot} changes={investmentChanges?.changes} showChangedOnly />
        <InvestmentSecurityDetails security={security} showChangedOnly />
      </>
    )
  } else if (action === 'create') {
    description = 'New investment created' + descriptionAddition

    detailsView = (
      <>
        <InvestmentDetails investment={snapshot} changes={investmentChanges?.changes} />
        <InvestmentSecurityDetails security={security} />
      </>
    )
  } else if (action === 'split') {
    description = 'Split investment' + descriptionAddition

    detailsView = <InvestmentSplitDetails snapshot={snapshot} changes={changes} />
  }

  return { description, detailsView }
}
