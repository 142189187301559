import React from 'react'

import { fade, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  status: {
    padding: theme.spacing(0.5, 1),
    width: '60px',
    display: 'inline-flex',
    fontSize: '10px',
    justifyContent: 'center',
    borderRadius: '4px',
  },
  live: {
    color: theme.palette.secondary.main,
    backgroundColor: fade(theme.palette.secondary.main, 0.1),
  },
  canceled: {
    color: theme.palette.common.pink,
    backgroundColor: fade(theme.palette.common.pink, 0.1),
  },
  received: {
    color: theme.palette.common.wave,
    backgroundColor: fade(theme.palette.common.wave, 0.1),
  },
  realised: {
    color: theme.palette.common.orange,
    backgroundColor: fade(theme.palette.common.orange, 0.1),
  },
  changed: {
    backgroundColor: 'transparent',
  },
}))

export const StatusText = ({ status, text }) => {
  const classes = useStyles()

  const statusClass = status.toLowerCase()

  return <span className={`${classes.status} ${classes[statusClass]}`}>{text}</span>
}
