import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  valueText: {
    color: theme.palette.common.dark,
  },
}))

export const FieldDetails = ({ label, value, className = '', renderValue = value => value, ...props }) => {
  const classes = useStyles()

  return (
    <Grid container item direction="column" className={className} {...props}>
      <Box pt={3}>
        <Typography variant="subtitle1" color="textSecondary">
          {label}
        </Typography>

        <Typography variant="body1" className={classes.valueText} component="div">
          {value !== undefined ? renderValue(value) : '-'}
        </Typography>
      </Box>
    </Grid>
  )
}
