import React from 'react'
import { Form } from 'react-final-form'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { Alert } from '@material-ui/lab'

import { useAuth } from 'hooks/useAuth'
import { FormTextInput, If, Spinner } from 'components'
import { AuthPage } from './AuthPage'
import { requiredField, isEmail } from 'validators'

const useStyles = makeStyles(theme => ({
  loginForm: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.customShadows.shadow1,
    padding: theme.spacing(5.5, 12.5),
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  formElement: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  link: {
    marginBottom: theme.spacing(2),
  },
  spinner: {
    height: '24px',
  },
  signUpLink: {
    marginLeft: theme.spacing(0.5),
  },
  error: {
    width: '100%',
    gridColumn: '1/5',
    margin: '15px 0',
  },
}))

const LoginFormComponent = props => {
  const { handleSubmit, submitting, pristine, form, apiErrorMessage } = props
  const classes = useStyles()

  return (
    <form className={classes.loginForm} onSubmit={handleSubmit} noValidate>
      <Typography className={classes.title} align="center" variant="h6">
        Sign In
      </Typography>

      <FormTextInput
        className={classes.formElement}
        label="Email"
        name="email"
        placeholder="Email"
        validators={[requiredField, isEmail]}
        required
      />

      <FormTextInput
        placeholder="Password"
        label="Password"
        name="password"
        type="password"
        className={classes.formElement}
        validators={[requiredField]}
        required
      />

      <Button
        type="submit"
        fullWidth
        color="primary"
        variant="contained"
        className={classes.formElement}
        disabled={submitting || pristine}
      >
        <If
          value={submitting}
          component={<Spinner color="primary" size={24} className={classes.spinner} />}
          elseComponent="SIGN IN"
        />
      </Button>

      {!!apiErrorMessage && (
        <Alert severity="error" className={classes.error}>
          {apiErrorMessage}
        </Alert>
      )}

      <Typography variant="body2" className={classes.link}>
        Dont have an account?
        <Typography component="span" variant="subtitle2" color="textSecondary" className={classes.signUpLink}>
          <Link to="/auth/signup">Sign Up </Link>
        </Typography>
      </Typography>

      <Typography variant="subtitle2" color="textSecondary">
        <Link to="/auth/forgot">Forgot your password?</Link>
      </Typography>
    </form>
  )
}

export const LoginForm = () => {
  const { login, apiErrorMessage } = useAuth()
  const onSubmit = values => login(values)
  const renderForm = props => <LoginFormComponent {...props} apiErrorMessage={apiErrorMessage} />

  return (
    <AuthPage>
      <Form onSubmit={onSubmit} render={renderForm} />
    </AuthPage>
  )
}
