import React from 'react'
import Button from '@material-ui/core/Button'
import { FormDropdown, Modal, FormTextInput } from 'components'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import { Form } from 'react-final-form'

import { SECURITY_TYPES } from 'constants/security-types'
import { AddOption } from './AddOption'
import { AddEquity } from './AddEquity'
import { AddWarrant } from './AddWarrant'
import { AddDebt } from './AddDebt'
import { AddConvertibleDebt } from './AddConvertibleDebt'

import { requiredField } from 'validators/requiredField'
import { apiErrors } from 'utils/error'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles(theme => ({
  modal: {
    width: '50%',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(2),
  },
  spacer: {
    gridColumn: '1/3',
    height: '1px',
    backgroundColor: theme.palette.grayShades.gray4,
    margin: theme.spacing(3, 0, 4, 0),
  },
}))

const dropdownOptions = [
  {
    name: 'Convertible Debt (CLN)',
    value: SECURITY_TYPES.CONVERTIBLE_DEBT,
  },
  {
    name: 'Debt',
    value: SECURITY_TYPES.DEBT,
  },
  {
    name: 'Equity',
    value: SECURITY_TYPES.EQUITY,
  },
  {
    name: 'Option',
    value: SECURITY_TYPES.OPTION,
  },
  {
    name: 'Warrant',
    value: SECURITY_TYPES.WARRANT,
  },
]

export const AddSecurityModal = ({ securityList, addSecurity, open, handleClose }) => {
  const classes = useStyles()

  const onSubmit = async (values, form) => {
    try {
      let security = { ...values }

      if (values.type !== SECURITY_TYPES.EQUITY) {
        delete security.upside_ratio
        delete security.downside_ratio
      }

      await addSecurity(security)
      handleClose(form)
    } catch (e) {
      return apiErrors(e)
    }
  }

  const securityTypeView = (type, securityList = []) => {
    const equities = securityList.filter(security => security.type === SECURITY_TYPES.EQUITY)
    switch (type) {
      case SECURITY_TYPES.EQUITY:
        return <AddEquity />
      case SECURITY_TYPES.CONVERTIBLE_DEBT:
        return <AddConvertibleDebt />
      case SECURITY_TYPES.OPTION:
        return <AddOption securityList={equities} />
      case SECURITY_TYPES.DEBT:
        return <AddDebt />
      case SECURITY_TYPES.WARRANT:
        return <AddWarrant securityList={equities} />
      default:
        return null
    }
  }

  const handleTypeChange = form => event => {
    const value = event.target.value
    const name = form.getFieldState('name').value

    form.restart()
    form.reset()
    form.change('type', value)
    form.change('name', name)
  }

  return (
    <>
      <Modal
        modalClass={classes.modal}
        modalTitle="Add Security"
        open={open}
        modalContent={
          <Form
            initialValues={{ upside_ratio: 0, downside_ratio: 0 }}
            onSubmit={onSubmit}
            render={({ handleSubmit, values, form, submitError }) => (
              <form onSubmit={handleSubmit} className={classes.form} autoComplete="off" noValidate id="add-security">
                <FormTextInput name="name" label="Security Name" required={true} validators={[requiredField]} />

                <FormDropdown
                  name="type"
                  label="Security Type"
                  required={true}
                  validators={[requiredField]}
                  handleChange={handleTypeChange(form)}
                  options={dropdownOptions}
                />

                {values.type && (
                  <>
                    <div className={classes.spacer} />
                    {securityTypeView(values.type, securityList)}
                  </>
                )}

                <FormHelperText error={!!submitError}>{submitError}</FormHelperText>
              </form>
            )}
          />
        }
        modalActions={
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" form="add-security" color="primary" variant="contained" autoFocus>
                Add
              </Button>
            </Grid>
          </Grid>
        }
      />
    </>
  )
}
