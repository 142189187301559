import React from 'react'
import { InvestorDetails } from 'components/detailsView'
import { mergeInvestorGroup } from './mergeInvestorGroup'

export const getInvestorChanges = ({ action, snapshot, changes = [], investorView }) => {
  let description = ''
  let showChangedOnly = false
  let { investorChanges } = mergeInvestorGroup({ snapshot, changes })

  let descriptionAddition = investorView ? '' : ` (${snapshot.investor_name})`

  if (action === 'edit') {
    description = 'Investor details changed' + descriptionAddition
    showChangedOnly = true
  } else if (action === 'create') {
    description = 'New investor created' + descriptionAddition
  }

  const detailsView = (
    <InvestorDetails investor={snapshot} changes={investorChanges?.changes} showChangedOnly={showChangedOnly} />
  )

  return { description, detailsView }
}
