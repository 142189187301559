import { SECURITY_TYPES } from 'constants/security-types'

let commonFields = ['name', 'rank', 'invested_capital']

const commonEquityFields = ['voting', 'number_of_shares']

const EquityFields = [
  ...commonFields,
  ...commonEquityFields,
  'share_price',
  'upside_ratio',
  'downside_ratio',
  'additional_notes',
]

const OptionFields = [
  ...commonFields,
  ...commonEquityFields,
  'option_price',
  'exercise_start',
  'exercise_end',
  'exercise_price',
  'converts_to_security',
  'additional_notes',
]

const WarrantFields = [
  ...commonFields,
  ...commonEquityFields,
  'warrant_price',
  'preferential_rights',
  'exercise_start',
  'exercise_end',
  'exercise_price',
  'converts_to_security',
  'additional_notes',
]

const DebtFields = [
  ...commonFields,
  'interest_rate',
  'is_compounding',
  'compounding_frequency',
  'frequency_of_payments',
  'final_repayment_date',
  'security',
  'additional_notes',
]

const CLNFields = [
  ...commonFields,
  'voting',
  'interest_rate',
  'is_compounding',
  'compounding_frequency',
  'frequency_of_payments',
  'final_repayment_date',
  'discount',
  'max_share_price',
  'conversion_events',
  'additional_notes',
]

export const getAllSecurityFields = securityType => {
  switch (securityType) {
    case SECURITY_TYPES.EQUITY:
      return EquityFields

    case SECURITY_TYPES.OPTION:
      return OptionFields

    case SECURITY_TYPES.WARRANT:
      return WarrantFields

    case SECURITY_TYPES.DEBT:
      return DebtFields

    case SECURITY_TYPES.CONVERTIBLE_DEBT:
      return CLNFields

    default:
      return []
  }
}
