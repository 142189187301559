import React, { useState } from 'react'
import { FormDateInput, FormCurrencyInput } from 'components'
import { currency, maxValue, requiredField } from 'validators'
import { Checkbox, FormControlLabel } from '@material-ui/core'

// Realise form for CLN and Debt
export const RealiseDebtType = ({ form, investment, classes }) => {
  const [realiseAll, setRealiseAll] = useState(false)
  const investedCapital = Number(investment.investment_security.investment_security_values.invested_capital)

  const onRealizeAllChange = event => setRealiseAll(event.target.checked)

  if (realiseAll) {
    setTimeout(() => form.change('realised_capital', investedCapital))
  }

  return (
    <>
      <FormDateInput label="Realisation Date" name="realisation_date" required validators={[requiredField]} />

      {realiseAll ? (
        <FormCurrencyInput label="Realised Capital" name="realised_capital" required disabled />
      ) : (
        <FormCurrencyInput
          label="Realised Capital"
          name="realised_capital"
          required
          validators={[requiredField, currency, maxValue(investedCapital, 'Realised Capital')]}
        />
      )}

      <FormControlLabel
        className={classes.realiseAllDebt}
        label="Total amount"
        name={'realise_all'}
        control={
          <Checkbox
            onChange={onRealizeAllChange}
            checked={realiseAll}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        }
      />
    </>
  )
}
