import { authService } from 'services/AuthService'
import firebase from 'firebase/app'

const isFBClientEnabled = !!process.env.REACT_APP_ENABLE_FB_CLIENT
class ApiClient {
  constructor({ baseApiUrl }) {
    this.baseApiUrl = baseApiUrl
  }

  async request(url, { method = '', body, headers = {} } = {}, controller) {
    let token = authService.getToken()
    const mutatableMethods = ['post', 'put', 'delete', 'patch']
    let csrf

    if (mutatableMethods.includes(method.toLowerCase())) {
      const csrfData = await (await fetch(`${this.baseApiUrl}/csrf`, { method: 'GET', headers })).json()
      csrf = csrfData.csrfToken
    }

    if (isFBClientEnabled && firebase.auth().currentUser) {
      token = await firebase.auth().currentUser.getIdToken()
      authService.setToken(token)
    }

    const requestData = {
      method,
      mode: 'cors',
      signal: controller.signal,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
        Authorization: `Bearer ${token}`,
      },
      body,
    }

    if (csrf) {
      requestData.headers['CSRF-Token'] = csrf
    }

    const response = fetch(`${this.baseApiUrl}${url}`, requestData)

    const promise = response
      .then(async res => {
        if (!res.ok) {
          if (res.status === 304) {
            try {
              const text = await res.text()
              JSON.parse(text)
              return Promise.resolve(res)
            } catch (e) {
              return Promise.resolve({})
            }
          }

          if (res.status === 401) {
            authService.removeToken()
            if (!window.location.pathname.includes('auth')) {
              const { pathname, search } = window.location

              let href = '/auth/login'
              if (pathname && pathname !== '/auth/login') {
                const newPath = pathname + (search || '')
                href += `?nextPath=${encodeURIComponent(newPath)}`
              }

              window.location.href = href
            }
            return res
          }

          if (res.status === 403) {
            return Promise.reject(res)
          }

          if (res.status === 404) {
            return Promise.reject({ status: res.status, message: 'Resource not found' })
          }

          if (res.status === 429) {
            return Promise.reject({ status: res.status, message: 'Too many requests, please try again later' })
          }

          return Promise.reject(await res.json())
          // if (res.status >= 500 && res.status <= 508) {
          //   //window.location.href = '/502'
          //   return Promise.reject({ status: res.status, message: 'Internal server error' })
          // } else if (res.status === 404 || res.status === 403) {
          //   //window.location.href = '/404'
          //   return Promise.reject({ status: res.status, message: 'Page not found' })
          // } else if (res.status === 400) {
          //   return Promise.reject(await res.json())
          // } else if (res.status === 401) {
          //   authService.removeToken()
          //   window.location.href = '/auth/sign_in'
          //   return Promise.reject(await res.json())
          // } else {
          //   throw new Error(res.statusText)
          // }
        }
        return res.text().then(function (text) {
          return text ? JSON.parse(text) : {}
        })
      })
      .catch(e => {
        if (e.name !== 'AbortError') {
          throw e
        }
      })

    return promise
  }

  cancelable(url, params) {
    const controller = new AbortController()
    const promise = this.request(url, params, controller)
    promise.cancel = () => controller.abort()
    return promise
  }

  get(url, { headers = {} } = {}) {
    return this.cancelable(url, { method: 'GET', headers })
  }

  post(url, { body = {}, headers = {} } = {}) {
    return this.cancelable(url, { method: 'POST', headers, body: JSON.stringify(body) })
  }

  put(url, { body = {}, headers = {} } = {}) {
    return this.cancelable(url, { method: 'PUT', headers, body: JSON.stringify(body) })
  }

  patch(url, { body = {}, headers = {} } = {}) {
    return this.cancelable(url, { method: 'PATCH', headers, body: JSON.stringify(body) })
  }

  delete(url, { body = {}, headers = {} } = {}) {
    return this.cancelable(url, { method: 'DELETE', headers, body: JSON.stringify(body) })
  }
}

const API_URL = '/api/v1'

export const API = new ApiClient({
  baseApiUrl: API_URL,
})
