import React from 'react'
import { FormTextInput, TextInput } from 'components'

export const TextArea = ({
  name,
  label,
  required,
  placeholder = '',
  className = '',
  rows = 1,
  rowsMax = 10,
  ...props
}) => (
  <TextInput
    required={required}
    multiline
    label={label}
    type="text"
    name={name}
    className={className}
    rowsMax={rowsMax}
    rows={rows}
    placeholder={placeholder}
    {...props}
  />
)

export const FormTextArea = ({
  name,
  label,
  required,
  placeholder = '',
  className = '',
  rows = 1,
  rowsMax = 10,
  ...props
}) => (
  <FormTextInput
    required={required}
    multiline
    label={label}
    type="text"
    name={name}
    className={className}
    rowsMax={rowsMax}
    rows={rows}
    placeholder={placeholder}
    {...props}
  />
)
