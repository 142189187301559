import React from 'react'
import Paper from '@material-ui/core/Paper'
import classnames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import { DetailsValue, CurrencyValue } from 'components'

const useStyles = makeStyles(theme => ({
  realiseDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    backgroundColor: theme.palette.grayShades.gray5,
    borderBottom: 'none',
    boxShadow: 'none',
  },
}))

const renderRealiseCurrencyCell = value => <CurrencyValue value={value} justify="flex-start" />

export const RealiseDetails = ({ className = '', sharesCount = 0, realisePrice = 0, initialPrice = 0 }) => {
  const classes = useStyles()

  const realisedCapital = sharesCount * realisePrice
  const investedCapital = sharesCount * initialPrice

  return (
    <Paper elevation={1} className={classnames([classes.realiseDetails, className])}>
      <DetailsValue label="Shares Sold" value={sharesCount} />

      <DetailsValue
        label="Initial transaction share price"
        value={initialPrice}
        renderValue={renderRealiseCurrencyCell}
      />

      <DetailsValue label="Realised Capital" value={realisedCapital} renderValue={renderRealiseCurrencyCell} />

      <DetailsValue
        label="Invested capital relating to realisation"
        value={investedCapital}
        renderValue={renderRealiseCurrencyCell}
      />
    </Paper>
  )
}
