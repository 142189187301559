import React from 'react'
import { DetailsView } from 'components/detailsView'
import { nameToLabelMapping, normalizeCompanyChanges, companyFields, enrichCompany } from 'utils/company'

import { makeStyles } from '@material-ui/core/styles'
import { CompanySecuritiesDetails } from './CompanySecuritiesDetails'
import { Link } from '@material-ui/core'
import { currency } from 'utils/numberFormat'

const useStyles = makeStyles(() => ({
  securityChanges: {
    padding: 0,
  },
  link: {
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
  },
}))

export const CompanyDetails = ({
  company,
  changes = [],
  showChangedOnly = false,
  title = 'Company Details',
  panelClass = '',
  xs = 3,
}) => {
  const classes = useStyles()

  const companySnapshot = enrichCompany(company)

  const renderLink = url => (
    <Link href={url} target="_blank" rel="noreferrer" color="textPrimary" underline="always" className={classes.link}>
      {url}
    </Link>
  )

  const renderMappings = {
    security: value => (
      <CompanySecuritiesDetails
        securities={value}
        className={classes.securityChanges}
        showChangedOnly={showChangedOnly}
      />
    ),
    company_house_link: renderLink,
    dropbox_link: renderLink,
    website: renderLink,
    current_market_price: currency,
    most_recent_price: currency,
  }

  const changesMap = normalizeCompanyChanges(changes)

  return (
    <DetailsView
      title={title}
      fields={[...companyFields, 'security']}
      entity={companySnapshot}
      changesMap={changesMap}
      labelsMapping={nameToLabelMapping}
      renderMappings={renderMappings}
      showChangedOnly={showChangedOnly}
      panelClass={panelClass}
      fieldClasses={{
        security: classes.securityChanges,
      }}
      fieldsSize={{
        security: 12,
      }}
      xs={xs}
    />
  )
}
