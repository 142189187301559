const STORAGE_TOKEN = 'token'

class AuthService {
  constructor() {
    this.token = localStorage.getItem(STORAGE_TOKEN) || ''
  }

  isAuthenticated() {
    return this.token
  }

  removeToken() {
    localStorage.removeItem(STORAGE_TOKEN)
    this.token = ''
  }

  setToken(token) {
    this.token = token
    localStorage.setItem(STORAGE_TOKEN, token)
  }

  getToken() {
    return localStorage.getItem(STORAGE_TOKEN)
  }
}

export const authService = new AuthService()
