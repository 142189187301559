import React from 'react'
import { FormRadioGroup, If } from 'components'
import { STRAIGHT_OR_COMPOUNDING } from 'constants/straight-or-compounding'
import { PAYMENT_FREQUENCIES } from 'constants/compunding-frequency'
import { FormDropdown } from 'components/Dropdown'
import { useFormState } from 'react-final-form'
import { requiredField } from 'validators'

export const SecurityCompounding = ({ className }) => {
  const { values: formValues = {} } = useFormState({ subscription: { values: true } })

  return (
    <>
      <FormRadioGroup
        groupClass={className}
        required
        label="Straight or Compounding"
        name="is_compounding"
        options={[
          { label: 'Straight', value: STRAIGHT_OR_COMPOUNDING.STRAIGHT },
          { label: 'Compounding', value: STRAIGHT_OR_COMPOUNDING.COMPOUNDING },
        ]}
        validators={[requiredField]}
      />

      <If
        value={formValues.is_compounding}
        component={
          <FormDropdown
            className={className}
            label="Compounding Frequency"
            name="compounding_frequency"
            options={PAYMENT_FREQUENCIES}
            required
            validators={[requiredField]}
          />
        }
      />
    </>
  )
}
