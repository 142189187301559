import React from 'react'
import { FormTextInput, Modal } from 'components'
import { requiredField } from 'validators'
import { Form } from 'react-final-form'
import { Button, FormHelperText, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth } from 'hooks/useAuth'

const useStyles = makeStyles(() => ({
  modal: {
    width: '480px',
  },
  updateProfileForm: {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
  actions: {
    paddingTop: 0,
  },
}))

export const UpdateProfile = ({ open, onCancel, onSubmit, isLoading }) => {
  const classes = useStyles()
  const { profile } = useAuth()
  const { first_name, last_name } = profile

  return (
    <Modal
      open={open}
      modalClass={classes.modal}
      modalTitle="Change Personal Information"
      modalContent={
        <Form
          initialValues={{ first_name, last_name }}
          onSubmit={onSubmit}
          render={props => <UpdateProfileForm {...props} />}
        />
      }
      modalActionClass={classes.actions}
      modalActions={
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" type="submit" color="primary" form="update-profile" disabled={isLoading}>
              Save
            </Button>
          </Grid>
        </Grid>
      }
      isLoading={isLoading}
    />
  )
}

const UpdateProfileForm = ({ handleSubmit, form, submitError }) => {
  const classes = useStyles()

  const handleFormSubmit = e => {
    e.preventDefault()
    handleSubmit()
  }

  return (
    <form
      id="update-profile"
      onSubmit={handleFormSubmit}
      noValidate
      autoComplete="off"
      className={classes.updateProfileForm}
    >
      <FormTextInput name="first_name" label="First Name" required validators={[requiredField]} />
      <FormTextInput name="last_name" label="Last Name" required validators={[requiredField]} />

      <FormHelperText error={!!submitError}>{submitError}</FormHelperText>
    </form>
  )
}
