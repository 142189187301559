import React, { useState } from 'react'
import { NavLink, useParams, useRouteMatch, useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { queryCache, useQuery } from 'react-query'
import Button from '@material-ui/core/Button'
import Add from '@material-ui/icons/Add'
import Box from '@material-ui/core/Box'
import { Helmet } from 'react-helmet'

import { BreadCrumbsHeader } from 'components/BreadcrumbHeader'
import { getCompany, getCompanyInvestments } from 'api'
import { Spinner } from 'components/Spinner'
import { InvestmentCreate } from 'components/investment'
import { Container, fade } from '@material-ui/core'
import { CompanyDetailsRouter } from './CompanyDetailsRouter'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.common.blueGray,
    textDecoration: 'none',
    fontWeight: 500,
    marginRight: theme.spacing(7.5),
  },
  activeLink: {
    color: theme.palette.common.dark,
  },
  linksBorder: {
    borderBottom: `1px solid ${fade(theme.palette.grayShades.gray3, 0.5)}`,
  },
}))

export const CompanyDetails = () => {
  const classes = useStyles()
  let { companyId } = useParams()
  const [openCreateInvestmentModal, setOpenCreateInvestmentModal] = useState(false)
  let { url } = useRouteMatch()
  const history = useHistory()

  if (!parseInt(companyId, 10)) {
    history.push('/investors')
  }

  const { data: company = {}, isLoading, error: companyError } = useQuery(['company', companyId], getCompany)
  const { data: companyInvestments = [] } = useQuery(['companyInvestments', companyId], getCompanyInvestments, {
    enabled: companyId,
  })

  if (companyError) {
    // TODO ADD ERROR TOAST
    if (companyError.status === 404) {
      history.push('/companies')
    }
  }

  const afterCreate = () => {
    queryCache.invalidateQueries('companyInvestments')
  }

  if (isLoading) {
    return <Spinner color="secondary" size={40} height="300px" centered />
  }

  return (
    <>
      <Helmet>
        <title>New Model VC Dashboard - Company Details</title>
      </Helmet>
      <Grid>
        <BreadCrumbsHeader section="Companies" subsection="Company Details" title={company.name} backPath="/companies">
          <Box ml="auto">
            <Button
              variant="contained"
              startIcon={<Add />}
              color="primary"
              onClick={() => setOpenCreateInvestmentModal(true)}
            >
              Add Investment
            </Button>

            <InvestmentCreate
              open={openCreateInvestmentModal}
              handleCancel={() => setOpenCreateInvestmentModal(false)}
              company={company}
              disableCompanySelect
              nextUrl={`${url}/pending-transaction-report`}
              afterCreate={afterCreate}
            />
          </Box>
        </BreadCrumbsHeader>

        <Container maxWidth="xl" disableGutters>
          <Box px={6} pt={7} pb={12}>
            <Box width="100%" mb={6} className={classes.linksBorder} pb={4}>
              <NavLink to={`${url}/overview`} className={classes.link} activeClassName={classes.activeLink}>
                Overview
              </NavLink>

              <NavLink to={`${url}/share-cap-table`} className={classes.link} activeClassName={classes.activeLink}>
                Share Cap Table
              </NavLink>

              <NavLink to={`${url}/debt`} className={classes.link} activeClassName={classes.activeLink}>
                Debt Table
              </NavLink>

              <NavLink to={`${url}/transaction-report`} className={classes.link} activeClassName={classes.activeLink}>
                Transaction Report
              </NavLink>

              <NavLink
                to={`${url}/pending-transaction-report`}
                className={classes.link}
                activeClassName={classes.activeLink}
              >
                New Pending Approval
              </NavLink>

              <NavLink to={`${url}/change-log`} className={classes.link} activeClassName={classes.activeLink}>
                Change Log
              </NavLink>
            </Box>

            <CompanyDetailsRouter company={company} companyInvestments={companyInvestments} />
          </Box>
        </Container>
      </Grid>
    </>
  )
}
