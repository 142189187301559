import { useSetState } from 'react-use'

export const useSorting = ({ getAccessor, items, initialField, initialDirection = 'asc' }) => {
  const [{ field, direction }, setState] = useSetState({
    field: initialField,
    direction: initialDirection,
  })

  function sort(items, field, direction) {
    let comparator = fieldComparator(getAccessor(field), direction)

    return items.sort(comparator)
  }

  const changeSorting = field => () => {
    const newDirection = direction === 'asc' ? 'desc' : 'asc'

    setState({ field, direction: newDirection })
  }

  return [{ field, direction, sortedItems: sort(items, field, direction), sort }, changeSorting]
}

function fieldComparator(getFieldFn, direction) {
  return (a, b) => {
    if (getFieldFn(a) === getFieldFn(b)) return 0
    if (getFieldFn(a) < getFieldFn(b)) return direction === 'asc' ? -1 : 1
    return direction === 'asc' ? 1 : -1
  }
}
