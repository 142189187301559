import { useEffect, useRef } from 'react'

export const useScroll = shouldScroll => {
  const ref = useRef(null)

  useEffect(() => {
    if (shouldScroll && ref.current) {
      ref.current.scrollIntoView()
    }
  }, [shouldScroll])

  return ref
}
