import React, { useState } from 'react'
import { Modal } from 'components'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { InvestmentForm } from './InvestmentForm'
import { useMutation, useQuery } from 'react-query'
import { getInvestors, updateInvestment as updateInvestmentApi } from 'api'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { apiErrors } from 'utils/error'
import { splitAttachments } from 'utils/investment'
import { SECURITY_TYPE_LABELS } from 'constants/security-type-labels'
import { useToast } from 'hooks'

const useStyles = makeStyles(theme => ({
  modalContentClass: {
    margin: 0,
    paddingBottom: 0,
  },
  createInvestmentForm: {
    padding: theme.spacing(0),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
  },
  investmentDate: {
    gridColumn: '1/4',
  },
  investmentCapital: {
    gridColumn: '2/4',
  },
  securityList: {
    gridColumn: '1/3',
    padding: 0,
  },
}))

export const InvestmentEdit = ({
  investment,
  open,
  onClose,
  company,
  disableInvestorSelect,
  disableCompanySelect,
  disableSecuritySelect,
  onAfterUpdate = () => {},
}) => {
  const classes = useStyles()

  const { data: investors = [] } = useQuery('investors', getInvestors)

  const [updateInvestment, { isLoading }] = useMutation(updateInvestmentApi, { throwOnError: true })

  const toastService = useToast()

  const [dirty, setDirty] = useState(false)

  const initialValues = {
    name: company?.name,
    company_id: company?.id,
    ...investment,
    status: investment.status.name,
    security_id: investment.investment_security?.security?.id,
    security_type: SECURITY_TYPE_LABELS[investment.investment_security?.security?.type],
    securities: { ...investment.investment_security.investment_security_values },
    applicable_schemes: investment.applicable_schemas,
    requested_schemes: investment.requested_schemas,
    beneficial_investor_id: investment.investor.id,
    legal_investor_id: investment?.legal_investor?.id,
    managed_by_id: investment?.managed_by?.id,
    ...splitAttachments(investment.attachments),
  }

  const handleInvestmentUpdate = async ({
    share_certificate = '',
    subscription_document = '',
    scheme_paperwork = '',
    ...data
  }) => {
    const investorId = data.beneficial_investor_id || investment.investor.id

    const values = {
      id: investment.id,
      company_id: company?.id || investment.company_id,
      investor_id: investorId,
      investment_date: data.investment_date,
      applicable_schemas: data.applicable_schemes,
      requested_schemas: data.requested_schemes,
      cash_received: data.cash_received,
      security_id: data.security_id,
      investment_security: data.securities,
      fees_due: data.fees_due,
      investment_type: data.investment_type,
      investment_status: data.investment_status,
      legal_investor_id: data.legal_investor_id || investorId,
      managed_by_id: data.managed_by_id || investorId,
      attachments: [...share_certificate, ...subscription_document, ...scheme_paperwork].map(
        ({ storage_url, file_name, file_type, document_type }) => ({
          storage_url,
          file_name,
          file_type,
          document_type,
        })
      ),
    }
    try {
      await updateInvestment(values)
      onClose()
      onAfterUpdate()
      toastService.showSuccessToast(`Investment updated successfully.`)
    } catch (e) {
      toastService.showErrorToast(`Failed to update investment. ${e.message}`)
      return apiErrors(e)
    }
  }

  return (
    <Modal
      open={open}
      modalTitle="Edit Investment"
      modalContentClass={classes.modalContentClass}
      modalContent={
        <InvestmentForm
          company={company}
          initialValues={initialValues}
          disableCompanySelect={disableCompanySelect}
          disableInvestorSelect={disableInvestorSelect}
          disableSecuritySelect={disableSecuritySelect}
          onSubmit={handleInvestmentUpdate}
          investors={investors}
          selectedInvestor={investment.investor}
          className={classes.createInvestmentForm}
          classes={{
            investmentDate: classes.investmentDate,
            investmentCapital: classes.investmentCapital,
            securityList: classes.securityList,
          }}
          handleDirty={isDirty => setDirty(isDirty)}
        />
      }
      modalActions={
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button onClick={onClose} color="primary" variant="outlined" disabled={isLoading}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              form="investment-form"
              type="submit"
              color="primary"
              variant="contained"
              disabled={isLoading || !dirty}
              autoFocus
            >
              Save
            </Button>
          </Grid>
        </Grid>
      }
      isLoading={isLoading}
    />
  )
}
