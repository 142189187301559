import React from 'react'
import { ConfirmationDialog, Panel, Spinner } from 'components'
import { IconButton } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { InvestorEdit } from '../InvestorEdit'
import { apiErrors } from 'utils/error'
import { InvestorDetailsView } from './InvestorDetailsView'

import { makeStyles } from '@material-ui/core/styles'
import { InvestorCompanies } from './InvestorCompanies'
import { queryCache, useMutation } from 'react-query'
import { updateInvestor as updateInvestorApi, getInvestorCompanies } from 'api'
import { useSetState } from 'react-use'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useToast } from 'hooks'

const useStyles = makeStyles(() => ({
  overview: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

export const InvestorOverview = ({ investor }) => {
  let { investorId } = useParams()
  const [{ open, openConfirmationModal }, setState] = useSetState({ open: false, openConfirmationModal: false })
  const classes = useStyles()
  const [updateInvestor, { isLoading: updatingInvestor }] = useMutation(updateInvestorApi, { throwOnError: true })
  const { data: companies = [], isLoading } = useQuery(['investorCompanies', investorId], getInvestorCompanies)
  const toastService = useToast()

  const openModal = () => setState({ open: true })
  const closeModal = (event, dirty) => {
    if (dirty) {
      setState({ openConfirmationModal: true })
    } else {
      setState({ open: false })
    }
  }
  const cancel = () => setState({ openConfirmationModal: false })
  const handleAccept = () => setState({ openConfirmationModal: false, open: false })

  const handleInvestorUpdate = async investor => {
    try {
      await updateInvestor(investor)
      closeModal()
      queryCache.invalidateQueries('investor')
      toastService.showSuccessToast(`Investor ${investor.investor_name} updated successfully.`)
    } catch (e) {
      toastService.showErrorToast(`Failed to update investor ${investor.investor_name}. ${e.message}`)
      return apiErrors(e)
    }
  }

  if (isLoading) {
    return <Spinner color="secondary" size={40} height="300px" centered />
  }

  return (
    <div id="investor-to-pdf">
      <Panel
        disableGutters
        panelClass={classes.overview}
        title="Investor Details"
        buttonLabel="Edit Investor Details"
        button={
          <IconButton color="primary" variant="outlined" onClick={openModal}>
            <Edit />
          </IconButton>
        }
      >
        <InvestorDetailsView investor={investor} />

        <InvestorEdit
          open={open}
          handleCancel={closeModal}
          investor={investor}
          handleSubmit={handleInvestorUpdate}
          isLoading={updatingInvestor}
        />

        <ConfirmationDialog
          open={openConfirmationModal}
          title="Cancel Investor Editing"
          onCancel={cancel}
          onAccept={handleAccept}
          content={`You are going to cancel ${investor.investor_name} editing. All the filed information will be lost.`}
        />
      </Panel>

      <InvestorCompanies companies={companies} />
    </div>
  )
}
