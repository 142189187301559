import React, { useState } from 'react'
import { AuthPage } from './AuthPage'
import { Form } from 'react-final-form'
import { Typography, Button, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormTextInput } from 'components'
import { minLength, requiredField } from 'validators'
import { useMutation } from 'react-query'
import { updatePassword as updatePasswordApi } from 'api'
import { useLocation, Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { useToast } from 'hooks'
import { apiErrors } from 'utils/error'

const useStyles = makeStyles(theme => ({
  form: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(5.5, 12.5),
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(5),
  },
  input: {
    marginBottom: theme.spacing(3),
  },
}))
export const UpdatePasswordForm = () => {
  let location = useLocation()
  const classes = useStyles()
  const [paramsError, setParamsError] = useState('')

  const [updatePassword, { isLoading, isError, error, data }] = useMutation(updatePasswordApi)

  const toastService = useToast()

  const onSubmit = async values => {
    const searchValues = queryString.parse(location.search)

    if (!searchValues.mode || !searchValues.oobCode) {
      return setParamsError('Malformed link')
    }

    const resetPasswordPayload = {
      ...values,
      mode: searchValues.mode,
      oobCode: searchValues.oobCode,
    }

    try {
      await updatePassword(resetPasswordPayload)
      toastService.showSuccessToast('Password has been updated.')
    } catch (e) {
      toastService.showErrorToast(`Failed to update the password. ${e.message}`)
      return apiErrors(e)
    }
  }

  if (!isError && data) {
    return <Redirect to="/auth/login" />
  }

  let message = isError && error.message

  return (
    <AuthPage>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, submitting }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Typography className={classes.title} variant="h6" align="center">
              Enter new password
            </Typography>

            <FormTextInput
              className={classes.input}
              label="New Password"
              name="newPassword"
              type="password"
              validators={[requiredField, minLength(6)]}
              required
            />

            <Button
              color="primary"
              disableElevation
              variant="contained"
              className={classes.button}
              disabled={pristine || submitting || isLoading}
              type="submit"
            >
              Update Password
            </Button>

            <FormHelperText error={isError || !!paramsError}>{message || paramsError}</FormHelperText>
          </form>
        )}
      />
    </AuthPage>
  )
}
