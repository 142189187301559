import React from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  spinner: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const Spinner = ({ className, color = 'primary', size = '24px', centered = false, height = size }) => {
  const classes = useStyles()

  const boxClass = centered ? `${classes.spinner} ${className}}` : className
  const boxHeight = height === size ? size : height

  return (
    <Box className={boxClass} height={boxHeight}>
      <CircularProgress color={color} size={size} />
    </Box>
  )
}
