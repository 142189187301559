import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Modal } from 'components/Modal'
import { InvestorForm } from './InvestorForm'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  modalContentClass: {
    margin: 0,
    paddingBottom: 0,
  },
  editInvestorForm: {
    padding: theme.spacing(0),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
  },
  fullWidthField: {
    gridColumn: '1/3',
  },
  affiliations: {
    marginBottom: theme.spacing(2),
  },
  investor_type: {
    gridColumn: '1',
    gridRow: '5',
  },
}))

export const InvestorEdit = ({ investor, open, handleCancel, handleSubmit, isLoading }) => {
  const classes = useStyles()
  const [dirty, setDirty] = useState(false)

  const onSubmit = values => {
    const { introducer, ...rest } = values
    handleSubmit({ ...rest, introducer_name: introducer })
  }

  return (
    <Modal
      modalTitle="Edit Investor Details"
      open={open}
      modalContentClass={classes.modalContentClass}
      modalContent={
        <InvestorForm
          investor={investor}
          onSubmit={onSubmit}
          className={classes.editInvestorForm}
          handleDirty={isDirty => setDirty(isDirty)}
          classes={{
            investor_name: classes.fullWidthField,
            introducer_name: classes.fullWidthField,
            address: classes.fullWidthField,
            affiliations: `${classes.fullWidthField} ${classes.affiliations}`,
            bio: classes.fullWidthField,
            investor_type: classes.investor_type,
          }}
        />
      }
      modalActions={
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button
              onClick={event => handleCancel(event, dirty)}
              color="primary"
              variant="outlined"
              disabled={isLoading}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              form="investor-form"
              type="submit"
              color="primary"
              variant="contained"
              autoFocus
              disabled={isLoading || !dirty}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      }
      isLoading={isLoading}
    />
  )
}
