import React from 'react'
import Paper from '@material-ui/core/Paper'
import { Grid, Typography } from '@material-ui/core'
import { FieldDetails } from './FieldDetails'
import { makeStyles } from '@material-ui/core/styles'
import { FieldChangesDetails } from './FieldChangesDetails'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  details: {
    borderBottom: `1px solid ${theme.palette.grayShades.gray4}`,
    '&:last-child': {
      borderBottom: 'none',
    },
    padding: theme.spacing(3, 5),
    width: '100%',
  },
}))

export const DetailsView = ({
  title,
  fields,
  entity,
  labelsMapping,
  changesMap = {},
  renderMappings = {},
  showChangedOnly = false,
  fieldClasses = {},
  fieldsSize = {},
  panelClass = '',
  xs = 3,
}) => {
  const classes = useStyles()

  const hasChanges = (field, changesMap) => {
    const value = changesMap[field]
    if (!value) {
      return
    }

    const { lhs, rhs } = value

    // Check length for array fields (e.g. applicable schemas)
    if (Array.isArray(lhs) || Array.isArray(rhs)) {
      return lhs.length + rhs.length
    }

    return lhs || rhs
  }

  const detailsClass = classnames([classes.details, panelClass])

  return (
    <Paper elevation={0} className={detailsClass}>
      <Typography variant="body1" color="textSecondary">
        {title}
      </Typography>

      <Grid container>
        {fields.map(field => {
          if (hasChanges(field, changesMap)) {
            return (
              <FieldChangesDetails
                key={field}
                value={changesMap[field]}
                label={labelsMapping[field]}
                renderValue={renderMappings[field]}
                fieldClasses={fieldClasses[field]}
                xs={fieldsSize[field] || xs}
              />
            )
          }

          if (showChangedOnly) return null

          return (
            <FieldDetails
              key={field}
              value={entity[field]}
              label={labelsMapping[field]}
              renderValue={renderMappings[field]}
              xs={fieldsSize[field] || xs}
            />
          )
        })}
      </Grid>
    </Paper>
  )
}
