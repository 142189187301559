import React from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import { paddedNumber } from 'utils/numberFormat'
import { TextTooltip } from './TextTooltip'

export const CurrencyValue = ({ value, tooltipTitle = '' }) => {
  const content = <span>{paddedNumber(value)}</span>
  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid item>
        <Box mr={1}>
          <Typography variant="body1" color="textPrimary" component="span">
            £
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <TextTooltip arrow title={tooltipTitle}>
          <Typography variant="body1" color="textPrimary" component="span">
            {content}
          </Typography>
        </TextTooltip>
      </Grid>
    </Grid>
  )
}
