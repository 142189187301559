import React from 'react'
import { CompanySecurityList } from 'components/company/CompanySecurityList'
import { queryCache, useMutation, useQuery } from 'react-query'
import { createSecurity as createSecurityApi, getCompanyStats } from 'api'
import { CompanyDetailsView } from './CompanyDetailsView'
import { CompanyStats } from './CompanyStats'
import { makeStyles } from '@material-ui/core/styles'
import { useToast } from 'hooks'
import { Spinner } from 'components'

const useStyles = makeStyles(theme => ({
  panel: {
    paddingTop: theme.spacing(5),
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

export const CompanyOverview = ({ company }) => {
  const classes = useStyles()
  const [createSecurity] = useMutation(createSecurityApi, { throwOnError: true })

  const { data: companyStats = {}, isLoading } = useQuery(['companyStats', company.id], getCompanyStats, {
    enabled: company?.id,
  })

  const toastService = useToast()

  const addSecurity = async security => {
    try {
      await createSecurity({ security, companyId: company.id })
      queryCache.invalidateQueries('company')
      toastService.showSuccessToast(`Added ${security.name} to company ${company.name}.`)
    } catch (e) {
      toastService.showErrorToast(`Failed to add security. ${e.message}`)
      console.log(e)
      throw e
    }
  }

  if (isLoading) {
    return <Spinner color="secondary" size={40} height="300px" centered />
  }

  return (
    <div>
      <CompanyStats companyStats={companyStats} />

      <CompanySecurityList
        securityList={company.security}
        addSecurity={addSecurity}
        panelClass={classes.panel}
        disableGutters
      />

      <CompanyDetailsView company={company} />
    </div>
  )
}
