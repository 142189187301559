import { date } from 'utils/dateFormat'
import { CurrencyDetails } from 'components/detailsView'
import React from 'react'
import { percent, paddedNumber, currency, decimal } from 'utils/numberFormat'
import { SECURITY_TYPE_LABELS } from 'constants/security-type-labels'
import _ from 'lodash'

const CURRENCY_INT_FIELDS = ['invested_capital', 'realised_capital']

const CURRENCY_DECIMAL_FIELDS = [
  'option_price',
  'share_price',
  'warrant_price',
  'max_share_price',
  'exercise_price',
  'realised_share_price',
]

const DECIMAL_FIELDS = ['upside_ratio', 'downside_ratio']
const DATE_FIELDS = ['exercise_end', 'exercise_start', 'drawdown_date', 'final_repayment_date']
const BOOLEAN_FIELDS = ['voting', 'preferential_rights', 'is_compounding']
const OBJECT_FIELDS = ['converts_to_security']
const PERCENT_FIELDS = ['interest_rate', 'discount']
const PADDED_FIELDS = ['number_of_shares']

const DEFAULT_FORMAT = {
  voting: value => (value ? 'Yes' : 'No'),
  preferential_rights: value => (value ? 'Yes' : 'No'),
  is_compounding: value => (value ? 'Compounding' : 'Straight'),
  type: value => SECURITY_TYPE_LABELS[value],
  converts_to_security: value => value?.name,
  compounding_frequency: value => _.capitalize(value) || '-',
  frequency_of_payments: value => _.capitalize(value),
}

const addFormat = (formatObject, fields, formatFn) => {
  fields.forEach(field => (formatObject[field] = formatFn))
}

addFormat(DEFAULT_FORMAT, CURRENCY_INT_FIELDS, value => <CurrencyDetails value={value} format={paddedNumber} />)
addFormat(DEFAULT_FORMAT, CURRENCY_DECIMAL_FIELDS, value => <CurrencyDetails value={value} format={currency} />)
addFormat(DEFAULT_FORMAT, DECIMAL_FIELDS, decimal)
addFormat(DEFAULT_FORMAT, DATE_FIELDS, date)
addFormat(DEFAULT_FORMAT, BOOLEAN_FIELDS, value => (value ? 'Yes' : 'No'))
addFormat(DEFAULT_FORMAT, OBJECT_FIELDS, value => value?.name)
addFormat(DEFAULT_FORMAT, PERCENT_FIELDS, percent)
addFormat(DEFAULT_FORMAT, PADDED_FIELDS, paddedNumber)

export const formatSecurityField = (value, field, formatOverrides = {}) => {
  const formats = { ...DEFAULT_FORMAT, ...formatOverrides }
  if (formats.hasOwnProperty(field)) {
    return formats[field](value)
  }

  return value || '-'
}
