import React from 'react'
import { FormTextInput } from 'components'
import { requiredField } from 'validators/requiredField'

export const SecurityInterestRate = ({ className }) => (
  <FormTextInput
    label="Interest rate, %"
    type="number"
    name="interest_rate"
    className={className}
    validators={[requiredField]}
    parse={value => parseFloat(value)}
    required
  />
)
