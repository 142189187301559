import React from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { TransactionRow } from './TransactionRow'
import { FadeContent, TablePagination, SortableHeader } from 'components'
import { useSorting, useTablePagination } from 'hooks'

const useStyles = makeStyles(theme => ({
  tableContainer: {
    minHeight: 610,
  },
  tableHeader: {
    '& th': {
      padding: theme.spacing(1.5),
    },
  },
}))

const SORT_LABELS = {
  ASSET_NAME: 'asset_name',
  COMPANY_NAME: 'company_name',
  INVESTED_CAPITAL: 'invested_capital',
  INVESTMENT_DATE: 'investment_date',
  INVESTOR_NAME: 'investor_name',
  UPDATED_AT: 'updated_at',
}

export const TransactionsTable = ({ investments, company, investorMode = false }) => {
  const classes = useStyles()
  const [{ page, rowsPerPage }, onPageChange] = useTablePagination()

  const [{ direction, field: byField, sortedItems: sortedInvestments }, changeSorting] = useSorting({
    getAccessor,
    items: investments,
    initialField: SORT_LABELS.UPDATED_AT,
    initialDirection: 'desc',
  })

  return (
    <FadeContent>
      <Box mt={5} width="100%">
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="transactions table">
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell width="4%" />
                {investorMode ? (
                  <TableCell width="11%">
                    <SortableHeader
                      name={SORT_LABELS.COMPANY_NAME}
                      byField={byField}
                      direction={direction}
                      clickHandler={changeSorting}
                    >
                      <Typography variant="subtitle1" color="textSecondary">
                        Company
                      </Typography>
                    </SortableHeader>
                  </TableCell>
                ) : (
                  <TableCell width="11%">
                    <SortableHeader
                      name={SORT_LABELS.INVESTOR_NAME}
                      byField={byField}
                      direction={direction}
                      clickHandler={changeSorting}
                    >
                      <Typography variant="subtitle1" color="textSecondary">
                        Investor
                      </Typography>
                    </SortableHeader>
                  </TableCell>
                )}

                <TableCell width="12%">
                  <SortableHeader
                    name={SORT_LABELS.ASSET_NAME}
                    byField={byField}
                    direction={direction}
                    clickHandler={changeSorting}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      Asset Name
                    </Typography>
                  </SortableHeader>
                </TableCell>

                <TableCell width="7%">
                  <SortableHeader
                    name={SORT_LABELS.INVESTMENT_DATE}
                    byField={byField}
                    direction={direction}
                    clickHandler={changeSorting}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      Date
                    </Typography>
                  </SortableHeader>
                </TableCell>

                <TableCell width="12%">
                  <SortableHeader
                    name={SORT_LABELS.INVESTED_CAPITAL}
                    byField={byField}
                    direction={direction}
                    clickHandler={changeSorting}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      Invested Capital
                    </Typography>
                  </SortableHeader>
                </TableCell>
                <TableCell width="7%">
                  <Typography variant="subtitle1" color="textSecondary">
                    Status
                  </Typography>
                </TableCell>
                <TableCell width="8%">
                  <Typography variant="subtitle1" color="textSecondary">
                    Investment Document
                  </Typography>
                </TableCell>
                <TableCell width="12%">
                  <Typography variant="subtitle1" color="textSecondary">
                    Investment Schemes
                  </Typography>
                </TableCell>
                <TableCell width="5%">
                  <Typography variant="subtitle1" color="textSecondary">
                    Fees Due
                  </Typography>
                </TableCell>
                <TableCell width="8%">
                  <Typography variant="subtitle1" color="textSecondary">
                    Cash Received
                  </Typography>
                </TableCell>
                <TableCell width="12%">
                  <SortableHeader
                    name={SORT_LABELS.UPDATED_AT}
                    byField={byField}
                    direction={direction}
                    clickHandler={changeSorting}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      Updated At
                    </Typography>
                  </SortableHeader>
                </TableCell>

                <TableCell width="3%" />
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedInvestments?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(investment => (
                <TransactionRow
                  key={investment.id}
                  company={company}
                  investment={investment}
                  investorMode={investorMode}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          count={sortedInvestments?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onPageChange}
        />
      </Box>
    </FadeContent>
  )
}

function getAccessor(field) {
  return investment => {
    switch (field) {
      case SORT_LABELS.ASSET_NAME:
        return investment.investment_security.security.name
      case SORT_LABELS.INVESTED_CAPITAL:
        return Number(investment.investment_security.investment_security_values.invested_capital)
      case SORT_LABELS.COMPANY_NAME:
        return investment.company.name
      case SORT_LABELS.INVESTOR_NAME:
        return investment.investor.investor_name
      default:
        return investment[field]
    }
  }
}
