import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  Typography,
} from '@material-ui/core'
import { useSetState } from 'react-use'
import { useHistory, useRouteMatch } from 'react-router-dom'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import { TextTooltip } from 'components/TextTooltip'
import { InvestorEdit } from './InvestorEdit'
import { updateInvestor as updateInvestorApi } from 'api'
import { useMutation, queryCache } from 'react-query'
import { ConfirmationDialog, CompanyTypeIcon, TablePagination, FadeContent, SortableHeader } from 'components'
import { apiErrors } from 'utils/error'
import { InvestmentCreate } from '../investment'
import { useTablePagination, useToast, useSorting } from 'hooks'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    minHeight: 450,
  },
  affiliations: {
    maxWidth: '0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '15%',
  },
  investorType: {
    width: '18px',
    height: '18px',
  },
  button: {
    padding: 0,
    marginRight: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
  },
  rowHover: {
    cursor: 'pointer',
  },
  menuCell: {
    textAlign: 'right',
  },
}))

const SORT_LABELS = {
  INVESTOR_NAME: 'investor_name',
  INVESTOR_INTRODUCER: 'introducer',
  ADDRESS: 'address',
  EMAIL: 'email',
}

export const InvestorList = ({ investors = [] }) => {
  const classes = useStyles()
  const history = useHistory()
  let { url } = useRouteMatch()

  const [{ page, rowsPerPage }, onPageChange] = useTablePagination()

  const [{ direction, field, sortedItems: sortedInvestors }, changeSorting] = useSorting({
    getAccessor,
    items: investors,
    initialField: SORT_LABELS.INVESTOR_NAME,
  })

  const [updateInvestor, { isLoading }] = useMutation(updateInvestorApi, { throwOnError: true })

  const [
    {
      openEditModal,
      selectedInvestor,
      openConfirmationModal,
      openCreateInvestmentModal,
      investmentInvestor,
      selectedCompany,
    },
    setState,
  ] = useSetState({
    anchorEl: null,
    openEditModal: false,
    selectedInvestor: {},
    openConfirmationModal: false,
    openCreateInvestmentModal: false,
    investmentInvestor: null,
    selectedCompany: null,
  })

  const toastService = useToast()

  const navigateToInvestor = (event, id) => {
    event.persist()
    event.stopPropagation()
    history.push(`investors/details/${id}`)
  }
  const renderAffiliations = (affiliations = []) => {
    const text = affiliations.join(',\t')

    let affiliationsElement = text
    if (affiliations.length > 2) {
      affiliationsElement = (
        <TextTooltip title={text}>
          <span>{text}</span>
        </TextTooltip>
      )
    }

    return affiliationsElement
  }

  const handleInvestorUpdate = async investor => {
    try {
      await updateInvestor(investor)
      queryCache.invalidateQueries('investors')
      setState({ openEditModal: false, selectedInvestor: {} })
      toastService.showSuccessToast(`Investor ${investor.investor_name} updated successfully.`)
    } catch (e) {
      toastService.showErrorToast(`Failed to update investor ${investor.investor_name}. ${e.message}`)
      return apiErrors(e)
    }
  }

  const onEdit = investor => {
    setState({ selectedInvestor: investor, openEditModal: true })
  }

  const cancel = () => {
    setState({ openConfirmationModal: false })
  }

  const handleAccept = () => {
    setState({ openEditModal: false, selectedInvestor: {}, openConfirmationModal: false })
  }

  const handleAddInvestmentClick = investor => {
    setState({ anchorEl: null, openCreateInvestmentModal: true, investmentInvestor: investor })
  }

  const getNextUrl = () => {
    if (investmentInvestor) {
      return `${url}/details/${investmentInvestor.id}/pending-transaction-report`
    }

    return url
  }

  const handleCancelClick = (event, dirty) => {
    if (dirty) {
      setState({ openConfirmationModal: true })
    } else {
      setState({ openConfirmationModal: false, selectedInvestor: {}, openEditModal: false })
    }
  }

  return (
    <FadeContent>
      <Box mt={5}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />

                <TableCell>
                  <SortableHeader
                    name={SORT_LABELS.INVESTOR_NAME}
                    direction={direction}
                    byField={field}
                    clickHandler={changeSorting}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      Investor
                    </Typography>
                  </SortableHeader>
                </TableCell>

                <TableCell>
                  <SortableHeader
                    name={SORT_LABELS.INVESTOR_INTRODUCER}
                    direction={direction}
                    byField={field}
                    clickHandler={changeSorting}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      Introducer
                    </Typography>
                  </SortableHeader>
                </TableCell>

                <TableCell>
                  <Typography variant="subtitle1" color="textSecondary">
                    Affiliations
                  </Typography>
                </TableCell>

                <TableCell>
                  <SortableHeader
                    name={SORT_LABELS.ADDRESS}
                    direction={direction}
                    byField={field}
                    clickHandler={changeSorting}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      Address
                    </Typography>
                  </SortableHeader>
                </TableCell>

                <TableCell>
                  <SortableHeader
                    name={SORT_LABELS.EMAIL}
                    direction={direction}
                    byField={field}
                    clickHandler={changeSorting}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      Email
                    </Typography>
                  </SortableHeader>
                </TableCell>

                <TableCell>
                  <Typography variant="subtitle1" color="textSecondary">
                    Phone Number
                  </Typography>
                </TableCell>

                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedInvestors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                <TableRow
                  classes={{ hover: classes.rowHover }}
                  key={row.investor_name}
                  hover
                  onClick={e => navigateToInvestor(e, row.id)}
                >
                  <TableCell width="1%">
                    <CompanyTypeIcon type={row.investor_type} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.investor_name}
                  </TableCell>
                  <TableCell>{row.introducer}</TableCell>
                  <TableCell className={classes.affiliations}>
                    {renderAffiliations(row.investor_affiliations)}
                  </TableCell>
                  <TableCell width="20%">{row.address}</TableCell>
                  <TableCell width="19%">{row.email}</TableCell>
                  <TableCell width="10%">{row.phone_number}</TableCell>
                  <TableCell className={classes.menuCell}>
                    <InvestorOptions
                      investor={row}
                      onEdit={onEdit}
                      handleAddInvestmentClick={handleAddInvestmentClick}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination count={investors.length} rowsPerPage={rowsPerPage} page={page} onChangePage={onPageChange} />

        <InvestorEdit
          open={openEditModal}
          handleCancel={handleCancelClick}
          investor={selectedInvestor}
          handleSubmit={handleInvestorUpdate}
          isLoading={isLoading}
        />

        <InvestmentCreate
          open={openCreateInvestmentModal}
          handleCancel={() => setState({ openCreateInvestmentModal: false, investmentInvestor: null })}
          selectedInvestor={investmentInvestor}
          company={selectedCompany}
          disableInvestorSelect
          nextUrl={getNextUrl()}
        />

        <ConfirmationDialog
          open={openConfirmationModal}
          title="Cancel Investor Editing"
          onCancel={cancel}
          onAccept={handleAccept}
          content={`You are going to cancel ${
            selectedInvestor && selectedInvestor.investor_name
          } editing. All the filed information will be lost.`}
        />
      </Box>
    </FadeContent>
  )
}

const InvestorOptions = ({ investor, onEdit, handleAddInvestmentClick }) => {
  const [{ anchorEl }, setState] = useSetState({ anchorEl: null })
  const classes = useStyles()

  const handleClick = event => {
    event.persist()
    event.preventDefault()
    event.stopPropagation()
    setState({ anchorEl: event.currentTarget })
  }

  const handleMenuClose = event => {
    if (event) {
      event.persist()
      event.stopPropagation()
    }

    setState({ anchorEl: null })
  }

  const handleEdit = event => {
    event.persist()
    event.stopPropagation()
    onEdit(investor)
    handleMenuClose()
  }

  const handleAddInvestment = event => {
    event.persist()
    event.stopPropagation()
    handleAddInvestmentClick(investor)
    handleMenuClose()
  }

  return (
    <>
      <IconButton onClick={handleClick} className={classes.button}>
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        elevation={1}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleEdit}>Edit Investor</MenuItem>
        <MenuItem onClick={handleAddInvestment}>Add Investment</MenuItem>
      </Menu>
    </>
  )
}

function getAccessor(field) {
  return investor => investor[field]
}
