import React from 'react'
import { TransactionsTable } from './TransactionsTable'
import { isPending } from 'utils/investment'
import { TransactionRow } from './TransactionRow'

export const PendingTransactionReport = ({ company, investments = [], investorMode = false }) => {
  const pendingInvestments = investments.filter(isPending)

  return (
    <TransactionsTable
      company={company}
      investorMode={investorMode}
      investments={pendingInvestments}
      TransactionRow={TransactionRow}
    />
  )
}
