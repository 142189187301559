import { makeStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.common.pattensBlue,
  },
  top: {
    color: theme.palette.green.main,
    position: 'absolute',
    left: 0,
  },
}))

export function PercentCircle({ value, size = 50, thickness = 4, ...props }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress variant="static" className={classes.bottom} size={size} thickness={4} value={100} {...props} />
      <CircularProgress
        variant="static"
        className={classes.top}
        size={size}
        thickness={thickness}
        value={value}
        {...props}
      />
    </div>
  )
}
