import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { FormRadioGroup } from 'components/RadioGroup'
import { VOTING } from 'constants/voting'
import { requiredField } from 'validators'

const useStyles = makeStyles(() => ({
  optionFields: {
    display: 'flex',
  },
  voting: {
    display: 'flex',
    flexDirection: 'column',
  },
  options: {
    display: 'flex',
  },
}))

export const SecurityVoting = ({ className = '' }) => {
  const classes = useStyles()

  return (
    <FormRadioGroup
      label="Voting"
      name="voting"
      required={true}
      groupClass={`${classes.voting} ${className}`}
      optionsClass={classes.options}
      options={[
        { label: 'Yes', value: VOTING.YES },
        { label: 'No', value: VOTING.NO },
      ]}
      validators={[requiredField]}
    />
  )
}
