import React from 'react'
import { DetailsView } from 'components/detailsView'
import { enrichInvestor, investorFields, nameToLabelMapping, normalizeInvestorChanges } from 'utils/investor'
import Chip from '@material-ui/core/Chip'

import { fade, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(1),
  },
  oldAffiliations: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiChip-root': {
      marginBottom: theme.spacing(1),
      height: 'inherit',
      backgroundColor: fade(theme.palette.common.yellow, 0.4),
    },
  },
  newAffiliations: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    '& .MuiChip-root': {
      marginBottom: theme.spacing(1),
      height: 'inherit',
      backgroundColor: fade(theme.palette.green.main, 0.4),
    },
  },
}))

export const InvestorDetails = ({ investor, changes = [], showChangedOnly = false }) => {
  const investorSnapshot = enrichInvestor(investor)
  const classes = useStyles()

  const renderMappings = {
    investor_affiliations: values => {
      if (!values || values.length === 0) return '-'

      return values.map(value => (
        <Chip key={value} label={value} color="primary" variant="default" className={classes.chip} />
      ))
    },
  }

  const fieldClasses = {
    investor_affiliations: {
      oldValueClass: classes.oldAffiliations,
      newValueClass: classes.newAffiliations,
    },
  }

  const changesMap = normalizeInvestorChanges(changes)

  return (
    <DetailsView
      title="Investor Details"
      fields={investorFields}
      entity={investorSnapshot}
      changesMap={changesMap}
      labelsMapping={nameToLabelMapping}
      renderMappings={renderMappings}
      showChangedOnly={showChangedOnly}
      fieldClasses={fieldClasses}
    />
  )
}
