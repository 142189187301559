export const requiredField = value => {
  const message = 'Field is required'

  if ([undefined, '', null].includes(value)) {
    return message
  }

  if (Array.isArray(value) && !value.length) {
    return message
  }

  return undefined
}
