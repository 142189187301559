export const investmentFields = [
  'asset_name',
  'investment_status',
  'investment_date',
  'cash_received',
  'fees_due',
  'investment_type',
  'applicable_schemas',
  'requested_schemas',
  'investor',
  'legal_investor',
  'managed_by',
  'subscription_document',
  'scheme_paperwork',
  'share_certificate',
]
