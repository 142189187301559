import React from 'react'
import { Helmet } from 'react-helmet'

import { UpdatePasswordForm } from 'components/auth'

export const UpdatePassword = () => (
  <>
    <Helmet>
      <title>New Model VC Dashboard - Update Password</title>
    </Helmet>
    <UpdatePasswordForm />
  </>
)
