import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Modal } from 'components/Modal'
import { InvestmentForm } from './InvestmentForm'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useMutation, useQuery } from 'react-query'
import { getInvestors, postInvestment } from 'api'
import { apiErrors } from 'utils/error'
import { useHistory } from 'react-router-dom'
import { useToast } from 'hooks'

const useStyles = makeStyles(theme => ({
  modalContentClass: {
    margin: 0,
    paddingBottom: 0,
  },
  createInvestmentForm: {
    padding: theme.spacing(0),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
  },
  investmentDate: {
    gridColumn: '1/4',
  },
  investmentCapital: {
    gridColumn: '2/4',
  },
  securityList: {
    gridColumn: '1/3',
    padding: 0,
  },
}))

export const InvestmentCreate = ({
  company = {},
  open,
  handleCancel,
  selectedInvestor,
  disableCompanySelect,
  disableInvestorSelect,
  nextUrl = '/companies',
  afterCreate = () => {},
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { data: investors = [] } = useQuery('investors', getInvestors)
  const [createInvestment, { isLoading }] = useMutation(postInvestment, { throwOnError: true })
  const toastService = useToast()
  const [dirty, setDirty] = useState(false)

  const handleInvestmentCreate = async ({
    share_certificate = [],
    subscription_document = [],
    scheme_paperwork = [],
    ...data
  }) => {
    const investorId = data.beneficial_investor_id || selectedInvestor.id
    const values = {
      company_id: company?.id || data.company_id,
      investor_id: investorId,
      investment_date: data.investment_date,
      applicable_schemas: data.applicable_schemes,
      requested_schemas: data.requested_schemes,
      cash_received: data.cash_received,
      security_id: data.security_id,
      investment_security: data.securities,
      fees_due: data.fees_due,
      investment_type: data.investment_type,
      legal_investor_id: data.legal_investor_id || investorId,
      managed_by_id: data.managed_by_id || investorId,
      attachments: [...share_certificate, ...subscription_document, ...scheme_paperwork].map(
        ({ storage_url, file_name, file_type, document_type }) => ({
          storage_url,
          file_name,
          file_type,
          document_type,
        })
      ),
    }

    try {
      await createInvestment(values)
      history.push(nextUrl)
      handleCancel()
      afterCreate && afterCreate()
      toastService.showSuccessToast(`Investment created successfully.`)
    } catch (e) {
      toastService.showErrorToast(`Failed to create investment. ${e.message}`)
      return apiErrors(e)
    }
  }

  const initialValues = {
    name: company?.name,
    company_id: company?.id,
    applicable_schemes: [],
    share_certificate: [],
    subscription_document: [],
    scheme_paperwork: [],
  }

  return (
    <Modal
      modalTitle="Create Investment"
      open={open}
      modalContentClass={classes.modalContentClass}
      modalContent={
        <InvestmentForm
          company={company}
          investors={investors}
          initialValues={initialValues}
          selectedInvestor={selectedInvestor}
          onSubmit={handleInvestmentCreate}
          className={classes.createInvestmentForm}
          classes={{
            investmentDate: classes.investmentDate,
            investmentCapital: classes.investmentCapital,
            securityList: classes.securityList,
          }}
          disableCompanySelect={disableCompanySelect}
          disableInvestorSelect={disableInvestorSelect}
          handleDirty={isDirty => setDirty(isDirty)}
        />
      }
      modalActions={
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button onClick={handleCancel} color="primary" variant="outlined" disabled={isLoading}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              form="investment-form"
              type="submit"
              color="primary"
              variant="contained"
              disabled={isLoading || !dirty}
              autoFocus
            >
              Create
            </Button>
          </Grid>
        </Grid>
      }
      isLoading={isLoading}
    />
  )
}
