import React from 'react'
import { Helmet } from 'react-helmet'

import { ForgotPasswordForm } from 'components/auth/ForgotPasswordForm'
export const ForgotPassword = () => (
  <>
    <Helmet>
      <title>New Model VC Dashboard - Forgot Password</title>
    </Helmet>
    <ForgotPasswordForm />
  </>
)
