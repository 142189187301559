import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  valueText: {
    color: theme.palette.common.dark,
  },
}))

export const DetailsValue = ({ label, value, renderValue = value => value, className = '' }) => {
  const classes = useStyles()

  return (
    <Grid container direction="column" className={className}>
      <Typography variant="subtitle1" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1" className={classes.valueText} component="div">
        {renderValue(value)}
      </Typography>
    </Grid>
  )
}
