import React from 'react'
import { EmptyList } from 'components/EmptyList'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SEQURITY_LABELS } from 'constants/security-labels'
import { getFields, formatSecurityField } from 'utils/security'
import classnames from 'classnames'

const useStyles = makeStyles(theme => ({
  security: {
    backgroundColor: theme.palette.background.lightGray,
    border: `1px solid ${theme.palette.grayShades.gray5}`,
    borderRadius: theme.spacing(1),
  },
}))

export const SecurityList = ({
  securityList = [],
  showEmptyList = true,
  className = '',
  securityClass = '',
  emptyText = 'No Securities added yet.',
}) => {
  const classes = useStyles()

  if (showEmptyList && securityList.length === 0) {
    return <EmptyList text={emptyText} />
  }

  const securities = securityList.map(security => ({ ...security, ...security[security.type] }))

  const getValue = (security, field) => formatSecurityField(security[field], field)

  const renderSecurity = security => {
    const fields = getFields(security.type)

    return (
      <Box p={3} mb={8} key={security.name} className={classnames([classes.security, securityClass])} width={1}>
        <Grid container spacing={8}>
          {fields.map(field => (
            <Grid item key={field}>
              <Typography variant="subtitle1" color="textSecondary">
                {SEQURITY_LABELS[field]}
              </Typography>
              <Typography variant="body1">{getValue(security, field)}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  }

  return (
    <Box p={5} className={classnames([className])}>
      <Grid container>{securities.map(security => renderSecurity(security))}</Grid>
    </Box>
  )
}
