import React from 'react'
import { Box } from '@material-ui/core'

export const CurrencyDetails = ({ value, format }) => (
  <span>
    <Box component="span" mr={2}>
      £
    </Box>
    <span>{format(value)}</span>
  </span>
)
