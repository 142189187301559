import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { getIntroducers as getIntroducersApi } from 'api'
import { FormAutocomplete } from 'components/autocomplete'
import { requiredField } from 'validators'
import { debounce } from 'lodash'
import { DEBOUNCE_DELAY } from 'constants/input'

export const InvestorIntroducer = () => {
  const [getIntroducers, { data = [], isLoading }] = useMutation(getIntroducersApi)

  useEffect(() => {
    const loadIntroducers = async () => getIntroducers({ introducer_name: '' })

    loadIntroducers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = value => {
    getIntroducers({ introducer_name: value || '' })
  }

  const debouncedChange = debounce(onChange, DEBOUNCE_DELAY)

  return (
    <FormAutocomplete
      label="Introducer"
      name="introducer"
      options={data}
      validators={[requiredField]}
      handleInputChange={debouncedChange}
      loading={isLoading}
      AutocompleteProps={{
        freeSolo: true,
        selectOnFocus: true,
        handleHomeEndKeys: true,
        openOnFocus: true,
        autoSelect: true,
      }}
      required
    />
  )
}
