import React from 'react'
import { Helmet } from 'react-helmet'

import { InvestorCreate as CreateInvestor } from 'components/investor'

export const InvestorCreate = () => (
  <>
    <Helmet>
      <title>New Model VC Dashboard - Create Investor</title>
    </Helmet>
    <CreateInvestor />
  </>
)
