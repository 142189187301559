import React from 'react'
import { CardContent, Typography, makeStyles, Card, Grid, Box } from '@material-ui/core'
import classnames from 'classnames'
import { currency, paddedNumber, percent } from 'utils/numberFormat'
import { PercentCircle, FadeContent } from 'components'

const useStyles = makeStyles(theme => ({
  notableMetrics: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr',
    gridColumnGap: theme.spacing(3),
  },
  tableContainer: {
    minHeight: 610,
  },
  sharesCard: {
    boxShadow: theme.customShadows.shadow1,
    borderRadius: '10px',
  },
  cardContent: {
    minHeight: '55px',
    display: 'flex',
    alignItems: 'center',
  },
  issuedShares: {
    borderBottom: `1px solid ${theme.palette.common.pattensBlue}`,
  },
  tileLabel: {
    fontSize: 14,
  },
  subCards: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridColumnGap: theme.spacing(3),
    gridRowGap: theme.spacing(3),
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.customShadows.shadow1,
    borderRadius: '10px',
    padding: theme.spacing(4),
    '& .MuiCardContent-root': {
      padding: 0,
    },
  },
  tooltipValue: {
    fontWeight: 'bold',
  },
}))

export const CompanyStats = ({ companyStats }) => {
  const classes = useStyles()
  const subcardClasses = classnames([classes.card, classes.subCard])

  return (
    <>
      <FadeContent>
        <div className={classes.notableMetrics}>
          <Card className={classes.sharesCard}>
            <CardContent>
              <Box m={2}>
                <Typography variant="h4">Number of Shares</Typography>
                <Box my={3} pb={3} className={classes.issuedShares}>
                  <Grid container>
                    <Grid container item direction="column" xs={6}>
                      <Typography variant="h6">{paddedNumber(companyStats.issued_number_of_shares)}</Typography>
                      <Typography variant="body2" color="textSecondary" className={classes.tileLabel}>
                        Issued
                      </Typography>
                    </Grid>

                    <Grid container item direction="row" xs={6} spacing={3}>
                      <Grid item>
                        <Typography variant="h6">
                          {percent(companyStats.fig_investor_issued_number_of_shares)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" className={classes.tileLabel}>
                          Fig & NM investors
                        </Typography>
                      </Grid>
                      <Grid item>
                        <PercentCircle
                          size={50}
                          thickness={4}
                          value={companyStats.fig_investor_issued_number_of_shares || 0}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>

                <Grid container>
                  <Grid container item direction="column" xs={6}>
                    <Typography variant="h6">{paddedNumber(companyStats.fully_diluted_number_of_shares)}</Typography>
                    <Typography variant="body2" color="textSecondary" className={classes.tileLabel}>
                      Fully diluted
                    </Typography>
                  </Grid>

                  <Grid container item direction="row" xs={6} spacing={3}>
                    <Grid item>
                      <Typography variant="h6">
                        {percent(companyStats.fig_investor_fully_diluted_number_of_shares)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" className={classes.tileLabel}>
                        Fig & NM investors
                      </Typography>
                    </Grid>

                    <Grid item>
                      <PercentCircle
                        size={50}
                        thickness={4}
                        value={companyStats.fig_investor_fully_diluted_number_of_shares || 0}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>

          <div className={classes.subCards}>
            <Card className={subcardClasses}>
              <TileContent
                value={currency(companyStats.current_marketed_share_price)}
                title="Current marketed share price"
              />
            </Card>

            <Card className={subcardClasses}>
              <TileContent
                value={paddedNumber(companyStats.fully_diluted_valuation_current_price)}
                title="Fully diluted valuation at current marketed share price"
              />
            </Card>
          </div>

          <div className={classes.subCards}>
            <Card className={subcardClasses}>
              <TileContent
                value={paddedNumber(companyStats.carrying_value_of_fig_debt)}
                title="Carrying value of Fig and NM debt"
              />
            </Card>
          </div>
        </div>
      </FadeContent>
    </>
  )
}

const TileContent = ({ value, title }) => {
  const classes = useStyles()
  return (
    <CardContent className={classes.cardContent}>
      <Grid container direction="column">
        <Typography variant="h4">£{value}</Typography>
        <Typography variant="body2" color="textSecondary">
          {title}
        </Typography>
      </Grid>
    </CardContent>
  )
}
