import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { FormTextArea, FormDateInput } from 'components'
import { SecurityRank } from './SecurityRank'
import { SecurityInterestRate } from './SecurityInterestRate'
import { SecurityCompounding } from './SecurityCompounding'
import { SecurityPaymentFrequency } from './SecurityPaymentFrequency'
import { requiredField } from 'validators'

const useStyles = makeStyles(theme => ({
  optionFields: {
    display: 'flex',
    margin: theme.spacing(3, 0, 4, 0),
  },
  margin: {
    margin: theme.spacing(1, 0),
  },
  textArea: {
    gridColumn: '1/3',
  },
}))

export const AddDebt = () => {
  const classes = useStyles()

  return (
    <>
      <SecurityInterestRate className={classes.margin} />
      <SecurityCompounding className={classes.margin} />

      <SecurityPaymentFrequency className={classes.margin} />

      <SecurityRank className={classes.margin} />

      <FormDateInput
        className={classes.margin}
        label="Final Repayment Date"
        name="final_repayment_date"
        validators={[requiredField]}
        required
      />

      <FormTextArea
        className={`${classes.textArea} ${classes.margin}`}
        name="security"
        label="Security"
        rows={3}
        required={false}
      />

      <FormTextArea
        className={`${classes.textArea} ${classes.margin}`}
        name="additional_notes"
        label="Additional notes"
        rows={3}
        required={false}
      />
    </>
  )
}
