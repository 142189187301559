import { CompanyDetails } from 'components/detailsView'
import React from 'react'
import { mergeCompanyGroup } from './mergeCompanyGroup'
import { CompanySecurityAddedView } from 'components/detailsView/CompanySecurityAddedView'

export const getCompanyChanges = ({ action, snapshot, changes = [], investorView }) => {
  let { companyChanges, companySecurities } = mergeCompanyGroup({ snapshot, changes })

  let description = ''
  let showChangedOnly = false
  let detailsView = null

  const descriptionAddition = investorView ? ` (${snapshot.name})` : ''

  if (action === 'edit') {
    // Security was added from company overview section
    if (companySecurities.length !== 0) {
      description = 'New security added' + descriptionAddition
      detailsView = <CompanySecurityAddedView securities={companySecurities} />
      return { description, detailsView }
    }

    description = 'Company details changed' + descriptionAddition
    showChangedOnly = true
  } else if (action === 'create') {
    description = 'New company created' + descriptionAddition
    companyChanges = {}
  }

  detailsView = (
    <>
      <CompanyDetails company={snapshot} changes={companyChanges?.changes} showChangedOnly={showChangedOnly} />
    </>
  )

  return { description, detailsView }
}
