import React from 'react'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import NewModelLogo from 'assets/images/nmvc-logo.svg'
import FigLogo from 'assets/images/fig-logo.svg'

const useStyles = makeStyles(theme => ({
  authPage: {
    minHeight: '100vh',
  },
  formBox: {
    display: 'flex',
    width: '600px',
    flexDirection: 'column',
  },
  logo: {
    marginBottom: theme.spacing(6),
    height: '30px',
    margin: 0,
  },
  logoImg: {
    height: '200%',
  },
  logoSpacer: {
    width: '1px',
    backgroundColor: theme.palette.common.pattensBlue,
    margin: theme.spacing(0, 3),
  },
}))

export const AuthPage = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid container justify="center" alignItems="center" className={classes.authPage}>
      <Grid container alignContent="center" alignItems="center" className={classes.formBox}>
        <Grid container justify={'center'} className={classes.logo}>
          <img src={NewModelLogo} alt="New Model Logo" className={classes.logoImg} />

          <div className={classes.logoSpacer} />

          <img src={FigLogo} alt="New Model Logo" className={classes.logoImg} />
        </Grid>
        {children}
      </Grid>
    </Grid>
  )
}
