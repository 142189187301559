import React from 'react'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'
import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { If } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.customShadows.shadow2,
    padding: theme.spacing(3, 6),
  },
}))

export const BreadCrumbsHeader = ({
  children,
  section = '',
  subsection = '',
  title = '',
  hideBackButton = false,
  className = '',
  backPath = '',
}) => {
  const history = useHistory()
  const classes = useStyles()

  const handleBackClick = () => {
    if (backPath) {
      history.push(backPath)
      return
    }

    history.goBack()
  }

  return (
    <Grid container className={`${classes.root} ${className}`} alignItems="center">
      <If
        value={hideBackButton}
        component={null}
        elseComponent={
          <Grid item>
            <Box mr={4}>
              <IconButton color="secondary" variant="outlined" onClick={handleBackClick}>
                <ArrowBack />
              </IconButton>
            </Box>
          </Grid>
        }
      />

      <Grid container item xs={4}>
        <If
          value={section}
          component={
            <Grid container item>
              <Typography variant="subtitle1">{section}</Typography>
              <Box mx={1}>
                <Typography variant="subtitle1" color="textSecondary">
                  {'/'}
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="textSecondary">
                {subsection}
              </Typography>
            </Grid>
          }
        />
        <Typography variant="h6">{title}</Typography>
      </Grid>
      {children}
    </Grid>
  )
}
